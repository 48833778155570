import { ButtonModel, ReplyMarkup } from "../../common/AppButtonsModel";

export interface BroadcastModel {
  id: string;
  botId: string;
  name: string;
  recipients: RecipientModel[];
  status: BroadcastStatus;
  scheduledDate: Date | null;
  isScheduled: boolean;
  message: BroadcatMessageModel;
  recipientsCount?: number;
  totalSent: number;
  totalNotSent: number;
  sentForAll?: boolean;
  initialSentForAllFlag?: boolean;
  recipientFilter?: RecipientFiltersModel[] | null;
  isExecute: boolean;
  executeState: { execute: boolean; dirty: boolean };
}

export interface RecipientFiltersModel {
  typeDiscriminator: string;
  members: MemberModel[];
}

export interface MemberModel {
  filterId?: string;
  typeDiscriminator: string;
  targetCustomVariable?: string | null;
  targetCustomVariableKey?: string | null;
  field: string | null;
  value: string;
  condition: string;
  valueType: string;
}

export interface BroadcastRecipientModel {
  currentPage: number;
  items: BroadcastRecipientsItemsModel[];
  totalItems: number;
  totalPages: number;
}

export interface BroadcastRecipientsItemsModel {
  contactId: string;
  conversationId: string;
  errorMessage: string;
  firstName: string;
  lastName: string;
  status: string;
  typeDiscriminator: string;
  username: string;
}

export type BroadcatMessageModel = TextBroadcatMessage | PhotoBroadcatMessageModel | DocumentBroadcatMessageModel;

export interface BaseBroadcatMessageModel {
  text: string;
  parsedText?: string;
  entities?: BroadcastEntityModel[];
  buttons: ButtonModel[];
  fileId?: string;
  fileName?: string;
  typeDiscriminator: BroadcastMessageTypeDescriminator;
}

export interface BroadcastEntityModel {
  offset: number;
  length: number;
  type: string;
}

export interface ReplyMarkupMessage extends BaseBroadcatMessageModel {
  replyMarkup: ReplyMarkup;
}

export interface TextBroadcatMessage extends BaseBroadcatMessageModel {
  typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastTextMessage;
}

export interface PhotoBroadcatMessageModel extends BaseBroadcatMessageModel {
  fileId: string;
  fileName: string;
  typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastPhotoMessage;
}

export interface DocumentBroadcatMessageModel extends BaseBroadcatMessageModel {
  fileId: string;
  fileName: string;
  typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastDocumentMessage;
}

export interface BroadcastValidationModel {
  errors: BroadcastError[] | [];
}

export interface BroadcastValidateModel {
  validateForm: (broadcast: BroadcastFormModel) => BroadcastValidationModel;
  validateField: (broadcast: BroadcastFormModel, field: BroadcastValidationField) => BroadcastValidationModel;
  checkError: (validation: BroadcastValidationModel, field: string) => boolean;
  getErrorMessage: (validation: BroadcastValidationModel, field: string) => string | undefined;
}

interface BroadcastError {
  field: BroadcastValidationField;
  message: string;
}

export interface ContactListModel {
  items: { [key: string]: ContactModel };
  filteredItems?: ContactModel[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  selectedRecipientsCount: number;
}

export interface RecipientListModel {
  items: { [key: string]: ContactModel };
  currentPage: number;
  totalItems: number;
  totalPages: number;
  selectedRecipientsCount: number;
}

export interface ContactModel {
  id: string;
  conversationId: string;
  externalId: string;
  username: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  isSelected?: boolean;
  isSelectedInitial?: boolean;
}
export interface BroadcastFormModel {
  id?: string;
  name: string;
  isScheduled: boolean;
  scheduledDate: Date | null;
  message: BaseBroadcatMessageModel;
  recipients: RecipientFormModel[] | [];
  recipientsCount?: number;
  startedDate?: Date | null;
  completedDate?: Date | null;
  customVariableIds?: string[];
  isRecipient?: boolean;
  sentForAll?: boolean;
  excludeAll?: boolean;
  recipientFilter?: RecipientFiltersModel[] | null;
  initialRecipients?: RecipientFormModel[] | null;
  initialSentForAllFlag?: boolean;
  includedRecipients?: RecipientModel[] | null;
  excludedRecipients?: RecipientModel[] | null;
  recipientCounter?: number;
  checkedFlag?: boolean;
  uncheckedFlag?: boolean;
}

export interface RecipientModel {
  contactId: string;
  conversationId: string;
  externalContactId: string;
  externalConversationId: string;
  typeDiscriminator: string;
  name?: string;
  username?: string;
}

export interface RecipientsImportModel {
  blocked: number | null;
  availableRecipients: number | null;
  recipients: RecipientModel[] | null;
}

export interface RecipientFormModel {
  contactId: string;
  conversationId: string;
  externalId: string;
}

export enum BroadcastStatus {
  Draft = "Draft",
  Scheduled = "Scheduled",
  Sending = "Sending",
  Finished = "Finished",
  Cancelled = "Cancelled",
  Failed = "Failed",
}

export enum BroadcastValidationField {
  message = "message",
  name = "name",
  recipients = "contactList",
  schedule = "schedule",
}

export enum BroadcastMessageTypeDescriminator {
  BroadcastTextMessage = "BroadcastTextMessage",
  BroadcastPhotoMessage = "BroadcastPhotoMessage",
  BroadcastDocumentMessage = "BroadcastDocumentMessage",
}

export interface FileInfoModel {
  fileId: string;
  fileName: string;
}

export interface FileModel {
  file: FileInfoModel;
  type: FileUploadType;
}

export enum FileUploadType {
  "photo" = "Photo",
  "document" = "Document",
}

export enum FilterStatus {
  "All" = "All",
  "Sent" = "Sent",
  "Failed" = "Failed",
  "Skipped" = "Skipped",
}

export enum BroadcastHeaderType {
  "view" = "view",
  "new" = "new",
  "edit" = "edit",
}

export interface IncludedRecipient {
  externalContactId: null;
  externalConversationId: null;
  contactId: string;
  conversationId?: string | null;
  status: string;
  typeDiscriminator: string;
}

export interface ExcludedRecipient {
  externalContactId: null;
  externalConversationId: null;
  contactId: string;
  conversationId?: string | null;
  status: string;
  typeDiscriminator: string;
}

export interface IncludedRecipient {
  externalContactId: null;
  externalConversationId: null;
  contactId: string;
  conversationId?: string | null;
  status: string;
  typeDiscriminator: string;
}

export interface ExcludedRecipient {
  externalContactId: null;
  externalConversationId: null;
  contactId: string;
  conversationId?: string | null;
  status: string;
  typeDiscriminator: string;
}
