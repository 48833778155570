import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SignupModel } from "./SignUpModel";
import { RootState } from "../../common/state/store";

export interface SignUpState {
  token: string | null;
  signUpSubmit?: boolean | null;
  email?: string | null;
}
const initialState: SignUpState = {
  token: null,
  signUpSubmit: null,
};

export const signUpSlice = createSlice({
  name: "signUpSlice",
  initialState,
  reducers: {
    signUp: (state, action: PayloadAction<SignupModel>) => {
      return {
        ...state,
      };
    },
    setSignUpSubmit: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        signUpSubmit: action.payload,
      };
    },
    setEmail: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        email: action.payload,
      };
    },
    setTokenRegistration: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        token: action.payload,
      };
    },
  },
});

export const { signUp, setSignUpSubmit, setEmail, setTokenRegistration } = signUpSlice.actions;

export const selectSignUpSubmit = (state: RootState) => state.app.signUpState.signUpSubmit;
export const selectEmail = (state: RootState) => state.app.signUpState.email;
export const selectToken = (state: RootState) => state.app.signUpState.token;

export default signUpSlice.reducer;
