import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";
import { FunctionComponent, ReactNode } from "react";
import { selectCurrentBot } from "../../components/sidebar/SidebarSlice";
import { useAppSelector } from "../state/store";
import { CreateConnectBotContainer } from "../../components/addBot/components/CreateConnectBot/CreateConnectBotContainer";
import s from "./BaseEmptyPage.module.scss";

interface Props extends FlexProps {
  title?: string;
  description?: string;
  botTitle?: string;
  botDescription?: string;
  Image?: FunctionComponent;
  children?: ReactNode;
  isAddBot?: boolean;
}

export default function BaseEmptyPage({
  Image,
  description,
  title,
  children,
  botDescription,
  botTitle,
  isAddBot,
  ...flexProps
}: Props) {
  const currentBot = useAppSelector(selectCurrentBot);

  if (!currentBot || isAddBot) {
    return (
      <Flex className={s.containerAddBot}>
        <Flex className={s.botTextContainer} {...flexProps}>
          <Text variant="h2Bold">{botTitle}</Text>
          <Text variant="mediumGrey">{botDescription}</Text>
        </Flex>
        <CreateConnectBotContainer />
      </Flex>
    );
  }

  return (
    <Flex className={s.container}>
      <Box className={s.img} as={Image} />
      <Flex className={s.textContainer}>
        <Text variant="h2Bold">{title}</Text>
        <Text variant="mediumGrey">{description}</Text>
      </Flex>
      <Flex className={s.children}>{children}</Flex>
    </Flex>
  );
}
