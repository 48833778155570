import dayjs from "dayjs";
import { TFunction } from "i18next";

export function validateBroadcastTitle(title: string) {
  if (!title.length) {
    const result: BroadcastNewTitleValidation = { isInvalid: true, error: "Title field can't be empty" };
    return result;
  }
  if (title.length > 50) {
    const result: BroadcastNewTitleValidation = { isInvalid: true, error: "Title field can't be more than 50 symbols" };
    return result;
  }
  const result: BroadcastNewTitleValidation = { isInvalid: false, error: "" };
  return result;
}

export function validateSchedulerDate(date: Date | null, t: TFunction<"translation", "broadcast">) {
  if (date && dayjs().isAfter(dayjs(date))) {
    return t("Select future date, please") ?? "";
  }

  if (!date) {
    return t("Select date, please") ?? "";
  }
}

export interface BroadcastNewTitleValidation {
  isInvalid: boolean;
  error: string;
}
