import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { handleException } from "../../common/SagaHelper";
import { postBot, setBotWebHook } from "./AddBotApi";
import { BotInfoData } from "./AddBotData";
import { addBotSlice } from "./AddBotSlice";
import { AppSettings } from "../../common/AppSettings";
import { CreatedBotModel } from "./AddBotModel";
import { sidebarSlice } from "../sidebar/SidebarSlice";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { routerSlice } from "../../common/router/RouterSlice";

declare const appSettings: AppSettings;

export function* addBotSaga() {
  yield takeLatest(addBotSlice.actions.connectBot, connectBot);
}

function* connectBot(action: PayloadAction<string>) {
  try {
    yield put(beginScope("connectBot"));
    const botData: BotInfoData = yield call(postBot, action.payload);
    const webHookUrl = `${appSettings.apiBaseUrl}/api/telegramBot/webhook`;
    const botModel: CreatedBotModel = { ...botData };
    yield call(setBotWebHook, botData.id, webHookUrl);
    yield put(sidebarSlice.actions.selectBot(botModel));
    yield put(sidebarSlice.actions.getBots({ page: 1 }));
    yield put(
      notificationSlice.actions.notify({
        message: "Created successfully!",
        type: "success",
      }),
    );
    yield put(addBotSlice.actions.connectBotCompleted());
    yield put(routerSlice.actions.redirect("/automation/flows"));
  } catch (e) {
    if (e instanceof Error && !action.payload) {
      yield put(
        notificationSlice.actions.notify({
          message: "Please enter the bot token",
          type: "error",
        }),
      );
      return;
    }
    if (e instanceof Error) {
      console.log("e.message", e, "action", typeof action.payload);
      yield put(
        notificationSlice.actions.notify({
          message: e.message,
          type: "error",
        }),
      );
      return;
    }
    yield handleException(e);
  } finally {
    yield put(completeScope("connectBot"));
  }
}
