import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Box, Flex, Link, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ru from "date-fns/locale/ru";
import { format } from "date-fns";
import { ReactComponent as LeftArrow } from "../../../../../assets/icons/arrowLeft.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";
import { InputSearch } from "../../../../../UI/molecules/inputSearch/InputSearch";
import { getDocuments, selectDocuments } from "../../../ContactInfoSlice";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import useDebounce from "../../../../../common/hooks/useDebounce";
import { AppSettings } from "../../../../../common/AppSettings";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";
import { constructSearchParams } from "../../../../../common/utils/constructSearchParams";
import s from "./ContactDocuments.module.scss";

declare const appSettings: AppSettings;
interface Props {
  conversationId: string;
  documentsCount: number | undefined;
  setOpenDocs: Dispatch<SetStateAction<boolean>>;
}

export default function ContactDocuments({ setOpenDocs, conversationId, documentsCount }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const tp = useTranslation("translation", { keyPrefix: "conversation" }).t;
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const isLoading = useGetLoadingState("getDocuments");
  const dispatch = useAppDispatch();
  const documents = useAppSelector(selectDocuments);
  const documentsRef = useRef<null | HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [searchText, setSearchText] = useState(search);
  const debouncedSearchText = useDebounce(searchText, 500);
  const fileName = debouncedSearchText;
  const size = 20;
  const array = Array.from({ length: documentsCount ?? 20 }, (_, index) => index + 1);

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return format(date, "d MMMM 'в' HH:mm", { locale: ru });
  };

  const clearSearchParams = () => {
    searchParams.delete("search");
    setSearchParams(searchParams);
  };

  const handleDocumentsClose = () => {
    setSearchText("");
    clearSearchParams();
    setOpenDocs(false);
  };

  useEffect(() => {
    dispatch(getDocuments({ conversationId, page, size, fileName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fileName, conversationId]);

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchText]);

  const handleScroll = useCallback((event: any) => {
    if (isLoading) return;

    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (documents && documents.totalPages > page) {
      if (scrollHeight - scrollTop - 20 <= clientHeight) {
        setPage(prevPage => prevPage + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const documentsList = documentsRef.current;
    if (documentsList) {
      documentsList.addEventListener("scroll", handleScroll);
      return () => {
        documentsList.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    constructSearchParams(search, debouncedSearchText, setSearchParams);
  }, [searchText, search, debouncedSearchText, setSearchParams]);

  return (
    <Flex className={s.documentsContainer}>
      <Flex className={s.documentsGroup}>
        <Flex className={s.titleContainer}>
          <LeftArrow className={s.icon} onClick={handleDocumentsClose} />
          <Text variant="largeBold">{t("Docs")}</Text>
        </Flex>
        <CrossIcon className={s.icon} onClick={handleDocumentsClose} />
      </Flex>
      <InputSearch
        classprop={s.inputSearch}
        placeholder={t("Search by docs")}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <Box className={s.container} ref={documentsRef}>
        {isLoading ? (
          array.map(el => <Skeleton className={s.sceletonMapper} key={el} />)
        ) : documents?.items.length === 0 ? (
          <Box className={s.noResults}>{ct("No results found")}</Box>
        ) : (
          documents?.items.map((item, index) => (
            <Flex className={s.fileContainer} key={index}>
              {isLoading ? (
                <Skeleton className={s.skeletonDocsLeft} />
              ) : (
                <Box className={s.fileType} objectFit="cover" layerStyle={"fill"}>
                  {item.fileName.toLowerCase().split(".").pop()}
                </Box>
              )}
              {isLoading ? (
                <Skeleton className={s.skeletonDocsRight} />
              ) : (
                <>
                  <Flex className={s.filesItem} objectFit="cover" layerStyle={"fill"}>
                    <Link href={`${appSettings.apiBaseUrl}/file/${item.fileId}`} isExternal>
                      <Text className={s.fileNameText} variant="small">
                        {item.fileName}
                      </Text>
                    </Link>
                    <Text variant="small" color="darkGrey">
                      {item.size} {tp("fileSize.MB")}
                    </Text>
                    <Text variant="extraSmall" color="darkGrey">
                      {item.uploadDate ? formatDate(item.uploadDate) : ""}
                    </Text>
                  </Flex>
                </>
              )}
            </Flex>
          ))
        )}
      </Box>
    </Flex>
  );
}
