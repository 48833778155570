import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { contactListSlice, selectContactList, selectSearchParams } from "./ContactListSlice";

import { begin, beginScope, complete, completeScope } from "../../common/loading/LoadingStateActions";
import * as contactListApi from "./ContactListApi";
import { ContactListModel, ImportResult, NewContactFormModel } from "./ContactListModel";
import { isChangePage } from "../../common/utils/pagination";
import { organisationSlice } from "../organisation/OrganisationSlice";
import { CONTACTS_PAGE_SIZE } from "../../common/paginator/paginatorSizes";
import { CustomVariableData } from "../conversation/ConversationData";
import { mapCustomVariableDataToModel } from "./ContactListMapper";

export function* contactListSaga() {
  yield takeLatest(contactListSlice.actions.importContacts, importContacts);
  yield takeLatest(contactListSlice.actions.getContactList, getContactList);
  yield takeLatest(contactListSlice.actions.deleteContact, deleteContact);
  yield takeLatest(contactListSlice.actions.createContact, createContact);
  yield takeLatest(contactListSlice.actions.getCustomVariables, getCustomVariables);
}

export function* getContactList(action: PayloadAction<{ filters: string; search: string; page: number; size?: number }>) {
  try {
    yield put(beginScope("getContactList"));
    const data: ContactListModel = yield call(
      contactListApi.getContactListByOrganization,
      action.payload.filters,
      action.payload.search,
      action.payload.page,
      action.payload.size ?? CONTACTS_PAGE_SIZE,
    );
    yield put(contactListSlice.actions.getContactListSucceed({ contactList: data }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getContactList"));
  }
}

export function* deleteContact(action: PayloadAction<{ contactId: string }>) {
  try {
    yield put(begin());
    const contactList: ReturnType<typeof selectContactList> = yield select(selectContactList);
    const searchParams: ReturnType<typeof selectSearchParams> = yield select(selectSearchParams);
    let page = contactList?.currentPage;
    let filters = "";
    let search = "";

    if (contactList && page && isChangePage({ ...contactList, items: contactList.items || [] })) {
      page -= 1;
    }
    if (searchParams) {
      filters = searchParams.filters;
      search = searchParams.search ?? "";
    }

    yield call(contactListApi.deleteContactApi, action.payload.contactId);

    yield put(contactListSlice.actions.getContactList({ filters: filters, search: search, page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

export function* importContacts(action: PayloadAction<{ file: File | null; botIds: string[] }>) {
  try {
    yield put(beginScope("importContacts"));
    const response: ImportResult = yield call(contactListApi.importContactsApi, action.payload.file, action.payload.botIds);
    yield put(contactListSlice.actions.setImportResult({ importResult: response }));
    yield put(organisationSlice.actions.getOrganisation());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("importContacts"));
  }
}

function* createContact(action: PayloadAction<NewContactFormModel>) {
  try {
    yield put(begin());
    const contactList: ReturnType<typeof selectContactList> = yield select(selectContactList);
    const searchParams: ReturnType<typeof selectSearchParams> = yield select(selectSearchParams);
    const page = contactList?.currentPage;
    let filters = "";
    let search = "";
    if (searchParams) {
      filters = searchParams.filters;
      search = searchParams.search ?? "";
    }
    yield call(contactListApi.createContact, action.payload);
    yield put(contactListSlice.actions.getContactList({ filters: filters, search: search, page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

export function* getCustomVariables() {
  try {
    const contactVariablesData: CustomVariableData[] = yield call(contactListApi.getContactCustomVariables);
    const variablesModel = contactVariablesData.map(cv => mapCustomVariableDataToModel(cv));
    yield put(contactListSlice.actions.getCustomVariablesSucceed(variablesModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
