import s from "./QuestionNodePopup.module.scss";
import {
  CustomVariablesModel,
  FlowActionModel,
  FlowModel,
  NodeModel,
  NodeValidationProp,
  QuestionFlowActionModel,
  QuestionActionType,
} from "../../../FlowBuilderModel";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import {
  createCustomVariable,
  editNode,
  saveNode,
  selectCustomVariables,
  selectCustomVariablesWithoutSystem,
} from "../../../FlowBuilderSlice";
import { useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  FormControl,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Box,
  DrawerFooter,
  Text,
  Flex,
  Button,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import { notify } from "../../../../../common/notifications/NotificationSlice";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import { ReactComponent as QuestionIcon } from "../../../../../assets/icons/questionIcon.svg";
import { useTranslation } from "react-i18next";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";

interface Props {
  addCustomVariableText: (variable: CustomVariablesModel, variableMaxLength: number) => void;
  flow: FlowModel;
  node: NodeModel;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  botId: string;
}
export const QuestionNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const minRetryCount = 0;
  const maxRetryCount = 5;
  const variables = useAppSelector(selectCustomVariables);
  const dispatch = useAppDispatch();

  const customVariables = useAppSelector(selectCustomVariablesWithoutSystem);

  const [, setCustomVariablesDropdown] = useState(false);

  const flowAction = props.node.flowAction as QuestionFlowActionModel;

  if (!flowAction) {
    return <></>;
  }

  const chosenCustomVariable = variables?.find(el => {
    const targetCustomVariableId = flowAction.targetCustomVariableId;
    return el.id === targetCustomVariableId;
  });

  const chosenMediaCustomVariable = variables?.find(el => {
    const answerMediaVariableId = flowAction.answerMediaVariableId;
    return el.id === answerMediaVariableId;
  });

  const onDataChange = (text: string) => {
    const newData: FlowActionModel = {
      ...flowAction,
      text,
    } as QuestionFlowActionModel;
    props.validate(newData);
    dispatch(editNode({ ...props.node, flowAction: newData }));
  };

  const setCustomVariable = (variableId: string) => {
    props.validate({
      ...flowAction,
      targetCustomVariableId: variableId,
    } as QuestionFlowActionModel);
    dispatch(
      editNode({
        ...props.node,
        flowAction: {
          ...flowAction,
          targetCustomVariableId: variableId,
        } as QuestionFlowActionModel,
      }),
    );
    setCustomVariablesDropdown(false);
  };

  const setMediaCustomVariable = (variableMediaId: string) => {
    props.validate({
      ...flowAction,
      answerMediaVariableId: variableMediaId,
    } as QuestionFlowActionModel);
    dispatch(
      editNode({
        ...props.node,
        flowAction: {
          ...flowAction,
          answerMediaVariableId: variableMediaId,
        } as QuestionFlowActionModel,
      }),
    );
    setCustomVariablesDropdown(false);
  };

  const setRetryNumber = (retryCount: string) => {
    if (Number(retryCount) > maxRetryCount || Number(retryCount) < minRetryCount) {
      dispatch(
        notify({
          message: `${Number(retryCount)} is not allowed. Allowed values from ${minRetryCount} to ${maxRetryCount}`,
          type: "error",
        }),
      );
      return;
    }

    dispatch(
      editNode({
        ...props.node,
        flowAction: {
          ...flowAction,
          retryCount: Number(retryCount),
        } as QuestionFlowActionModel,
      }),
    );
  };

  const onCloseValidate = () => {
    dispatch(
      saveNode({
        flow: props.flow,
        node: props.node,
      }),
    );
  };

  const filteringType = (
    array: CustomVariablesModel[] | undefined,
    type: CustomVariableType,
    chosenVariable?: CustomVariableModel,
  ) => {
    const customVariables = array?.filter(el => el.type === type && el.id !== chosenVariable?.id);
    return customVariables;
  };

  const selectVariable = (variable: CustomVariablesModel) => {
    if (variable.id) {
      setCustomVariable(variable.id);
    }
  };

  const selectMediaVariable = (variable: CustomVariablesModel) => {
    if (variable.id) {
      setMediaCustomVariable(variable.id);
    }
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: { createCustomMediaVeriable: false } }));
    }
  };

  const onCreateMediaVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: { createCustomMediaVeriable: true } }));
    }
  };

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoDrawer">
        <DrawerContent>
          <Loading scope="nodeValidate" />
          <DrawerHeader>{t("Question Message")}</DrawerHeader>
          <DrawerBody>
            <div className={s.popupWrapper}>
              <div className={s.popup}>
                <div className={s.wrapper}>
                  <div className={s.popupItem}>
                    <MessageTextEditor
                      variables={variables ?? []}
                      text={flowAction.text ?? ""}
                      maxLength={500}
                      onChange={t => onDataChange(t)}
                      validate={props.validate}
                      flowAction={flowAction}
                      addCustomVariableText={props.addCustomVariableText}
                      borderRadiusTop="8px"
                      borderRadiusBottom="8px"
                    />
                    <FormControl>
                      <Flex mt={"25px"} w={"100%"} justifyContent={"center"} alignItems={"center"}>
                        <Tooltip variant={"dominoLight"} label={t("Text - Expected Contacts reply is a text message")}>
                          <Button
                            width="100%"
                            borderRightRadius="0"
                            variant={
                              flowAction.questionActionType === QuestionActionType.Text ? "dominoViolet" : "dominoOutlineViolet"
                            }
                            onClick={() => {
                              dispatch(
                                editNode({
                                  ...props.node,
                                  flowAction: {
                                    ...flowAction,
                                    questionActionType: QuestionActionType.Text,
                                  } as QuestionFlowActionModel,
                                }),
                              );
                            }}
                            data-pw="text-message-button"
                          >
                            {t("Text message")}
                          </Button>
                        </Tooltip>
                        <Tooltip
                          variant={"dominoLight"}
                          label={t("Media file - Expected Contacts reply is a message with media file")}
                        >
                          <Button
                            width="100%"
                            borderLeftRadius="0"
                            variant={
                              flowAction.questionActionType === QuestionActionType.Media ? "dominoViolet" : "dominoOutlineViolet"
                            }
                            onClick={() => {
                              dispatch(
                                editNode({
                                  ...props.node,
                                  flowAction: {
                                    ...flowAction,
                                    questionActionType: QuestionActionType.Media,
                                  } as QuestionFlowActionModel,
                                }),
                              );
                            }}
                            data-pw="media-file-button"
                          >
                            {t("Media file")}
                          </Button>
                        </Tooltip>
                      </Flex>
                    </FormControl>
                    <FormControl position="static">
                      <Box mt={"24px"}>
                        {flowAction.questionActionType === QuestionActionType.Text && (
                          <>
                            <h4>{t("editNodePopup.Save Response to a Custom Field")}</h4>
                            <Box>
                              <CustomVariableContextMenu
                                mt="8px"
                                type={CustomVariableContextMenuType.Dropdown}
                                selectCustomVariable={selectVariable}
                                addVariable={onCreateVariable()}
                                variables={customVariables ?? []}
                                chosenVariable={chosenCustomVariable}
                                excludeVariableType={CustomVariableType.Order}
                                clearVariable={() => setCustomVariable("")}
                              />
                            </Box>
                          </>
                        )}

                        {flowAction.questionActionType === QuestionActionType.Media && (
                          <>
                            <Box>
                              <Flex gap="4px" alignItems="center">
                                <h4>{t("Save media file to a custom field")}</h4>
                                <Tooltip
                                  variant="dominoLight"
                                  label={t(
                                    "Bot can only save one file to the field. To save multiple files, the contact needs to send them separately.",
                                  )}
                                  placement="right"
                                  maxW={"270px"}
                                >
                                  <Icon
                                    as={QuestionIcon}
                                    boxSize={"15px"}
                                    borderRadius={"50%"}
                                    fill="darkGrey"
                                    _hover={{ fill: "darkPurple" }}
                                  />
                                </Tooltip>
                              </Flex>
                              <Box>
                                <CustomVariableContextMenu
                                  mt="8px"
                                  type={CustomVariableContextMenuType.Dropdown}
                                  selectCustomVariable={selectMediaVariable}
                                  addVariable={onCreateMediaVariable()}
                                  variables={filteringType(customVariables, CustomVariableType.Array, chosenCustomVariable) ?? []}
                                  excludeVariableType={CustomVariableType.Order}
                                  chosenVariable={chosenMediaCustomVariable}
                                  onlyVariableType={CustomVariableType.Array}
                                  clearVariable={() => setMediaCustomVariable("")}
                                />
                              </Box>
                            </Box>
                            <Box mt={"24px"}>
                              <Flex gap="4px" alignItems="center">
                                <h4>{t("Save file caption to a custom field")}</h4>
                                <Tooltip
                                  variant="dominoLight"
                                  label={t("If you want to save file caption, select custom field. Otherwise, leave it blank")}
                                  placement="right"
                                  maxW={"270px"}
                                >
                                  <Icon
                                    as={QuestionIcon}
                                    boxSize={"15px"}
                                    borderRadius={"50%"}
                                    fill="darkGrey"
                                    _hover={{ fill: "darkPurple" }}
                                  />
                                </Tooltip>
                              </Flex>

                              <Box>
                                <CustomVariableContextMenu
                                  mt="8px"
                                  type={CustomVariableContextMenuType.Dropdown}
                                  selectCustomVariable={selectVariable}
                                  addVariable={onCreateVariable()}
                                  //variables={variables ?? []}
                                  variables={variables?.filter(variable => variable.type !== CustomVariableType.Order) ?? []}
                                  chosenVariable={chosenCustomVariable}
                                  clearVariable={() => setCustomVariable("")}
                                  excludeVariableType={CustomVariableType.Order}
                                />
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    </FormControl>
                    <div onClick={e => e.stopPropagation()} className={s.retry}>
                      {t("editNodePopup.Retry")}&nbsp;
                      <Menu variant="dominoDotsMenu" gutter={0} offset={[-26, -2]}>
                        {({ isOpen }) => (
                          <>
                            <MenuButton as={Text} className={s.retryText} color={isOpen ? "blueLink" : ""}>
                              {flowAction.retryCount} {t("editNodePopup.time(s)")}
                            </MenuButton>

                            <MenuList minWidth={"160px"}>
                              <MenuItem onClick={() => setRetryNumber("0")}>0 {t("editNodePopup.0 times")}</MenuItem>
                              <MenuItem onClick={() => setRetryNumber("1")}>1 {t("editNodePopup.1 time")}</MenuItem>
                              <MenuItem onClick={() => setRetryNumber("2")}>2 {t("editNodePopup.times")}</MenuItem>
                              <MenuItem onClick={() => setRetryNumber("3")}>3 {t("editNodePopup.times")}</MenuItem>
                              <MenuItem onClick={() => setRetryNumber("4")}>4 {t("editNodePopup.times")}</MenuItem>
                              <MenuItem onClick={() => setRetryNumber("5")}>5 {t("editNodePopup.5 times")}</MenuItem>
                            </MenuList>
                          </>
                        )}
                      </Menu>
                      &nbsp;<p className={s.text}>{t("editNodePopup.time(s) if the user reply is invalid")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
          <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => onCloseValidate()} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  );
};
