import { ActiveJoinOperatorsData, OperatorJoinData, OperatorLeftData } from "./ConversationViewerData";
import { OperatorJoinModel, OperatorLeftModel } from "./ConversationViewerModel";

export const mapOperatorJoinDataToModel = (source: OperatorJoinData) => {
  const model: OperatorJoinModel = {
    operatorId: source.operatorId,
    operatorName: source.operatorName,
    conversationId: source.conversationId,
  };
  return model;
};

export const mapActiveJoinOperatorsDataToModel = (source: ActiveJoinOperatorsData) => {
  const model: OperatorJoinModel = {
    operatorId: source.operatorId,
    operatorName: source.operator,
    conversationId: source.conversationId,
  };
  return model;
};

export const mapListOperatorJoinDataToOperatorJoinModel = (source: OperatorJoinData[]) => {
  const models = source.map(source => {
    return mapOperatorJoinDataToModel(source);
  });
  return models;
};

export const mapListActiveJoinOperatorsDataToModel = (source: ActiveJoinOperatorsData[]) => {
  const models = source.map(source => {
    return mapActiveJoinOperatorsDataToModel(source);
  });
  return models;
};

export const mapOperatorJoinModelToData = (source: OperatorJoinModel) => {
  const data: OperatorJoinData = {
    operatorId: source.operatorId,
    operatorName: source.operatorName,
    conversationId: source.conversationId,
  };
  return data;
};

export const mapOperatorLeftModelToData = (source: OperatorLeftModel) => {
  const data: OperatorLeftData = {
    operatorName: source.operatorName,
  };
  return data;
};
