import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BroadcastHeaderType } from "../../BroadcastModel";
import { selectBroadcastState } from "../../BroadcastSlice";
import { useAppSelector } from "../../../../common/state/store";
import { BackButton } from "../../../backButton/BackButton";
import { StatusTag } from "../../../broadcastList/components/BroadcastList/components/StatusTag/StatusTag";
import s from "./BroadcastHeader.module.scss";

interface BroadcastHeaderProps {
  type: BroadcastHeaderType;
}

export const BroadcastHeader: React.FC<BroadcastHeaderProps> = ({ type }) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const navigate = useNavigate();
  const { broadcast } = useAppSelector(selectBroadcastState);

  const headerConfig = {
    view: {
      title: t("Broadcast view"),
      showStatusTag: true,
    },
    new: {
      title: t("New Broadcast"),
      showStatusTag: false,
    },
    edit: {
      title: t("Edit Broadcast"),
      showStatusTag: true,
    },
  };

  const config = headerConfig[type] || {};

  return (
    <Box className={s.headerContainer}>
      <Box className={s.main}>
        <BackButton onButtonClick={() => navigate(-1)} />
        <Text className={s.title}>{config.title}</Text>
        {config.showStatusTag && <StatusTag variant="dominoSubtleHeader" broadcastStatus={broadcast?.status} />}
      </Box>
    </Box>
  );
};
