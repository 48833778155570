import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  ComplexFilterFieldTypes,
  ComplexFilterItemsModel,
  FieldParamModel,
  FilterParams,
  FilterParamTypes,
} from "../../ComplexFilterModel";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { ReactComponent as BackArrowIcon } from "../../../../assets/icons/backArrowIcon.svg";
import { forwardRef, useEffect, useState } from "react";
import s from "./ComplexFilterPopover.module.scss";
import dayjs from "dayjs";
import { customDateInput, DatePicker } from "../../../../UI/atoms/datePicker/DatePicker";
import { v1 as uuidv1 } from "uuid";
import { SelectVariableMenu } from "./SelectVariableMenu/SelectVariableMenu";
import { formatDateTimeToView } from "../../../../common/utils/formatDate";

interface Props {
  width?: string;
  isVariablesForbidden?: boolean;
  filterFields: ComplexFilterItemsModel;
  filterParams: FilterParams;
  id: string;
  menuStyle?: string;
  onSetFilterParam: (filterParamType: FilterParamTypes, fieldParamValue: FieldParamModel, id: string) => void;
  componentSlice?: string;
}

export const ComplexFilterPopover = (props: Props) => {
  const [popoverValue, setPopoverValue] = useState(props.filterParams.conditionValue?.value ?? "");
  const [chosenVariable, setChoseVariable] = useState(props.filterParams?.conditionValue?.variableValue ?? "");
  const [isPopoverOpen, setIsPopoverOpen] = useState(!popoverValue && !chosenVariable);
  const [isVariableTypeActive, setIsVariableTabActive] = useState(!!props.filterParams.conditionValue?.variableValue?.length);
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });
  const CustomInput = forwardRef(customDateInput);
  const locale = localStorage.getItem("i18nextLng") ?? "ru";
  useEffect(() => {
    if (isPopoverOpen) {
      setPopoverValue(props.filterParams.conditionValue?.value ?? "");
      setChoseVariable(props.filterParams?.conditionValue?.variableValue ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverOpen]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setIsPopoverOpen(false);
      props.onSetFilterParam(
        FilterParamTypes.conditionValue,
        {
          title: popoverValue,
          value: popoverValue,
          type: ComplexFilterFieldTypes.Text,
        },
        props.id,
      );
    }
  };

  const onApply = () => {
    setIsPopoverOpen(false);
    const fieldParamValue =
      isVariableTypeActive && chosenVariable
        ? {
            title: chosenVariable,
            value: "",
            variableValue: chosenVariable,
            type: props.filterParams.field?.type,
          }
        : {
            title: popoverValue,
            value: popoverValue,
            type: props.filterParams.field?.type,
          };
    props.onSetFilterParam(FilterParamTypes.conditionValue, fieldParamValue, props.id);
  };

  const onClosePopover = () => {
    setIsPopoverOpen(false);
    setPopoverValue(props.filterParams.conditionValue?.value ?? "");
  };

  const onOpenPopover = () => {
    setIsPopoverOpen(true);
  };

  const changeSourceType = (isActive: boolean) => () => {
    setIsVariableTabActive(isActive);
  };

  const selectCustomVariable = (variableValue: string) => {
    setChoseVariable(variableValue);
  };

  const isValidValue = () => {
    if (isVariableTypeActive && !chosenVariable) {
      return false;
    }
    if (!isVariableTypeActive && !popoverValue.trim().length) {
      return false;
    }
    return true;
  };

  return (
    <Popover
      onClose={onClosePopover}
      isOpen={isPopoverOpen}
      onOpen={onOpenPopover}
      autoFocus
      placement="bottom"
      offset={[Number(props.width), 10]}
      variant="dominoPopover"
      arrowSize={0}
    >
      <>
        <PopoverTrigger>
          <Box
            className={props.componentSlice ? `${s.popoverTrigger} ${s[`${props.componentSlice}`]}` : s.popoverTrigger}
            borderBottom={`2px solid ${isPopoverOpen ? "var(--chakra-colors-blueLink)" : "var(--chakra-colors-line)"}`}
            color={isPopoverOpen ? "mainPurple" : "black"}
            _hover={{ borderColor: "blueLink" }}
          >
            {isVariableTypeActive ? (
              <>{chosenVariable ? chosenVariable : <pre>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</pre>}</>
            ) : (
              <>
                {!popoverValue ? (
                  <pre>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</pre>
                ) : (
                  formatDateTimeToView(props.filterParams.field?.type, popoverValue, locale)
                )}
              </>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          width={{ base: "90%", sm: "100%" }}
          className={`${props.menuStyle === "conditionNode" && s.conditionNodeVariableModal}`}
        >
          <PopoverBody>
            {props.filterFields.groups.length && !props.isVariablesForbidden && (
              <Flex mb="16px" w={"100%"} justifyContent={"center"} alignItems={"center"}>
                <Button
                  ml="0"
                  borderRightRadius="0"
                  width="100%"
                  variant={isVariableTypeActive ? "dominoOutlineViolet" : "dominoViolet"}
                  onClick={changeSourceType(false)}
                >
                  {t("Value")}
                </Button>
                <Button
                  width="100%"
                  borderLeftRadius="0"
                  variant={isVariableTypeActive ? "dominoViolet" : "dominoOutlineViolet"}
                  onClick={changeSourceType(true)}
                >
                  {t("Variable")}
                </Button>
              </Flex>
            )}
            {isVariableTypeActive && props.filterFields.groups.length ? (
              <SelectVariableMenu
                filterFields={props.filterFields}
                filterParams={props.filterParams}
                chosenVariable={chosenVariable}
                selectCustomVariable={selectCustomVariable}
                filterParamType={FilterParamTypes.conditionValue}
                id={props.id ?? uuidv1()}
              />
            ) : (
              <InputGroup w="auto">
                {props.filterParams.field?.type !== "DateTime" && props.filterParams.field?.type !== "Date" && (
                  <Input
                    autoFocus
                    value={popoverValue}
                    type={props.filterParams.field?.type}
                    onChange={e => setPopoverValue(e.target.value)}
                    onKeyDown={e => {
                      handleKeyPress(e);
                    }}
                    data-pw="complex-input"
                  />
                )}
                {props.filterParams.field?.type === "DateTime" && (
                  <DatePicker
                    dateFormat={locale !== "en" ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy h:mm a"}
                    selected={popoverValue ? new Date(popoverValue) : null}
                    onChange={scheduledDate => {
                      setPopoverValue(dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD HH:mm") : "");
                    }}
                    strictParsing
                    popperPlacement="left"
                    popperModifiers={[
                      { name: "offset", options: { offset: [28, 8] } },
                      {
                        name: "preventOverflow",
                        options: {
                          mainAxis: false, // true by default
                        },
                      },
                    ]}
                    placeholderText={t("placeholderDateTime") ?? ""}
                    showTimeSelect
                    customInput={<CustomInput width="225px" />}
                  />
                )}

                {props.filterParams.field?.type === "Date" && (
                  <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={popoverValue ? new Date(popoverValue) : null}
                    onChange={scheduledDate => {
                      setPopoverValue(dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD") : "");
                    }}
                    strictParsing
                    popperPlacement="left"
                    popperModifiers={[
                      { name: "offset", options: { offset: [28, 8] } },
                      {
                        name: "preventOverflow",
                        options: {
                          mainAxis: false, // true by default
                        },
                      },
                    ]}
                    placeholderText={t("placeholderDate") ?? ""}
                    customInput={<CustomInput width="225px" />}
                  />
                )}
                <InputRightElement onClick={() => setPopoverValue("")} right="10px" top="50%" mt="-11px" boxSize="22px">
                  {popoverValue && <CrossIcon className={s.crossIcon} cursor="pointer" />}
                </InputRightElement>
              </InputGroup>
            )}
          </PopoverBody>
          <PopoverFooter>
            <Flex alignItems="center" justifyContent="space-between" h="84px">
              <Button variant="dominoBlueGhost" w="87px" ml="10px" onClick={onClosePopover} data-pw="cancel-button">
                <BackArrowIcon fill="defaultGrey" />
                &nbsp;&nbsp;&nbsp;{t("Back")}
              </Button>
              <Button
                variant="dominoViolet"
                w="87px"
                mr="24px"
                isDisabled={!isValidValue()}
                onClick={onApply}
                data-pw="apply-button"
              >
                {t("Apply")}
              </Button>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      </>
    </Popover>
  );
};
