import { useTabletView } from "../../LayoutHelper/ResolutionHooks";
import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useAppSelector } from "../../../../common/state/store";
import { selectOrganisation } from "../../../organisation/OrganisationSlice";
import { useParams } from "react-router-dom";
import { getOrganisationIdFromStorage } from "../../../../common/BaseApi";
import { ExecPlugin, execPlugin, LoadScript, loadScript } from "./handlers";
import { selectIsOverlayShown } from "../Common/CommonSlice";
import s from "./PluginPage.module.scss";

export const PluginPage = () => {
  const isTablet = useTabletView();
  const [mobileView, isMobileView] = useState(isTablet);
  const organisation = useAppSelector(selectOrganisation);
  const isOverlayShown = useAppSelector(selectIsOverlayShown);
  const { pluginName } = useParams();

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  const getFrameUrl = () => {
    if (!organisation) {
      return "";
    }

    const plugin = organisation.plugins?.find(x => x.name === pluginName);
    return plugin?.url?.replace("{:orgId}", getOrganisationIdFromStorage());
  };

  const loadScriptWrapper = (ev: MessageEvent<LoadScript>) => loadScript(ev, () => getFrameUrl());
  const execPluginWrapper = (ev: MessageEvent<ExecPlugin>) => execPlugin(ev, () => getFrameUrl());
  useEffect(() => {
    window.addEventListener("message", loadScriptWrapper);
    window.addEventListener("message", execPluginWrapper);

    return () => {
      window.removeEventListener("message", loadScriptWrapper);
      window.removeEventListener("message", execPluginWrapper);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  return (
    <>
      <Flex flexDirection={"column"} height={"100%"} id="pluginContainer" className={s.container}>
        <iframe className={s.iframe} title="pluginIframe" src={getFrameUrl()} allow="clipboard-read; clipboard-write" />
      </Flex>
      {isOverlayShown && <div className={s.overlay}></div>}
    </>
  );
};
