import { Box } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import s from "../../ChatList.module.scss";
import { ChatListItemModel, ChatListModel, TeamsModel, OverLimitConversationsModel } from "../../ChatListModel";

import { MutableRefObject, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { selectConversationUploading, setSearchText } from "../../ChatListSlice";
import { ConversationsOverLimitPanel } from "../ConversationsOverLimitPanel";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import ChatListItem from "./components/ChatListItem/ChatListItem";
import ChatListFilters from "./components/ChatListFilters/ChatListFilters";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../../common/hooks/useDebounce";
import { constructSearchParams } from "../../../../common/utils/constructSearchParams";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";
import { useMobileView, useTabletView } from "../../../layout/LayoutHelper/ResolutionHooks";

interface Props {
  chatList?: ChatListModel;
  chatListRef: MutableRefObject<HTMLDivElement | null>;
  myTeams?: TeamsModel;
  getChatListOnScroll: (lastId: string) => void;
  overLimitConversations?: OverLimitConversationsModel[];
}

function debounce(func: { (): void; (arg0: any[]): void }, wait: number | undefined) {
  let timeout: string | number | NodeJS.Timeout | undefined;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(args), wait);
  };
}

export const ChatList = ({ chatList, chatListRef, getChatListOnScroll, myTeams, overLimitConversations }: Props) => {
  const chatListInnerRef = useRef<null | HTMLDivElement>(null);
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  const checkHightElements = () => {
    if (chatListInnerRef.current && chatListRef.current) {
      const innerHeight = chatListInnerRef.current.getBoundingClientRect().height;
      const outerHeight = chatListRef.current.getBoundingClientRect().height;
      const needLoadMore = innerHeight <= outerHeight;
      if (needLoadMore) {
        getAdditionalChatsOnScroll();
      }
    }
  };
  const debouncedCheckHightElements = debounce(checkHightElements, 500);

  const { t } = useTranslation("translation", { keyPrefix: "chatList" });
  const dispatch = useAppDispatch();
  const isLoading = useGetLoadingState("getChatList");

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [searchValue, setSearchValue] = useState(search);
  const debouncedSearchText = useDebounce(searchValue, 500);

  useEffect(() => {
    constructSearchParams(search, debouncedSearchText, setSearchParams);
    dispatch(setSearchText(debouncedSearchText));
  }, [searchValue, debouncedSearchText, search, setSearchParams, dispatch]);

  const canUploadConv = useAppSelector(selectConversationUploading);

  const overLimitCount = overLimitConversations?.length ?? 0;
  const isOverLimit = overLimitCount > 0;
  const isTablet = useTabletView();
  const isMobile = useMobileView();

  const trackEvent = useGAWithCustomParameters("Chats");

  useEffect(() => {
    if (
      chatListRef.current?.scrollHeight === chatListRef.current?.clientHeight &&
      chatList &&
      chatList?.totalItems > chatList?.items.length &&
      isLoading
    ) {
      getChatListOnScroll(chatList.lastId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatList, chatListRef]);

  useEffect(() => {
    if (chatList && chatList?.totalItems) {
      trackEvent("ChatlistLoaded", "", { count: chatList.totalItems });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatList, chatList?.totalItems]);

  const getAdditionalChatsOnScroll = () => {
    if (chatListRef.current) {
      const scrollHeight = chatListRef.current.scrollHeight;
      const scrollTop = chatListRef.current.scrollTop;
      const clientHeight = chatListRef.current?.clientHeight;
      const isBottom = clientHeight + scrollTop > scrollHeight - 2;

      if (isBottom && chatList && !isLoading && canUploadConv) {
        const lastId = Number(chatList.lastId);
        const id = String(lastId + 1);
        getChatListOnScroll(id);
      }
    }
  };
  checkHightElements();

  useEffect(() => {
    if (chatListRef.current) {
      const observer = new ResizeObserver(() => {
        debouncedCheckHightElements();
      });
      observer.observe(chatListRef.current);
      return () => {
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatListRef.current]);

  return (
    <Box className={s.chatListContainer}>
      <Box className={s.header}>
        <Box className={s.searchBlock}>
          <InputSearch placeholder={t("Search by name")} setSearchText={setSearchValue} searchText={searchValue} />
        </Box>
        <ChatListFilters chatList={chatList} myTeams={myTeams} />
        {isOverLimit && (
          <Box className={s.overLimitItem}>
            <ConversationsOverLimitPanel overLimitCount={overLimitCount} />
          </Box>
        )}
      </Box>
      <Box
        className={s.content}
        h={`calc(100dvh - ${isTablet ? "237px" : "177px"} - ${isTrial && isMobile ? "60px" : "0px"})`}
        ref={chatListRef}
        onScroll={getAdditionalChatsOnScroll}
      >
        <Box h="100%" position="relative" ref={chatListInnerRef}>
          {!chatList?.items.length && !isOverLimit ? (
            <Box className={s.noData}>{t("No Conversations")}</Box>
          ) : (
            chatList?.items?.map((chat: ChatListItemModel, index: number) => {
              const compareDate = chat?.lastMessage?.date == null ? chat?.createDate : chat?.lastMessage.date;
              return <ChatListItem chat={chat} compareDate={compareDate} key={index} />;
            })
          )}
          <Box maxH="10dvh">
            <Loading scope="getChatList" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
