import { put } from "redux-saga/effects";
import { PaymentRequiredError, UnauthorizedError } from "./ErrorModel";
import { NotificationModel } from "./notifications/NotificationModel";
import { notificationSlice } from "./notifications/NotificationSlice";

import i18n from "../common/i18next/i18n";

export function* handleException(error: unknown) {
  if (error instanceof UnauthorizedError) {
    console.warn(error.message);
    return;
  }

  if (error instanceof PaymentRequiredError) {
    const notification: NotificationModel = {
      message: (error as Error).message,
      type: "paymentError",
      duration: 5000,
    };
    yield put(notificationSlice.actions.notify(notification));
    return;
  }

  if ((error as Error).message) {
    const notification: NotificationModel = {
      message: (error as Error).message,
      type: "error",
    };

    yield put(notificationSlice.actions.notify(notification));
    return;
  }

  yield put(
    notificationSlice.actions.notify({
      message: i18n.t("commonWords.Oops... Something went wrong"),
      type: "error",
    }),
  );
}
