import { store } from "../..";
import { ButtonTypeDiscriminator } from "../../common/AppEnums";
import { ValidationError } from "../../common/ErrorModel";
import { ConditionsByField } from "../complexFilter/ComplexFilterModel";
import {
  AssignFlowActionModel,
  ButtonsContainerFlowAction,
  CalendarFlowActionModel,
  ConditionFlowActionModel,
  CurlFlowActionModel,
  DelayFlowActionModel,
  FlowActionModel,
  FlowActionType,
  NodeValidation,
  PutItemIntoArrayModel,
  QuestionFlowActionModel,
  SendAudioFlowActionModel,
  SendDocumentFlowActionModel,
  SendPhotoFlowActionModel,
  SendTextFlowActionModel,
  SendVideoFlowActionModel,
  SetVariableModel,
  StartFlowActionModel,
  SystemActionFlowActionModel,
  TakeItemFromArrayModel,
  TextContainerFlowAction,
} from "./FlowBuilderModel";
import { getButtonsWithExactType } from "./utils";
import { TFunction } from "i18next";

const validateVariables = (text: string, t: TFunction) => {
  const regex = /@{([\wа-яА-ЯёЁ-]+):([\wа-яА-ЯёЁ-]+)}/g;
  const customVariables = store.getState().app.flowBuilderState.customVariables;
  const variables = text.match(regex);
  const notExist = variables?.find(el => {
    const scopeAndKey = el.replace(regex, "$1:$2");
    const [scope, key] = scopeAndKey.split(":");
    const isVariableExist = customVariables?.find(el => key && el.key === key && el.scope === scope);
    return isVariableExist === undefined;
  });

  if (notExist) {
    return t("variableDoesNotExist", { variable: notExist });
  }
};

export const validateNode = (node: FlowActionModel, t: TFunction) => {
  const result: NodeValidation = {
    errors: [],
  };

  const btnContainer = node as ButtonsContainerFlowAction;

  if (
    btnContainer &&
    getButtonsWithExactType(btnContainer.replyMarkup, node) &&
    getButtonsWithExactType(btnContainer.replyMarkup, node)?.length
  ) {
    getButtonsWithExactType(btnContainer.replyMarkup, node)?.forEach(element =>
      element.forEach(btn => {
        if (
          btn.typeDiscriminator ===
            (ButtonTypeDiscriminator.FlowMessageUrlButton || ButtonTypeDiscriminator.FlowMessageCallbackButton) &&
          btn.label.trim().length < 1
        ) {
          result.errors.push({
            field: "buttons",
            message: "Please enter a button title",
          });
        }

        if (
          btn.typeDiscriminator ===
            (ButtonTypeDiscriminator.FlowMessageUrlButton || ButtonTypeDiscriminator.FlowMessageCallbackButton) &&
          btn.label.length > 35
        ) {
          result.errors.push({
            field: "buttons",
            message: "Button title must be less than 35 characters long",
          });
        }
      }),
    );
    if (btnContainer.isFallback && (!btnContainer.fallbackMessage || btnContainer?.fallbackMessage.length < 1)) {
      result.errors.push({
        field: "fallback",
        message: "Fallback can't be empty",
      });
    }
    if (btnContainer.isFallback && btnContainer.fallbackMessage && btnContainer?.fallbackMessage?.trim()?.length < 1) {
      result.errors.push({
        field: "fallback",
        message: "Fallback message can't contain only spaces",
      });
    }
  }

  let text = "";
  const textContainer = node as TextContainerFlowAction;
  if (textContainer && textContainer.text) {
    text = textContainer.text || "";
    // validate variables
    const textVariablesError = validateVariables(text, t);
    if (textVariablesError) {
      result.errors.push({
        field: "text",
        message: textVariablesError,
      });
    }
  }

  // validate fields by type
  switch (node.typeDiscriminator) {
    case FlowActionType.SendTextFlowAction: {
      const instance = node as SendTextFlowActionModel;

      // validate not empty
      if (text.length < 1) {
        result.errors.push({
          field: "text",
          message: "Text can't be empty",
        });
      }

      // validate not spacebars only
      if ((instance.text || "")?.trim().length < 1) {
        result.errors.push({
          field: "text",
          message: "Text can't contain only spaces",
        });
      }

      // validate length
      if (text.length > 2000) {
        result.errors.push({
          field: "text",
          message: "Text must be less than 2000 characters long",
        });
      }
      break;
    }
    case FlowActionType.SendPhotoFlowAction: {
      const instance = node as SendPhotoFlowActionModel;
      if (!instance.fileUrl && !instance.fileId && !instance.file) {
        result.errors.push({
          field: "file",
          message: "Add image or url",
        });
      }

      const instanceText = instance.text || "";
      // validate not spacebars only
      if (instanceText.length > 0 && instanceText.trim().length < 1) {
        result.errors.push({
          field: "text",
          message: "Text can't contain only spaces",
        });
      }

      // validate length
      if (instanceText.length > 500) {
        result.errors.push({
          field: "text",
          message: "Text must be less than 500 characters long",
        });
      }
      break;
    }
    case FlowActionType.SendDocumentFlowAction: {
      const instance = node as SendDocumentFlowActionModel;
      const instanceText = instance.text || "";

      // validate not spacebars only
      if (instanceText.length > 0 && instanceText.trim().length < 1) {
        result.errors.push({
          field: "text",
          message: "Text can't contain only spaces",
        });
      }
      // validate length
      if (instanceText.length > 500) {
        result.errors.push({
          field: "text",
          message: "Text must be less than 500 characters long",
        });
      }
      // file validation
      if (!instance.fileUrl && !instance.fileId && !instance.file) {
        result.errors.push({
          field: "file",
          message: "Add file",
        });
      }
      // file size limits
      const fileImageSizeMB = 10;
      const fileDocumentSizeMB = 50;

      let fileSizeB = 1024 * 1024;
      fileSizeB *= instance.typeDiscriminator === FlowActionType.SendPhotoFlowAction ? fileImageSizeMB : fileDocumentSizeMB;

      if (instance.file?.size && instance.file.size > fileSizeB) {
        result.errors.push({
          field: "file",
          message: "File is too big",
        });
      }
      break;
    }
    case FlowActionType.CurlFlowAction: {
      const isntance = node as CurlFlowActionModel;
      const curl = isntance.curlCommand || "";
      // validate not empty
      if (curl.length < 1) {
        result.errors.push({
          field: "curl",
          message: "Text field can't be empty",
        });
      }

      // validate not spacebars only
      if (curl.length > 0 && curl.trim().length < 1) {
        result.errors.push({
          field: "curl",
          message: "Text can't contain only spaces",
        });
      }

      // validate groups
      const isJsonFieldsEmpty =
        isntance.targetCustomVariables && isntance?.targetCustomVariables.some(el => !el.targetCustomVariableId);

      if (isntance.canSaveResponse && (!isntance.targetCustomVariables || isJsonFieldsEmpty)) {
        result.errors.push({
          field: "curl",
          message: "Select variable in the JSON group",
        });
      }

      // variables
      const varialblesCheck = validateVariables(curl, t);
      if (varialblesCheck) {
        result.errors.push({
          field: "curl",
          message: varialblesCheck,
        });
      }
      break;
    }
    case FlowActionType.QuestionFlowAction: {
      const instance = node as QuestionFlowActionModel;
      const instanceText = instance.text || "";

      // validate not empty
      if (instanceText.length < 1) {
        result.errors.push({
          field: "text",
          message: "Text field can't be empty",
        });
      }

      // validate not spacebars only
      if (instanceText.length > 0 && instanceText.trim().length < 1) {
        result.errors.push({
          field: "text",
          message: "Text can't contain only spaces",
        });
      }

      // validate length
      if (instanceText.length > 500) {
        result.errors.push({
          field: "text",
          message: "Text must be less than 500 characters long",
        });
      }
      // custom variable selected
      if (!instance.targetCustomVariableId) {
        result.errors.push({
          field: "targetCustomVariable",
          message: "Choose custom variable",
        });
      }

      break;
    }
    case FlowActionType.SendAppointmentFlowAction: {
      const instance = node as CalendarFlowActionModel;
      const instanceText = instance.text || "";

      // validate not empty
      if (instanceText.length < 1) {
        result.errors.push({
          field: "text",
          message: "Text field can't be empty",
        });
      }

      // validate not spacebars only
      if (instanceText.length > 0 && instanceText.trim().length < 1) {
        result.errors.push({
          field: "text",
          message: "Text can't contain only spaces",
        });
      }

      // validate length
      if (instanceText.length > 500) {
        result.errors.push({
          field: "text",
          message: "Text must be less than 500 characters long",
        });
      }
      // custom variable selected
      if (!instance.targetCustomVariableId) {
        result.errors.push({
          field: "targetCustomVariable",
          message: "Choose custom variable",
        });
      }
      //fallback
      if (instance.isFallback && !instance.fallbackMessage) {
        result.errors.push({
          field: "fallback",
          message: "Fallback can't be empty",
        });
      }

      break;
    }
    case FlowActionType.SendAudioFlowAction: {
      const instance = node as SendAudioFlowActionModel;
      if (!instance.fileUrl && !instance.fileId && !instance.file) {
        result.errors.push({
          field: "file",
          message: "Add audio",
        });
      }

      const instanceText = instance.text || "";
      // validate not spacebars only
      if (instanceText.length > 0 && instanceText.trim().length < 1) {
        result.errors.push({
          field: "text",
          message: "Text can't contain only spaces",
        });
      }

      // validate length
      if (instanceText.length > 500) {
        result.errors.push({
          field: "text",
          message: "Text must be less than 500 characters long",
        });
      }
      break;
    }
    case FlowActionType.SendVideoFlowAction: {
      const instance = node as SendVideoFlowActionModel;
      if (!instance.fileUrl && !instance.fileId && instance.file === undefined) {
        result.errors.push({
          field: "file",
          message: "Add video",
        });
      }

      const instanceText = instance.text || "";
      // validate not spacebars only
      if (instanceText.length > 0 && instanceText.trim().length < 1) {
        result.errors.push({
          field: "text",
          message: "Text can't contain only spaces",
        });
      }

      // validate length
      if (instanceText.length > 500) {
        result.errors.push({
          field: "text",
          message: "Text must be less than 500 characters long",
        });
      }
      break;
    }

    case FlowActionType.ConditionFlowAction: {
      const instance = node as ConditionFlowActionModel;
      const isValidError = instance.conditions.find(
        el =>
          !el.conditionCustomVariableId ||
          !el.condition ||
          (!el.value && !ConditionsByField.noValueConditions.includes(el.condition) && !el.valueCustomVariableId),
      );
      if (isValidError) {
        result.errors.push({
          field: "condition",
          message: "All conditions must be specified",
        });
      }
      break;
    }

    case FlowActionType.DelayFlowAction: {
      const instance = node as DelayFlowActionModel;
      if (!instance.value) {
        result.errors.push({
          field: "delay",
          message: "Delay value can't be empty",
        });
      }
      break;
    }

    case FlowActionType.AssignFlowAction: {
      const instance = node as AssignFlowActionModel;
      if (!instance.operatorId && !instance.teamId) {
        result.errors.push({
          field: "assign",
          message: "Operator or Team must be specified",
        });
      }
      break;
    }

    case FlowActionType.StartSubFlowAction: {
      const instance = node as StartFlowActionModel;
      if (!instance.flowId) {
        result.errors.push({
          field: "startFlow",
          message: "Start Flow must be specified",
        });
      }
      break;
    }

    case FlowActionType.SystemActionFlowAction: {
      const instance = node as SystemActionFlowActionModel;
      if (instance.typeDiscriminator === FlowActionType.SystemActionFlowAction) {
        result.errors.push({
          field: "systemAction",
          message: "Specify an action",
        });
      }
      break;
    }

    case FlowActionType.SetVariableValueFlowAction: {
      const instance = node as SetVariableModel;
      if ((!instance.sourceCustomVariableId && !instance.value) || !instance.targetCustomVariableId) {
        result.errors.push({
          field: "systemAction",
          message: "All fields should be filled",
        });
      }

      if (instance.value && instance.targetCustomVariableId && instance?.sourceType === "value") {
        const customVariables = store.getState().app.flowBuilderState.customVariables;
        const chosenTarget = customVariables?.find(el => {
          const targetCustomVariableId = instance.targetCustomVariableId;
          return el.id === targetCustomVariableId;
        });
        const possibleValues = ["True", "False"];
        if (chosenTarget?.type === "Boolean" && !possibleValues.find(el => el === instance.value)) {
          result.errors.push({
            field: "systemAction",
            message: "Boolean should be true or false",
          });
        }
      }
      break;
    }

    case FlowActionType.TakeItemFromArrayFlowAction: {
      const instance = node as TakeItemFromArrayModel;

      if (!instance?.sourceCustomVariableId) {
        result.errors.push({
          field: "systemAction",
          message: "Select variable for source",
        });
      }

      const isJsonFieldsEmpty =
        instance.targetCustomVariables && instance.targetCustomVariables.some(el => !el.targetCustomVariableId);

      if (isJsonFieldsEmpty) {
        result.errors.push({
          field: "systemAction",
          message: "Select variable in the JSON group",
        });
      }
      break;
    }

    case FlowActionType.PutItemIntoArrayFlowAction: {
      const instance = node as PutItemIntoArrayModel;

      if ((!instance.sourceCustomVariableId && !instance.value) || !instance.targetCustomVariableId) {
        result.errors.push({
          field: "systemAction",
          message: "All fields should be filled",
        });
      }

      if (instance.value && instance.targetCustomVariableId && instance?.sourceType === "value") {
        const customVariables = store.getState().app.flowBuilderState.customVariables;
        const chosenTarget = customVariables?.find(el => {
          const targetCustomVariableId = instance.targetCustomVariableId;
          return el.id === targetCustomVariableId;
        });
        const possibleValues = ["true", "false"];
        if (chosenTarget?.type === "Boolean" && !possibleValues.find(el => el === instance.value)) {
          result.errors.push({
            field: "systemAction",
            message: "Boolean should be true or false",
          });
        }
      }

      break;
    }

    default:
      break;
  }

  if (result.errors.length) {
    throw new ValidationError(result);
  } else {
    return result;
  }
};
