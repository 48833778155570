import { httpGet, httpPost } from "../../common/BaseApi";

export const getToursApi = (username: string) => {
  return httpGet(`/api/user/${username}/onboarding`);
};

export const completeTourApi = (tourName: string) => {
  return httpPost(`/api/user/onboarding/complete`, { tour: tourName });
};

export const skipTourApi = (tourName: string) => {
  return httpPost(`/api/user/onboarding/skip`, { tour: tourName });
};
