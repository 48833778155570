import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectCurrentBotId } from "../../../sidebar/SidebarSlice";
import {
  clearState,
  getBroadcast,
  getCustomVariables,
  selectBroadcastState,
  setCurrentFile,
  selectCustomVariables,
  selectBroadcastIsNew,
} from "../../BroadcastSlice";
import { BroadcastMessageTypeDescriminator, FileUploadType } from "../../BroadcastModel";
import s from "./Broadcast.module.scss";
import { BroadcastForm } from "../Broadcast/BroadcastForm";
import { useTranslation } from "react-i18next";
import { ComplexFilterFieldTypes, ComplexFilterGroupTypes, FilterParams } from "../../../complexFilter/ComplexFilterModel";

import { getTitleByFieldTitle } from "../../../complexFilter/CreateFilterFields";
import {
  parseRecipientFilterConditionTitle,
  parseRecipientFilterConditionValue,
} from "../../../complexFilter/ComplexFilterMapper";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";

const BROADCAST_VIEW_STATUSES = ["Scheduled", "Sending", "Finished", "Cancelled", "Failed"];

export type BroadcastHeaderType = "view" | "new" | "edit";

export const Broadcast = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { broadcastId } = useParams();
  const bt = useTranslation("translation", { keyPrefix: "broadcast.fieldToFilter" }).t;
  const ccf = useTranslation("translation", {
    keyPrefix: "complexFilter.conditionsByType",
  }).t;

  const currentBotId = useAppSelector(selectCurrentBotId);
  const broadcastState = useAppSelector(selectBroadcastState);
  const isBroadcastNew = useAppSelector(selectBroadcastIsNew);
  const variables = useAppSelector(selectCustomVariables);
  const recipientList = useAppSelector((state: RootState) => state.app.broadcastState.recipientList);
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  const status = broadcastState.broadcast?.status;
  const broadcast = broadcastState.broadcast;
  const [broadcastFilter, setBroadcastFilter] = useState<FilterParams[]>([{}]);

  useEffect(() => {
    if (broadcast?.recipientFilter && broadcast.recipientFilter.length && variables && variables.length > 0) {
      const filters = broadcast.recipientFilter.flatMap(filter => {
        return filter.members.map(member => {
          const filterParam: FilterParams = {
            field: {
              title:
                member?.targetCustomVariable && variables
                  ? variables.find(el => el.id === member.targetCustomVariable)?.key ?? ""
                  : bt(`${getTitleByFieldTitle(member.field ?? "")}`) ?? "",
              value:
                member?.targetCustomVariable && variables
                  ? variables.find(el => el.id === member.targetCustomVariable)?.key ?? ""
                  : member.field ?? "",
              type: member?.valueType
                ? ComplexFilterFieldTypes[member.valueType as keyof typeof ComplexFilterFieldTypes]
                : undefined,
              id: member?.targetCustomVariable ?? null,
              groupType: !!member.targetCustomVariable
                ? ComplexFilterGroupTypes.CustomVariables
                : ComplexFilterGroupTypes.ContactFields,
            },
            condition: {
              value: parseRecipientFilterConditionValue(member),
              title: ccf(parseRecipientFilterConditionTitle(member)),
            },
            conditionValue: {
              title: member?.value ?? "",
              value: member.valueType === "DateTime" ? member?.value : member.value ?? "",
            },
            id: member.filterId,
          };
          return filterParam;
        });
      });

      setBroadcastFilter(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcast?.recipientFilter, variables]);

  useEffect(() => {
    if (currentBotId) {
      dispatch(getCustomVariables({ botId: currentBotId }));
      if (!isBroadcastNew && broadcastId) {
        dispatch(getBroadcast({ botId: currentBotId, broadcastId }));
      }
    }
    return () => {
      dispatch(clearState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastId, currentBotId, isBroadcastNew]);

  useEffect(() => {
    if (!isBroadcastNew && broadcastState.broadcast) {
      if (broadcastState.broadcast.message.typeDiscriminator === BroadcastMessageTypeDescriminator.BroadcastDocumentMessage) {
        const { fileName, fileId } = broadcastState.broadcast.message;
        dispatch(setCurrentFile({ file: { fileName, fileId }, type: FileUploadType.document }));
      }
      if (broadcastState.broadcast.message.typeDiscriminator === BroadcastMessageTypeDescriminator.BroadcastPhotoMessage) {
        const { fileName, fileId } = broadcastState.broadcast.message;
        dispatch(setCurrentFile({ file: { fileName, fileId }, type: FileUploadType.photo }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastState.broadcast, isBroadcastNew]);

  useEffect(() => {
    if (status && BROADCAST_VIEW_STATUSES.includes(status)) {
      navigate(-1);
    }
  }, [navigate, status]);

  useEffect(() => {
    setBroadcastFilter([{}]);
  }, [recipientList?.availableRecipients]);

  return (
    <>
      <Box p={{ base: "12px", sm: "32px" }} className={`${!isTrial ? s.broadcastContainer : s.containerWithTrial}`}>
        <BroadcastForm broadcastRecipientsFilters={broadcastFilter} setBroadcastFilter={setBroadcastFilter} />
      </Box>
    </>
  );
};
