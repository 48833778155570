import { LinkedAccountModel } from "../../components/userProfile/UserProfileModel";

export enum LanguageEnum {
  en = "English",
  ru = "Русский",
}

export enum LanguageCodeEnum {
  en = "en",
  ru = "ru",
}

export enum UserNotificationsProvider {
  none = "None",
  dominoBot = "DominoBot",
  customBot = "CustomBot",
}

export interface UserModel {
  id: string;
  locale: LanguageCodeEnum;
  hasPassword: boolean;
  username: string;
  email?: string;
  linkedAccounts: LinkedAccountModel[];
}
