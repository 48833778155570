import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { BotListHeader } from "../../../botList/components/BotListHeader/BotListHeader";
import { BotListContainer } from "../../../botList/BotListContainer";
import s from "./BotsPage.module.scss";
import { useAppSelector } from "../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";
import { TABLET_VIEW, MOBILE_VIEW } from "../../LayoutHelper/ResolutionConst";
import { useNowWidthView } from "../../LayoutHelper/ResolutionHooks";

export const BotsPage = () => {
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const nowWidth = useNowWidthView();
  return (
    <Flex className={s.botsPageContainer}>
      <ProtectedHeaderContainer>
        <BotListHeader />
      </ProtectedHeaderContainer>
      <Box
        className={s.botsPageListContainer}
        height={
          nowWidth <= TABLET_VIEW
            ? `calc(100dvh - ${isTrial && nowWidth <= MOBILE_VIEW ? "180px" : "120px"})`
            : "calc(100dvh - 60px)"
        }
      >
        <Loading />
        <BotListContainer />
      </Box>
    </Flex>
  );
};
