import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { BroadcastHeader } from "../../../../broadcast/components/BroadcastHeader/BroadcastHeader";
import { BroadcastHeaderType } from "../../../../broadcast/BroadcastModel";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import s from "./BroadcastPagesLayout.module.scss";

export const BroadcastPagesLayout = (children: React.ReactNode, broadcastType: BroadcastHeaderType) => {
  return (
    <Flex className={s.pageContainer}>
      <ProtectedHeaderContainer>
        <BroadcastHeader type={broadcastType} />
      </ProtectedHeaderContainer>
      <Box className={s.pageContent}>
        <Loading />
        {children}
      </Box>
    </Flex>
  );
};
