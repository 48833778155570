import { Flex, Text, Image, Hide, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import botImage from "../../assets/images/botImageStart.svg";
import s from "./AuthSidebar.module.scss";
interface Props {
  title: string;
}
export const AuthSidebar = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  return (
    <Hide breakpoint="(max-width: 999px)">
      <Flex className={s.authLeftSide}>
        <Box className={s.authLeftSideContent}>
          <Image className={s.authLeftImage} src={botImage}></Image>
          <Text className={s.authLeftTitle}>{t(props.title)}</Text>
        </Box>
      </Flex>
    </Hide>
  );
};
