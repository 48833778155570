import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { selectConversationInfo } from "../conversation/ConversationSlice";
import { OperatorViewBox } from "./OperatorViewBox/components/OperatorViewBox";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { selectUser } from "../../common/user/UserSlice";
import { selectCurrentBot } from "../sidebar/SidebarSlice";
import { operatorViewConversation, selectOperatorViewersInChats } from "./ConversationViewerSlice";
import { OperatorJoinModel } from "./ConversationViewerModel";

interface Props {
  id: string | undefined;
  getMessages: (lastId: string, searchValue?: string, currentSearchMessagesLength?: number, includeMessageId?: string) => void;
}

export const ConversationViewerContainer = (props: Props) => {
  const operatorViewers = useAppSelector(selectOperatorViewersInChats);
  const conversationInfo = useAppSelector(selectConversationInfo);
  const currentBot = useAppSelector(selectCurrentBot);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });

  useEffect(() => {
    if (currentBot && props.id) {
      if (user) {
        const operator: OperatorJoinModel = {
          operatorId: user.id,
          operatorName: user.username,
          conversationId: props.id,
        };
        dispatch(operatorViewConversation({ ...operator }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, dispatch, props.getMessages, props.id]);

  function showViewers() {
    if (operatorViewers && conversationInfo) {
      return operatorViewers.filter(x => x.conversationId === conversationInfo.id).length > 0;
    }
    return false;
  }

  return (
    <>
      {showViewers() && conversationInfo && operatorViewers && (
        <OperatorViewBox text={t("Viewers")} operators={operatorViewers.filter(x => x.conversationId === conversationInfo.id)} />
      )}
    </>
  );
};
