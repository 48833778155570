import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { contactSlice, selectContact, selectContactVariables } from "./ContactSlice";

import { begin, beginScope, complete, completeScope } from "../../common/loading/LoadingStateActions";
import * as contactApi from "./ContactApi";
import { ContactModel, ContactVariableModel, UsageContactVariableData } from "./ContactModel";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { ValidationError } from "../../common/ErrorModel";
import { CustomVariableModel } from "../../common/AppEnums";
import { formatVariableToData, formatDateTimeVariableToView } from "../../common/utils/variablesMapper";
import { CustomVariableType } from "../modals/addVariable/AddVariableModel";
import { selectCurrentBot, sidebarSlice } from "../sidebar/SidebarSlice";
import { BotModel } from "../sidebar/SidebarModel";
import { routerSlice } from "../../common/router/RouterSlice";
import { chatListSlice } from "../chatList/ChatListSlice";
import { ContactVariableListData } from "./ContactData";

export function* contactSaga() {
  yield takeLatest(contactSlice.actions.getContact, getContact);
  yield takeLatest(contactSlice.actions.getContactVariables, getContactVariables);
  yield takeLatest(contactSlice.actions.changeContactVariableValue, changeContactVariableValue);
  yield takeLatest(contactSlice.actions.createCustomVariable, createCustomVariable);
  yield takeLatest(contactSlice.actions.deleteVariable, deleteVariable);
  yield takeLatest(contactSlice.actions.checkVariableUsage, checkVariableUsage);
  yield takeLatest(contactSlice.actions.selectChatBot, selectChatBot);
  yield takeLatest(contactSlice.actions.changeContactVariableDescription, changeContactVariableDescription);
}

export function* getContact(action: PayloadAction<{ contactId: string }>) {
  try {
    yield put(beginScope("getContact"));
    const data: ContactModel = yield call(contactApi.getContactById, action.payload.contactId);
    yield put(contactSlice.actions.getContactSucceed({ contact: data }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getContact"));
  }
}

export function* selectChatBot(action: PayloadAction<{ bot: BotModel; contactId: string }>) {
  try {
    const bot: BotModel = yield select(selectCurrentBot);
    const currentContact: ContactModel = yield select(selectContact);
    if (bot.id !== action.payload.bot.id) {
      const contact: ContactModel = yield call(
        contactApi.getContactBotConversationId,
        action.payload.contactId,
        action.payload.bot.id,
      );

      yield put(sidebarSlice.actions.selectBot(action.payload.bot));
      yield put(routerSlice.actions.redirect(`chats/${contact.conversationId}`));
    } else if (!currentContact.conversationId) {
      const contact: ContactModel = yield call(
        contactApi.getContactBotConversationId,
        action.payload.contactId,
        action.payload.bot.id,
      );
      yield put(routerSlice.actions.redirect(`chats/${contact.conversationId}`));
    } else {
      yield put(routerSlice.actions.redirect(`chats/${currentContact.conversationId}`));
    }
    yield put(chatListSlice.actions.setFilter({ type: "all" }));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getContactVariables(action: PayloadAction<{ contactId: string; page: string; size: string }>) {
  try {
    yield put(beginScope("getContactVariables"));
    const data: ContactVariableListData = yield call(
      contactApi.getContactVariables,
      action.payload.contactId,
      action.payload.size,
      action.payload.page,
    );
    const contactVariablesModel = { ...data, items: data.items.map(el => formatDateTimeVariableToView(el)) };
    yield put(contactSlice.actions.getContactVariablesCompleted(contactVariablesModel));
    yield put(completeScope("getContactVariables"));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* changeContactVariableValue(
  action: PayloadAction<{
    contactId: string;
    contactVariable: ContactVariableModel;
    page?: string;
    size?: string;
  }>,
) {
  try {
    validateVariable(action.payload.contactVariable);
    const currentContactVariables: ContactVariableModel[] | undefined = yield select(selectContactVariables);
    const currentVar = currentContactVariables?.find(el => el.id === action.payload.contactVariable.id);

    const isValueChanged = action.payload.contactVariable.value !== currentVar?.value;
    if (isValueChanged) {
      yield call(
        contactApi.changeContactVariable,
        action.payload.contactId,
        formatVariableToData(action.payload.contactVariable),
      );
    }

    yield put(
      notificationSlice.actions.notify({
        message: "Value saved successfully!",
        type: "success",
      }),
    );
    const contact: ReturnType<typeof selectContact> = yield select(selectContact);
    if (contact && action.payload.page && action.payload.size) {
      yield put(
        contactSlice.actions.getContactVariables({
          contactId: contact.id,
          page: action.payload.page,
          size: action.payload.size,
        }),
      );
    }
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "error",
        }),
      );
      return;
    }
    yield handleException(e);
  }
}

export function* changeContactVariableDescription(
  action: PayloadAction<{
    contactId: string;
    contactVariable: ContactVariableModel;
    page?: string;
    size?: string;
  }>,
) {
  try {
    validateVariable(action.payload.contactVariable);

    const currentContactVariables: ContactVariableModel[] | undefined = yield select(selectContactVariables);
    const currentVar = currentContactVariables?.find(el => el.id === action.payload.contactVariable.id);
    const isDescriptionChanged = action.payload.contactVariable.description !== currentVar?.description;
    if (isDescriptionChanged) {
      yield call(
        contactApi.editVariableDefenition,
        action.payload.contactId,
        formatVariableToData(action.payload.contactVariable),
      );
      yield put(
        notificationSlice.actions.notify({
          message: "Value saved successfully!",
          type: "success",
        }),
      );
    }
    const contact: ReturnType<typeof selectContact> = yield select(selectContact);
    if (contact && action.payload.page && action.payload.size) {
      yield put(
        contactSlice.actions.getContactVariables({
          contactId: contact.id,
          page: action.payload.page,
          size: action.payload.size,
        }),
      );
    }
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "error",
        }),
      );
      return;
    }
    yield handleException(e);
  }
}

function* createCustomVariable(action: PayloadAction<{ variable: CustomVariableModel; page?: string; size?: string }>) {
  try {
    yield put(begin());
    yield call(contactApi.createCustomVariable, action.payload.variable);
    const contact: ReturnType<typeof selectContact> = yield select(selectContact);
    if (contact && action.payload.page && action.payload.size) {
      yield put(
        contactSlice.actions.getContactVariables({
          contactId: contact.id,
          page: action.payload.page,
          size: action.payload.size,
        }),
      );
    }
  } catch (e: unknown) {
    yield handleException(e);
    const contact: ReturnType<typeof selectContact> = yield select(selectContact);
    if (contact && action.payload.page && action.payload.size) {
      yield put(
        contactSlice.actions.getContactVariables({
          contactId: contact.id,
          page: action.payload.page,
          size: action.payload.size,
        }),
      );
    }
  } finally {
    yield put(complete());
  }
}

function* deleteVariable(action: PayloadAction<{ variableId: string; page?: string; size?: string }>) {
  try {
    yield put(begin());
    yield call(contactApi.deleteVariable, action.payload.variableId);
    const contact: ReturnType<typeof selectContact> = yield select(selectContact);
    if (contact && action.payload.page && action.payload.size) {
      yield put(
        contactSlice.actions.getContactVariables({
          contactId: contact.id,
          page: action.payload.page,
          size: action.payload.size,
        }),
      );
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* checkVariableUsage(action: PayloadAction<string>) {
  try {
    yield put(beginScope("checkVariableUsage"));
    const useInfo: UsageContactVariableData = yield call(contactApi.getIsVariableInUse, action.payload);
    const usableData = mapToFlowListData(useInfo);
    yield put(contactSlice.actions.checkVariableUsageComplete(usableData));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("checkVariableUsage"));
  }
}

function mapToFlowListData(data: UsageContactVariableData) {
  return {
    usageInFlow: Object.keys(data.usageInFlow).map(k => {
      return {
        id: k,
        name: data.usageInFlow[k],
      };
    }),
    usageInBroadcasts: Object.keys(data.usageInBroadcasts).map(k => {
      return {
        id: k,
        name: data.usageInBroadcasts[k],
      };
    }),
  };
}

export const validateVariable = (variable: ContactVariableModel) => {
  if (!variable.value) {
    return;
  }
  switch (variable.type) {
    case CustomVariableType.Number: {
      if (variable.value.length >= 20) {
        throw new ValidationError({
          errors: [{ field: "error", message: "Max value exceeded" }],
        });
      }
      return;
    }
    case CustomVariableType.Text: {
      if (!variable.value.trim().length) {
        throw new ValidationError({
          errors: [{ field: "error", message: "Value can not contain only spaces" }],
        });
      }
      return;
    }
  }
};
