import { Dispatch, SetStateAction, useState, useEffect, useRef, useCallback } from "react";
import { Flex, Text, Image, Skeleton, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AppSettings } from "../../../../../common/AppSettings";
import { ReactComponent as LeftArrow } from "../../../../../assets/icons/arrowLeft.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { getMediaFiles, selectMediaFiles } from "../../../ContactInfoSlice";
import s from "./ContactMediaFiles.module.scss";

declare const appSettings: AppSettings;
interface Props {
  conversationId: string;
  mediaFilesCount: number | undefined;
  setOpenMedia: Dispatch<SetStateAction<boolean>>;
}

export default function ContactMediaFiles({ conversationId, mediaFilesCount, setOpenMedia }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const mediaFiles = useAppSelector(selectMediaFiles);
  const isLoading = useGetLoadingState("getMediaFiles");
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(mediaFiles?.currentPage || 1);
  const [mediaFilesLoaded, setmediaFilesLoaded] = useState(false);
  const mediaFilesListRef = useRef<null | HTMLDivElement>(null);
  const size = 35;
  const array = Array.from({ length: mediaFilesCount ?? 30 }, (_, index) => index + 1);
  useEffect(() => {
    dispatch(getMediaFiles({ conversationId, page, size }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, page]);

  const handleMediaFilesClose = () => {
    setOpenMedia(false);
  };

  const handleScroll = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (isLoading) {
        return;
      }
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (mediaFiles && mediaFiles.totalPages > page) {
        if (scrollHeight - scrollTop - 20 <= clientHeight) {
          setPage(prevPage => prevPage + 1);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, mediaFiles],
  );

  useEffect(() => {
    const mediaFilesList = mediaFilesListRef.current;

    if (mediaFilesList) {
      mediaFilesList.addEventListener("scroll", handleScroll);
      return () => {
        mediaFilesList.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <Flex className={s.mediaContainer}>
      <Flex className={s.mediaGroup}>
        <Flex className={s.titleContainer}>
          <LeftArrow className={s.leftArrow} onClick={handleMediaFilesClose} />
          <Text variant="largeBold">{t("Media files")}</Text>
        </Flex>
        <CrossIcon className={s.crossIcon} onClick={handleMediaFilesClose} />
      </Flex>
      <Flex className={s.filesContainer} ref={mediaFilesListRef} mb={"100px"}>
        {isLoading
          ? array.map(el => <Skeleton className={s.skeleton} key={el} />)
          : mediaFiles?.items.map((item, index) => (
              <Skeleton className={s.skeleton} key={index} isLoaded={mediaFilesLoaded}>
                <Link href={`${appSettings.apiBaseUrl}/file/${item.fileId}`} isExternal>
                  <Image
                    key={index}
                    alt={`${item.fileName}`}
                    loading="lazy"
                    src={`${appSettings.apiBaseUrl}/file/${item.fileId}`}
                    className={s.mediaBox}
                    onLoad={() => setmediaFilesLoaded(true)}
                    objectFit="cover"
                    layerStyle={"fill"}
                  />
                </Link>
              </Skeleton>
            ))}
      </Flex>
    </Flex>
  );
}
