import { Text } from "@chakra-ui/react";
import s from "../fileNameSplitter/FileNameSplitter.module.scss";

interface Props {
  fileName: string;
  style?: string;
  variant?: string;
}

export const FileNameSplitter = (props: Props) => {
  if (props?.fileName?.length > 20) {
    const firstPart = props?.fileName?.substring(0, 12);
    const secondPart = props?.fileName?.substring(props?.fileName?.length - 7, props?.fileName?.length);
    return (
      <Text className={props.style ?? s.fileNameSplitterStyle} variant={props.variant ?? "largeBold"} data-pw="filename">
        {firstPart + "..." + secondPart}
      </Text>
    );
  } else {
    return (
      <Text className={props.style ?? s.fileNameSplitterStyle} variant={props.variant ?? "largeBold"} data-pw="filename">
        {props?.fileName}
      </Text>
    );
  }
};
