import s from "../KeywordTriggerPopup.module.scss";
import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { KeywordMatchType } from "../../../../FlowBuilderModel";

interface Props {
  addKeyword: () => void;
  keywordMatchType?: KeywordMatchType | undefined;
}

export const AddKeywordButton = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  if (props.keywordMatchType && props.keywordMatchType === KeywordMatchType.Any) {
    return <div />;
  }

  return (
    <Button className={s.addKeywordButtonWrp} onClick={() => props.addKeyword()} data-pw="add-keyword-button">
      <Box className={s.addKeywordButton}>+ {t("editTriggerPopup.Keyword")}</Box>
    </Button>
  );
};
