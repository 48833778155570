import { Box, List } from "@chakra-ui/react";
import { OperatorViewItem } from "../OperatorViewItem";
import s from "./OperatorViewItemsList.module.scss";
import { OperatorJoinModel } from "../../../ConversationViewerModel";

interface Props {
  operators: OperatorJoinModel[];
}

export const OperatorViewItemsList = (props: Props) => {
  return (
    <Box className={s.operatorsBox}>
      <List spacing={3} className={s.operatorsList}>
        {props.operators.map(operators => {
          return <OperatorViewItem key={operators.operatorName} operatorName={operators.operatorName} />;
        })}
      </List>
    </Box>
  );
};
