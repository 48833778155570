import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Flex,
  Text,
  Box,
  Icon,
  Link,
} from "@chakra-ui/react";
import { ChangeEvent, useState, DragEvent } from "react";
import { useTranslation } from "react-i18next";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { Loading } from "../../common/loading/LoadingStateContainer";
import { notify } from "../../common/notifications/NotificationSlice";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { importContacts, selectImportResult, setImportResult } from "../contactList/ContactListSlice";
import { ReactComponent as FileIcon } from "../../assets/icons/fileIcon.svg";
import { ReactComponent as CircleCheckIcon } from "../../assets/icons/circleCheckIcon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";

import s from "./ImportContacts.module.scss";
import { MultiSelectMenu } from "../multiSelectMenu/MultiSelectMenu";
import { getBots, selectBots } from "../sidebar/SidebarSlice";
import { FileNameSplitter } from "../../UI/atoms/fileNameSplitter/FileNameSplitter";
import { useGAWithCustomParameters } from "../../common/ga/GAEventTracker";

const MAX_SIZE_10MB = 10485760;

interface Props {
  isOpen: boolean;
  usage?: string;
  onClose: () => void;
}

export const ImportContactsModal = ({ isOpen, usage, onClose }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "importContacts" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const dispatch = useAppDispatch();
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [botIds, setBotIds] = useState<string[]>([]);
  const importResult = useAppSelector(selectImportResult);
  const loadingState = useGetLoadingState("importContacts");
  const bots = useAppSelector(selectBots);
  const [isBotsMenuValid, setIsBotsMenuValid] = useState(false);

  const trackEvent = useGAWithCustomParameters("Broadcast");

  const getAdditionalBots = () => {
    if (bots) {
      dispatch(getBots({ page: bots.currentPage + 1 }));
    }
  };
  const [isDragging, setDragging] = useState(false);

  const chooseFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file && file.size && file.size <= MAX_SIZE_10MB) {
      setCurrentFile(e.target?.files?.[0] ?? null);
      dispatch(setImportResult({ importResult: null }));
    } else if (file && file.size && file.size > MAX_SIZE_10MB) {
      dispatch(notify({ message: t("File is too big"), type: "error" }));
    }
  };

  const uploadFile = () => {
    if (currentFile) {
      if (window.location.pathname.includes("broadcast")) {
        trackEvent("BroadcastContactsImport");
      }
      dispatch(importContacts({ file: currentFile, botIds: botIds }));
    }
  };

  const handleClose = () => {
    if (loadingState) return;
    onClose();
    setCurrentFile(null);
    dispatch(setImportResult({ importResult: null }));
  };

  const onBotListChange = (botIds: string[]) => {
    setBotIds(botIds);
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [, ext] = file.type.split("/");
      if (ext === "csv") {
        if (file && file.size && file.size <= MAX_SIZE_10MB) {
          setCurrentFile(file ?? null);
          dispatch(setImportResult({ importResult: null }));
        } else if (file && file.size && file.size > MAX_SIZE_10MB) {
          dispatch(notify({ message: t("File is too big"), type: "error" }));
        }
      } else {
        dispatch(notify({ message: t("Select a CSV file"), type: "error" }));
      }
    }
  };

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!currentFile) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragging(true);
      } else if (e.type === "dragleave" || e.type === "dragend") {
        setDragging(false);
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "300px", sm: "460px" }}>
          <ModalHeader p={{ base: "24px", sm: "32px" }}>
            {!importResult ? (
              <Text variant="h1">{t("Import Contacts")}</Text>
            ) : (
              <Flex className={s.importResult}>
                <Icon as={CircleCheckIcon} boxSize="40px" />
                <Text color="#0BCB6B" variant="h1">
                  {t("Import has been completed")}
                </Text>
              </Flex>
            )}
          </ModalHeader>
          <ModalCloseButton className={s.closeButton}>
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody px={{ base: "24px", sm: "32px" }} py="0" className={s.modalBody}>
            {!importResult && (
              <Box>
                <Text variant="medium" className={s.modalText}>
                  {t("Select a CSV file containing your contacts")}
                </Text>
                <Link
                  className={s.downloadLink}
                  href={"https://cdn.domino-crm.com/import-contacts-template.csv"}
                  data-pw="download-CSV"
                >
                  {t("Download Sample CSV")}
                </Link>
                <Text variant="medium" className={s.modalText}>
                  {t("Important: use as a delimiter")}
                </Text>
              </Box>
            )}
            <Box
              className={s.fileUploadContainer}
              borderStyle={importResult ? "solid" : "dashed"}
              borderColor={isDragging ? "mainPurple" : "line"}
              onDragEnter={handleDrag}
            >
              <Flex className={s.fileContainer}>
                {!importResult ? (
                  <>
                    <Icon color="black" as={FileIcon} boxSize="24px" />
                    {currentFile ? (
                      <FileNameSplitter fileName={currentFile.name} />
                    ) : (
                      <>
                        <Text variant="largeBold">{t("Drag and drop the file here")}</Text>
                        <Text variant="medium" color="darkGrey">
                          {t("or press the button")}
                        </Text>
                        <Button htmlFor="file-upload" as="label" variant="dominoOutlineViolet" data-pw="file-upload">
                          {t("Select file")}
                        </Button>
                        <Input id="file-upload" onChange={chooseFile} variant="unstyled" type="file" accept=".csv" />
                      </>
                    )}
                    {isDragging && (
                      <div
                        className={s.dragFileElement}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={onDropFile}
                      ></div>
                    )}
                  </>
                ) : (
                  <Flex className={s.uploadResults}>
                    <Flex className={s.resultsTitle}>
                      <Text variant="large" color="darkGrey" mb="4px">
                        {t("Import")}:
                      </Text>
                      <FileNameSplitter fileName={currentFile?.name ? currentFile.name : ""} />
                    </Flex>
                    <Flex className={s.resultList}>
                      <Text color="defaultGreen" variant="medium">
                        {t("Created contacts")} - {importResult.successfullyCreatedCount}
                      </Text>
                      <Text variant="medium">
                        {t("Duplicates contacts")} - {importResult.duplicateCount}
                      </Text>
                      <Text color="darkGrey" variant="medium">
                        {t("With error")} - {importResult.errorCount}
                      </Text>
                    </Flex>
                  </Flex>
                )}
                <Loading scope="importContacts" />
              </Flex>
            </Box>
            {usage !== "broadcast" && (
              <Box>
                <Flex className={s.selectBots}>
                  <Text variant="medium" mb={"8px"} title="text tooltip">
                    {t("Select bot(s) from the list")}
                  </Text>
                </Flex>
                <MultiSelectMenu
                  isLastPage={bots?.currentPage === bots?.totalPages}
                  options={bots?.items ?? []}
                  getAdditionalItems={getAdditionalBots}
                  onChange={onBotListChange}
                  required={true}
                  validationMessage={t("Choose at least one bot")}
                  onValidation={setIsBotsMenuValid}
                  isDisabled={Boolean(importResult) || loadingState}
                />
              </Box>
            )}
          </ModalBody>

          <ModalFooter p="32px">
            {importResult ? (
              <Button variant="dominoViolet" onClick={handleClose} data-pw="done-button">
                {ct("Done")}
              </Button>
            ) : (
              !((currentFile && importResult) || loadingState) && (
                <Flex width="100%" justifyContent="space-between">
                  <Button variant="dominoOutlineViolet" onClick={handleClose} data-pw="cancel-button">
                    {ct("Cancel")}
                  </Button>
                  <Button
                    isDisabled={!currentFile || loadingState || !isBotsMenuValid}
                    variant="dominoViolet"
                    onClick={uploadFile}
                    data-pw="import-button"
                  >
                    {t("Import")}
                  </Button>
                </Flex>
              )
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
