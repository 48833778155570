import { Box, Flex, Input, InputGroup, InputProps, InputRightElement, Text } from "@chakra-ui/react";
import { ReactElement, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import s from "./InputPlacholderLabel.module.scss";

interface Props extends InputProps {
  rightIcon?: ReactElement;
  isError?: boolean;
  errorMessage?: string;
  formHelperText?: string;
}

export const InputPlacholderLabel = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  const uniqueId = uuidv4();

  const handleInputClick = () => {
    inputRef.current && inputRef.current.focus();
  };

  return (
    <Flex className={`${s.wrapper} ${props.errorMessage && s.wrapperError}`}>
      <Box className={s.wrapperInput} onClick={handleInputClick}>
        <InputGroup position="static" className={s.inputGroup}>
          <Input
            id={uniqueId}
            maxLength={64}
            ref={inputRef}
            className={`${s.input} ${props.isError && s.error}`}
            {...props}
            placeholder=""
          />
          <Text className={s.inputLabel}>{props.placeholder}</Text>
          {props.rightIcon && (
            <InputRightElement top="none" h="max-content" bottom="8px">
              {props.rightIcon}
            </InputRightElement>
          )}
        </InputGroup>
      </Box>

      {props.formHelperText ? (
        <Text variant="small" color={props.isError ? "mainRed" : "darkGrey"}>
          {props.formHelperText}
        </Text>
      ) : (
        props.errorMessage && props.isError && <Text className={s.errorMessage}>{props.errorMessage}</Text>
      )}
    </Flex>
  );
};
