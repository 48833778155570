import { Text, Card, CardBody, Box, Icon, Flex, Switch, Link, Show } from "@chakra-ui/react";
import { TriggerItemModel, TriggerListModel } from "../AutomationModel";
import s from "../Automation.module.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/state/store";
import { switchTriggerStatus } from "../AutomationSlice";
import { ReactComponent as keyIcon } from "../../../assets/icons/keyIcon.svg";
import { ReactComponent as messageIcon } from "../../../assets/icons/messageIcon.svg";
import { ReactComponent as flashIcon } from "../../../assets/icons/flashIcon.svg";
import { ReactComponent as refUrlIcon } from "../../../assets/icons/refUrlIcon.svg";
import { ReactComponent as buttonClickIcon } from "../../../assets/icons/buttonClickIcon.svg";
import { ReactComponent as commandIcon } from "../../../assets/icons/commandTriggerIcon.svg";
import { ReactComponent as scheduleTriggerIcon } from "../../../assets/icons/scheduleTriggerIcon.svg";
import { ReactComponent as FollowLinkIcon } from "../../../assets/images/followLinkIcon.svg";

import {
  ButtonTriggerMatchTypeName,
  KeywordMatchType,
  keywordMatchTypeName,
  ScheduleTriggerTypeDiscriminator,
  TriggerTypeDiscriminator,
  TriggerTypeName,
} from "../../flowBuilder/FlowBuilderModel";
//import { selectCurrentBot } from "../../sidebar/SidebarSlice";
import { ChangeEvent, Key } from "react";
import { formatDateTimeToView } from "../../../common/utils/formatDate";
import { convertPascalCaseToText } from "../../../common/utils/convertPascalCaseToText";
import * as vars from "../../layout/LayoutHelper/ResolutionConst";
import { BotModel } from "../../sidebar/SidebarModel";

interface Props {
  currentPage: number;
  triggerList: TriggerListModel;
  currentBot: BotModel;
}

export const TriggerList = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "triggerList" });
  const ft = useTranslation("translation", { keyPrefix: "flow" }).t;
  const cft = useTranslation("translation", { keyPrefix: "complexFilter.conditionsByType" }).t;

  const dispatch = useAppDispatch();
  const { currentBot } = props;

  const TriggerTypeIcons = {
    ConversationStartFlowTrigger: flashIcon,
    IncomingMessageFlowTrigger: messageIcon,
    KeywordMatchFlowTrigger: keyIcon,
    TelegramRefUrlFlowTrigger: refUrlIcon,
    ButtonClickTrigger: buttonClickIcon,
    TelegramCommandFlowTrigger: commandIcon,
    SchedulerFlowTrigger: scheduleTriggerIcon,
  };

  const handleSwitchTriggerStatus = (triggerItem: TriggerItemModel) => (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      switchTriggerStatus({
        flowId: triggerItem.flow?.id,
        triggerId: triggerItem.trigger.id,
        isEnabled: !triggerItem.trigger.isEnabled,
      }),
    );
  };

  const getTriggerRule = (triggerItem: TriggerItemModel, isMobile: boolean) => {
    const trigger = triggerItem.trigger;
    switch (trigger.typeDiscriminator) {
      case TriggerTypeDiscriminator.CommandMatch: {
        return (
          <Flex gap="12px" align="center">
            <Text>{t("If command is")}</Text>
            <Box display="flex" border="1px solid" borderColor="line" borderRadius="8px">
              <Box borderLeftRadius="8px" bg="defaultGrey" p="4px 10px">
                <Text variant="medium">/</Text>
              </Box>
              <Box p="4px 8px">
                <Text variant="medium">{trigger?.command}</Text>
              </Box>
            </Box>
          </Flex>
        );
      }
      case TriggerTypeDiscriminator.RefUrl: {
        if (!isMobile) {
          return (
            <Flex gap="12px" align="center">
              <Link
                display="flex"
                alignItems="center"
                gap="4px"
                color="blueLink"
                href={`https://t.me/${currentBot?.username}?start=${trigger?.triggerRefNumber}`}
                isExternal
              >
                {`https://t.me/${currentBot?.username}?start=${trigger?.triggerRefNumber}`}
                <Icon as={FollowLinkIcon} boxSize="16px" />
              </Link>
            </Flex>
          );
        } else {
          return (
            <Flex align="center">
              <Link color="blueLink" href={`https://t.me/${currentBot?.username}?start=${trigger?.triggerRefNumber}`} isExternal>
                {`https://t.me/${currentBot?.username}?start=${trigger?.triggerRefNumber}`}
                <Icon as={FollowLinkIcon} boxSize="16px" marginLeft={"10px"} />
              </Link>
            </Flex>
          );
        }
      }
      case TriggerTypeDiscriminator.ButtonClick: {
        const type = ButtonTriggerMatchTypeName[trigger.buttonTriggerType as keyof typeof ButtonTriggerMatchTypeName];
        return (
          <Flex gap="12px" align="center" wrap="wrap">
            {!isMobile ? (
              <Text>
                {t("If")}&nbsp;
                {t(`button payload ${type}`)}
              </Text>
            ) : (
              <Text>{t(`button payload ${type} (mobile)`)}</Text>
            )}
            {trigger?.buttonIds?.map((buttonId, index) => (
              <Box
                key={index}
                display="flex"
                border="1px solid"
                borderColor="line"
                borderRadius="8px"
                bg="defaultGrey"
                p="4px 10px"
              >
                <Text variant="medium">{buttonId}</Text>
              </Box>
            ))}
          </Flex>
        );
      }
      case TriggerTypeDiscriminator.KeywordMatch: {
        const matchTypeName = trigger?.keywordMatchType as keyof typeof keywordMatchTypeName;
        const type = keywordMatchTypeName[matchTypeName ?? KeywordMatchType["Is"]];
        return (
          <Flex gap="12px" align="center" wrap="wrap">
            {!isMobile ? (
              <Text>
                {t("If")}&nbsp;
                {t("message")}&nbsp;
                {t(type)}
              </Text>
            ) : (
              <Text>{t("If message " + type + " (mobile)")}</Text>
            )}
            {trigger?.keywords?.map((message, index) => (
              <Box
                key={index}
                display="flex"
                border="1px solid"
                borderColor="line"
                borderRadius="8px"
                bg="defaultGrey"
                p="4px 10px"
              >
                <Text variant="medium">{message}sada</Text>
              </Box>
            ))}
          </Flex>
        );
      }
      case TriggerTypeDiscriminator.Schedule: {
        return (
          <Flex direction="column">
            <Box mb="8px">
              {trigger?.conditions?.map((elem, index) => {
                return (
                  <Flex key={index} flexWrap="wrap">
                    <Text color="midDeepBlue" variant="medium">
                      {elem?.conditionCustomVariableName ?? elem.conditionCustomVariableId}
                    </Text>
                    <Text variant="medium">
                      &nbsp;&nbsp;{!!elem.condition && cft(convertPascalCaseToText(elem.condition))}&nbsp;&nbsp;
                    </Text>
                    {!isMobile ? (
                      <Text variant="medium" noOfLines={1} maxWidth={"300px"}>
                        {formatDateTimeToView(elem.conditionCustomVariableName, elem.value)}
                      </Text>
                    ) : (
                      <Text variant="medium" noOfLines={1}>
                        {formatDateTimeToView(elem.conditionCustomVariableName, elem.value)}
                      </Text>
                    )}
                  </Flex>
                );
              })}
            </Box>
            {trigger.scheduler?.value && trigger.scheduler?.unit && (
              <Flex gap="4px">
                <Text variant="medium" color="darkGrey">
                  {ft("editTriggerPopup.Repeat Interval")}:
                </Text>
                <Text variant="medium">
                  {trigger.scheduler?.value}{" "}
                  {ft(`delayNodeTimeUnits.${trigger.scheduler.unit}`, { count: parseInt(trigger.scheduler.value) })}
                </Text>
              </Flex>
            )}
            <Flex gap="4px">
              {trigger.scheduler?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.once ? (
                <>
                  <Text variant="medium" color="darkGrey">
                    {ft("editTriggerPopup.Scheduled time")}:
                  </Text>
                  <Text variant="medium">{formatDateTimeToView("DateTime", trigger.scheduler?.scheduledTime ?? "")}</Text>
                </>
              ) : (
                <>
                  <Text variant="medium" color="darkGrey">
                    {ft("editTriggerPopup.Start time")}:
                  </Text>
                  <Text variant="medium">{formatDateTimeToView("DateTime", trigger.scheduler?.startTime ?? "")}</Text>
                </>
              )}
            </Flex>
            {trigger.scheduler?.endTime && (
              <Flex gap="4px">
                <Text variant="medium" color="darkGrey">
                  {ft("editTriggerPopup.End time")}:
                </Text>
                <Text variant="medium">{formatDateTimeToView("DateTime", trigger.scheduler?.endTime ?? "")}</Text>
              </Flex>
            )}
          </Flex>
        );
      }
      case TriggerTypeDiscriminator.ConversationStart: {
        return <div />;
      }
    }
  };

  return (
    <Box position={"relative"}>
      <Show breakpoint="(min-width: 769px)">
        <Box className={`${s.triggerListItemGrid} ${s.flowListFilter} ${s.flowListFilterTrigger}`} position="absolute">
          <Text noOfLines={1}>{t("Trigger type")}</Text>
          <Text mr="36px" noOfLines={1}>
            {t("Rule")}
          </Text>
          <Text noOfLines={1}>{t("Flow")}</Text>
          <Text justifySelf="center" noOfLines={1}>
            {t("Active")}
          </Text>
        </Box>
      </Show>
      <Box
        overflowY="auto"
        height={
          window.innerWidth > vars.TABLET_VIEW
            ? `calc(100dvh - ${vars.HEADER}px - 30px )`
            : `calc(100dvh - ${vars.HEADER}px - ${vars.SUB_HEADER}px - 68px )`
        }
        paddingBottom={"48px"}
        paddingTop={window.innerWidth > vars.TABLET_VIEW ? "60px" : "0"}
      >
        {props.triggerList?.items?.map((item: TriggerItemModel, index: Key | null | undefined) => {
          return (
            <>
              <Show breakpoint="(min-width: 769px)">
                <Card variant="dominoListTileTriggers" key={item.trigger.id}>
                  <CardBody>
                    <div className={`${s.triggerListItemGrid}`}>
                      <Flex align="center" gap="8px" alignSelf="flex-start">
                        <Icon
                          color="defaultGreen"
                          as={TriggerTypeIcons[item.trigger.typeDiscriminator as keyof typeof TriggerTypeIcons]}
                          boxSize="20px"
                        />
                        <Text noOfLines={1}>
                          {t(TriggerTypeName[item.trigger.typeDiscriminator as keyof typeof TriggerTypeName])}
                        </Text>
                      </Flex>

                      {getTriggerRule(item, false)}

                      <Text color="blueLink" noOfLines={1}>
                        <Link href={`/automation/flows/${item.flow?.id}`} isExternal fontSize={"15px"} color={"blueLink"}>
                          {item?.flow?.title}
                        </Link>
                      </Text>
                      <Switch
                        justifySelf="center"
                        variant="dominoDefaultGreen"
                        onChange={handleSwitchTriggerStatus(item)}
                        isChecked={item.trigger.isEnabled}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Show>
              <Show breakpoint="(max-width: 768px)">
                <Card variant="dominoListTileTriggers" key={item.trigger.id} mb={"10px"}>
                  <CardBody>
                    <div className={`${s.triggerListItemGridMobile}`}>
                      <Box>
                        <Flex align="center" gap={"2"} marginBottom={"10px"}>
                          <Icon
                            color="defaultGreen"
                            as={TriggerTypeIcons[item.trigger.typeDiscriminator as keyof typeof TriggerTypeIcons]}
                            boxSize="20px"
                          />
                          <Text noOfLines={1}>
                            {t(TriggerTypeName[item.trigger.typeDiscriminator as keyof typeof TriggerTypeName])}
                          </Text>
                        </Flex>

                        {getTriggerRule(item, true)}

                        <Text color="blueLink" noOfLines={1} mt={"8px"}>
                          <Link href={`/automation/flows/${item.flow?.id}`} isExternal fontSize={"15px"} color={"blueLink"}>
                            {item?.flow?.title}
                          </Link>
                        </Text>
                      </Box>
                      <Box>
                        <Switch
                          variant="dominoDefaultGreen"
                          onChange={handleSwitchTriggerStatus(item)}
                          isChecked={item.trigger.isEnabled}
                        />
                        <div />
                        <div />
                      </Box>
                    </div>
                  </CardBody>
                </Card>
              </Show>
            </>
          );
        })}
      </Box>
    </Box>
  );
};
