import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useBreakpointValue,
} from "@chakra-ui/react";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateBotTab } from "./CreateBotTab";
import { ConnectBotTab } from "./ConnectBotTab";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { connectBot } from "../../AddBotSlice";
import { selectNotification } from "../../../../common/notifications/NotificationSlice";
import useGA from "../../../../common/ga/GAEventTracker";
import { BotIndexTabNames } from "../../AddBotModel";
import s from "../../components/CreateConnectBot/CreateConnectBotContainer.module.scss";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";

interface Props {
  onConnect?: (token: string) => void;
}

export const CreateConnectBotContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });
  const creactTabTitle = useBreakpointValue({
    base: t("Create Bot"),
    md: t("Create New Bot"),
  });
  const connectTabTitle = useBreakpointValue({
    base: t("Connect Bot"),
    md: t("Connect Existing Bot"),
  });

  const trackEvent = useGA("Bot");
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectNotification);
  const notificationType = notification?.type;
  const tokenExample = "7013558025:AAG5jNdQSyEH9I4SCT1FrDQ-0Zk35iaS0YQ";
  const [token, setToken] = useState<string>("");
  const [isTokenInvalid, setIsTokenInvalid] = useState<boolean>(false);
  const isLoading = useGetLoadingState("connectBot");

  const handleTabChange = (index: number) => {
    setToken("");
    setIsTokenInvalid(false);
    if (index === BotIndexTabNames.CREATE) {
      trackEvent("BotCreateTab");
    } else if (index === BotIndexTabNames.CONNECT) {
      trackEvent("BotConneсtTab");
    }
  };

  const onChangeToken = (e: { target: { value: SetStateAction<string> } }) => {
    setIsTokenInvalid(false);
    setToken(e.target.value);
  };

  useEffect(() => {
    if (notificationType === "error") {
      setIsTokenInvalid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const onConnect = (token: string) => {
    dispatch(connectBot(token));
  };

  return (
    <Box className={s.tabsContainer}>
      <Tabs isLazy={true} isFitted variant="enclosed" onChange={handleTabChange}>
        <TabList>
          <Tab
            _hover={{ color: "black" }}
            _selected={{
              bg: "white",
              borderBottom: "none",
              color: "black",
            }}
          >
            {creactTabTitle}
          </Tab>
          <Tab _hover={{ color: "black" }} _selected={{ bg: "white", borderBottom: "none", color: "black" }}>
            {connectTabTitle}
          </Tab>
        </TabList>
        <TabPanels className={s.tabsPanels}>
          <TabPanel className={s.tabPanel}>
            <CreateBotTab />
          </TabPanel>
          <TabPanel className={s.tabPanel}>
            <ConnectBotTab />
          </TabPanel>
          <FormControl mt="24px">
            <FormLabel className={s.tgFormLabel}>{t("Telegram bot token")}</FormLabel>
            <Input
              className={`${isTokenInvalid ? s.invalidTokenValidation : ""} ${s.tokenInput} `}
              value={token}
              onChange={onChangeToken}
              placeholder={tokenExample}
            />
          </FormControl>
          <Button isLoading={isLoading} className={s.connectButton} onClick={() => onConnect(token)} variant="dominoViolet">
            {t("Connect")}
          </Button>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
