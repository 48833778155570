import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const dominoBase = defineStyle({
  color: "darkGrey",
  backgroundColor: "#FFFFFF",
  fontWeight: "400",
  textTransform: "none",
  fontSize: "13px",
});

const dominoNumberList = defineStyle({
  backgroundColor: "midDeepBlue",
  borderRadius: "50px",
  color: "white",
  height: "24px",
  width: "24px",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  display: "flex",
  flexShrink: 0,
  fontSize: "14px",
  fontWeight: 400,
});

const dominoInstructionsNumberList = defineStyle({
  backgroundColor: "midDeepBlue",
  borderRadius: "50px",
  color: "white",
  height: "20px",
  width: "20px",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  display: "flex",
  flexShrink: 0,
  fontSize: "12px",
  fontWeight: 400,
});

export const badgeTheme = defineStyleConfig({
  variants: { dominoBase, dominoNumberList, dominoInstructionsNumberList },
});
