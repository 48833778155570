import { useEffect, useRef, useState } from "react";
import { Box, Checkbox, Flex, Menu, MenuButton, MenuItem, MenuList, Text, Textarea, TextareaProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ButtonTypeDiscriminator, FlowMarkupTypeDiscriminator } from "../../../../common/AppEnums";
import { ButtonModel, InlineKeyboardMarkupModel, ReplyKeyboardMarkupModel } from "../../../../common/AppButtonsModel";
import { ButtonsContainerFlowAction, FlowActionModel, SendTextFlowActionModel } from "../../FlowBuilderModel";
import s from "./NodeEditPopup.module.scss";
import styles from "./Fallback.module.scss";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { InputWrapper } from "../../../inputWrapper/InputWrapper";
import { InputType } from "../../../inputWrapper/InputWrapperModel";
import { insertStringIntoText } from "../../../../common/utils/insertStringIntoText";
import { maxLength } from "../../../../common/validation/defaultValidators";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";

interface Props {
  maxLength: number;
  text: string;
  isEmojiDisabled?: boolean;
  maxHeightWithoutScroll?: number;
  TextAreaProps?: TextareaProps | undefined;
  isInvalid?: boolean;
  flowAction: ButtonsContainerFlowAction;
  dataChange: (flowAction: FlowActionModel) => void;
  onChange: (text: string) => void;
  validate: (flowAction: FlowActionModel) => void;
  isNodeFallback?: boolean;
}

export const Fallback = ({ isNodeFallback, flowAction, dataChange, validate, ...props }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const [cursorPosition, setCursorPosition] = useState<number>(props.text.length);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const setFallbackRetryCount = (count: number) => {
    dataChange({
      ...flowAction,
      retryCount: count,
    } as ButtonsContainerFlowAction);
  };

  const hasPayloadButtons = () => {
    if (isNodeFallback) {
      return true;
    }
    if (flowAction.replyMarkup) {
      switch (flowAction.replyMarkup.typeDiscriminator) {
        case FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup:
          if ((flowAction.replyMarkup as ReplyKeyboardMarkupModel).buttons.length > 0) {
            return true;
          }
          break;
        default:
          const inlineAction: InlineKeyboardMarkupModel = flowAction.replyMarkup as InlineKeyboardMarkupModel;
          if (inlineAction.buttons.length > 0) {
            const buttons: ButtonModel[][] = inlineAction.buttons;
            const isButtonWithNextStep = buttons.find((el: ButtonModel[]) =>
              el.find(
                (el: ButtonModel) =>
                  el.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageCallbackButton ||
                  el.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageDynamicButton,
              ),
            );
            return !!isButtonWithNextStep;
          }
          break;
      }
    }
  };

  useEffect(() => {
    validate(flowAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowAction.isFallback]);

  const onAddEmoji = (emoji: string) => {
    const text = insertStringIntoText(flowAction.fallbackMessage ?? "", emoji, cursorPosition);
    const lengthError = maxLength(500)(text);
    if (lengthError) {
      return;
    }
    props.onChange(text);
  };

  return hasPayloadButtons() ? (
    <Flex direction="column" width="100%">
      <Flex mt="24px" alignItems="center">
        <Checkbox
          isChecked={flowAction.isFallback}
          onChange={e => {
            dataChange({
              ...flowAction,
              retryCount: flowAction.retryCount || 1,
              fallbackMessage: flowAction.fallbackMessage ?? "",
              isFallback: e.target.checked,
            } as ButtonsContainerFlowAction);
          }}
          variant="dominoGreen"
          data-pw="fallback-checkbox"
        >
          <Text data-pw="textFallback">{t("Fallback")}</Text>
        </Checkbox>
        <TooltipWithTouch
          hasArrow
          background="#FEF6DC"
          variant="dominoLight"
          whiteSpace={"pre-wrap"}
          label={t(
            "Fallback is activated when the contact fails to click on any of the provided buttons in the message and instead inputs text, numbers, or other similar data",
          )}
        >
          <QuestionOutlineIcon ml={"8px"} boxSize={"16px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
        </TooltipWithTouch>
      </Flex>
      <Box opacity={flowAction.isFallback ? 1 : "0.4"} pointerEvents={flowAction.isFallback ? "auto" : "none"}>
        <Flex mb="12px">
          <Flex flexWrap="wrap" onClick={e => e.stopPropagation()} className={s.retry}>
            {t("editNodePopup.Retry")}&nbsp;
            <Menu variant="dominoDotsMenu" gutter={0} offset={[-26, -2]}>
              {({ isOpen }) => (
                <>
                  <MenuButton as={Text} className={s.retryText} color={isOpen ? "blueLink" : ""} data-pw="retry-text">
                    {flowAction.retryCount || 1} {t("editNodePopup.time(s)")}
                  </MenuButton>

                  <MenuList zIndex={4} minWidth={"160px"}>
                    <MenuItem onClick={() => setFallbackRetryCount(1)} data-pw="retry-count-1">
                      1 {t("editNodePopup.1 time")}
                    </MenuItem>
                    <MenuItem onClick={() => setFallbackRetryCount(2)} data-pw="retry-count-2">
                      2 {t("editNodePopup.times")}
                    </MenuItem>
                    <MenuItem onClick={() => setFallbackRetryCount(3)} data-pw="retry-count-3">
                      3 {t("editNodePopup.times")}
                    </MenuItem>
                    <MenuItem onClick={() => setFallbackRetryCount(4)} data-pw="retry-count-4">
                      4 {t("editNodePopup.times")}
                    </MenuItem>
                    <MenuItem onClick={() => setFallbackRetryCount(5)} data-pw="retry-count-5">
                      5 {t("editNodePopup.5 times")}
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
            &nbsp;
            <Box mr="4px">
              <p className={s.text}>{t("editNodePopup.time(s) if the user reply is")}</p>
            </Box>
            <Box>
              <p className={s.text}>{t("editNodePopup.invalid")}</p>
            </Box>
          </Flex>
        </Flex>
        <InputWrapper
          cursorPosition={cursorPosition}
          type={InputType.TextArea}
          maxLength={props.maxLength}
          charactersNumber={flowAction.fallbackMessage?.length ?? 0}
          emojiProps={props.isEmojiDisabled ? undefined : { addEmoji: onAddEmoji }}
          childRef={textareaRef}
        >
          <Textarea
            className={styles.fallbackTextArea}
            variant="dominoTextEditorColor"
            ref={textareaRef}
            isInvalid={props.isInvalid}
            onChange={e =>
              dataChange({
                ...flowAction,
                fallbackMessage: e.target.value,
              } as SendTextFlowActionModel)
            }
            onSelect={e => {
              setCursorPosition(e.currentTarget.selectionStart);
            }}
            name="text"
            id="text"
            maxLength={500}
            placeholder={`${t("Enter hint text to help user submit a valid reply")}`}
            value={flowAction.fallbackMessage}
            borderRadius={"none"}
            borderTopRadius="8px"
            borderBottomRadius="8px"
            maxH={500}
            {...props.TextAreaProps}
            data-pw="fallback-textarea"
          />
        </InputWrapper>
      </Box>
    </Flex>
  ) : (
    <></>
  );
};
