/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { ContactListModel, CustomVariableModel, ImportResult, NewContactFormModel } from "./ContactListModel";
import { act } from "react";

export interface ContactListState {
  contactList: ContactListModel;
  importResult?: ImportResult | null;
  customVariables?: CustomVariableModel[] | null;
  searchParams?: ContactsSearchParams | null;
}

export interface ContactsSearchParams {
  search: string;
  filters: string;
}

const initialState: ContactListState = {
  contactList: { items: [], currentPage: 1, totalItems: 75, totalPages: 5 },
};

export const contactListSlice = createSlice({
  name: "contactListSlice",
  initialState,
  reducers: {
    getContactList: (state, action: PayloadAction<{ filters: string; search: string; page: number; size?: number }>) => {
      return {
        ...state,
        contactList: { ...state.contactList, currentPage: action.payload.page, items: undefined },
      };
    },
    getContactListSucceed: (state, action: PayloadAction<{ contactList: ContactListModel }>) => {
      return {
        ...state,
        contactList: action.payload.contactList,
      };
    },
    importContacts: (state, action: PayloadAction<{ file: File | null; botIds: string[] }>) => {
      return {
        ...state,
      };
    },
    setImportResult: (state, action: PayloadAction<{ importResult: ImportResult | null }>) => {
      return {
        ...state,
        importResult: action.payload.importResult,
      };
    },
    createContact: (state, action: PayloadAction<NewContactFormModel>) => {
      return {
        ...state,
      };
    },
    deleteContact: (state, action: PayloadAction<{ contactId: string }>) => {
      return {
        ...state,
      };
    },
    clearContactListState: () => {
      return initialState;
    },
    getCustomVariablesSucceed: (state, action: PayloadAction<CustomVariableModel[]>) => {
      return {
        ...state,
        customVariables: action.payload,
      };
    },
    getCustomVariables: state => {
      return {
        ...state,
      };
    },
    setStateSearchParams: (state, action: PayloadAction<{ filters: string; search: string }>) => {
      return {
        ...state,
        searchParams: {
          filters: action.payload.filters,
          search: action.payload.search,
        },
      };
    },
  },
});

export const {
  importContacts,
  getContactList,
  setImportResult,
  deleteContact,
  createContact,
  clearContactListState,
  getCustomVariables,
  setStateSearchParams,
} = contactListSlice.actions;

export const selectContactList = (state: RootState) => state.app.contactListState.contactList;
export const selectImportResult = (state: RootState) => state.app.contactListState.importResult;
export const selectCustomVariables = (state: RootState) => state.app.contactListState.customVariables;
export const selectSearchParams = (state: RootState) => state.app.contactListState.searchParams;

export default contactListSlice.reducer;
