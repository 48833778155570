import { useBrowserFitContent, useTabletView } from "../../LayoutHelper/ResolutionHooks";
import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { UserProfileHeader } from "../../../userProfile/components/UserProfileHeader";
import { UserProfileContainer } from "../../../userProfile/UserProfileContainer";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { HEADER } from "../../../layout/LayoutHelper/ResolutionConst";
import { useAppSelector } from "../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";

export const MyProfilePage = () => {
  const browserFill = useBrowserFitContent();
  const isTablet = useTabletView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const mobileHeight = window.innerHeight - HEADER - HEADER - (isTrial ? HEADER : 0);
  const [containerHeight, setContainerHeight] = useState(mobileHeight);
  const [mobileView, isMobileView] = useState(isTablet);

  const handleWindowResize = () => {
    setContainerHeight(mobileHeight);
  };

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <UserProfileHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={mobileView ? containerHeight : browserFill}>
        <Loading />
        <UserProfileContainer />
      </Box>
    </Flex>
  );
};
