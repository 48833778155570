import s from "./AudioNodePopup.module.scss";
import sm from "../NodeEditPopup.module.scss";
import { ReactComponent as AudioIcon } from "../../../../../assets/icons/audioIcon.svg";
import {
  CustomVariablesModel,
  NodeModel,
  FlowModel,
  SendAudioFlowActionModel,
  NodeValidationProp,
  FlowActionModel,
} from "../../../FlowBuilderModel";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { editNode, saveNode, selectCustomVariables, selectNodeEditorState } from "../../../FlowBuilderSlice";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  FormControl,
  DrawerFooter,
  Icon,
  Text,
  Flex,
  Button,
} from "@chakra-ui/react";
import { ButtonsList } from "../ButtonsList";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import { useTranslation } from "react-i18next";
import { ReplyKeyboardButtonModel, ButtonModel } from "../../../../../common/AppButtonsModel";
import { Fallback } from "../Fallback";
import { getButtonsWithExactType } from "../../../utils";
import { FileNameSplitter } from "../../../../../UI/atoms/fileNameSplitter/FileNameSplitter";
import { DragAndDropContainer, handleDataChange, handleDrag } from "../DragAndDropContainer";
import { useState } from "react";

interface Props {
  addButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  deleteButton: (buttonId: string) => void;
  setButtons: (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => void;
  addCustomVariableText: (variable: CustomVariablesModel, variableMaxLength: number) => void;
  editButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  dataChange: (flowAction: FlowActionModel) => void;
  flow: FlowModel;
  node: NodeModel;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
}

export const AudioNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectNodeEditorState);
  const variables = useAppSelector(selectCustomVariables);
  const [isDragging, setDragging] = useState(false);

  if (!currentNode) {
    return <></>;
  }

  const flowAction = props.node.flowAction as SendAudioFlowActionModel;

  const fieldTypeExt = ".mp3,.aac,.flac ";
  const fileSizeMB = 50;
  const fileSizeB = fileSizeMB * 1024 * 1024;

  const onDataChange = (text: string, file?: File) => {
    handleDataChange({ file, dispatch, currentNode, fieldTypeExt, fileSizeB });

    const newData: SendAudioFlowActionModel = {
      ...flowAction,
      text,
    };
    props.dataChange(newData);
    dispatch(
      editNode({
        ...currentNode,
        flowAction: newData,
      }),
    );
  };

  const handleFallbackChange = (text: string) => {
    const newFlowAction = { ...flowAction, fallbackMessage: text };
    dispatch(
      editNode({
        ...currentNode,
        flowAction: newFlowAction,
      }),
    );
  };

  const flowActionDataChange = (flowAction: SendAudioFlowActionModel) => {
    dispatch(
      editNode({
        ...currentNode,
        flowAction: flowAction,
      }),
    );
  };

  const onCloseValidate = () => {
    dispatch(
      saveNode({
        flow: props.flow,
        node: props.node,
      }),
    );
  };

  return (
    <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoDrawer">
      <DrawerContent>
        <Loading scope="nodeValidate" />
        <DrawerHeader>{t("Audio Message")}</DrawerHeader>
        <DrawerBody paddingInline="7px">
          <div className={s.popupWrapper}>
            <div className={s.popup}>
              <div className={s.wrapper}>
                <div onDragEnter={e => handleDrag(e, setDragging)} className={s.popupItem}>
                  <FormControl
                    className={`${sm.itemFileWrp} ${flowAction.file || flowAction.fileInfo ? sm.itemFileWrpSolid : ""}`}
                  >
                    <div className={`${s.popupItem} ${s.documentBlock}`}>
                      <Loading scope={`postFileEditor${props.node.id}`} />
                      <label data-pw="audioInputLabel" className={s.input}>
                        <Icon as={AudioIcon} boxSize="28px" mb="8px" />
                        {flowAction.fileInfo ? (
                          <FileNameSplitter
                            data-pw="audioInput"
                            style={sm.fileName}
                            fileName={flowAction.fileInfo.name}
                            variant={"medium"}
                          />
                        ) : flowAction.file ? (
                          <FileNameSplitter
                            data-pw="audioFile"
                            style={sm.fileName}
                            fileName={flowAction.file.name}
                            variant={"medium"}
                          />
                        ) : (
                          <Flex data-pw="uploadAudio" textAlign="center" direction="column" className={sm.fileName}>
                            <Text>{t("editNodePopup.Upload Audio")}</Text>
                            <Text variant="small">{t("editNodePopup.mp3 up to 20 mb")}</Text>
                          </Flex>
                        )}
                        <input
                          onChange={e => {
                            if (e.target.files) {
                              onDataChange(flowAction.text ?? "", e.target.files[0]);
                            }
                          }}
                          type="file"
                          accept={fieldTypeExt}
                        />
                      </label>
                      {isDragging && !flowAction.fileId && (
                        <DragAndDropContainer
                          setDragging={setDragging}
                          handleDataChange={{ dispatch, currentNode, fieldTypeExt, fileSizeB }}
                        />
                      )}
                    </div>
                  </FormControl>
                  <MessageTextEditor
                    variables={variables ?? []}
                    validate={props.validate}
                    flowAction={flowAction}
                    text={flowAction.text ?? ""}
                    maxLength={500}
                    onChange={t => onDataChange(t, flowAction.file)}
                    addCustomVariableText={props.addCustomVariableText}
                  />
                  <ButtonsList
                    node={props.node}
                    buttons={
                      (getButtonsWithExactType(flowAction?.replyMarkup, flowAction) as
                        | ButtonModel[][]
                        | ReplyKeyboardButtonModel[][]) ?? []
                    }
                    flowAction={flowAction}
                    addButton={props.addButton}
                    setButtons={props.setButtons}
                    deleteButton={props.deleteButton}
                    editButton={props.editButton}
                  />
                  <Fallback
                    validate={props.validate}
                    flowAction={flowAction}
                    dataChange={flowActionDataChange}
                    maxLength={500}
                    text={""}
                    onChange={handleFallbackChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </DrawerBody>
        {props.validationInfo.isError && (
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
        )}
        <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => onCloseValidate()} data-pw="apply-button">
          {t("Apply")}
        </Button>
      </DrawerContent>
    </Drawer>
  );
};
