import React from "react";
import { Trans } from "react-i18next";
import { Box, Text, Link } from "@chakra-ui/react";
import s from "../../components/CreateConnectBot/CreateConnectBotContainer.module.scss";

interface BotFatherLinkProps {
  i18nKey: string;
}

export const BotFatherLink: React.FC<BotFatherLinkProps> = ({ i18nKey }) => (
  <Box>
    <Trans i18nKey={i18nKey}>
      <Link className={s.botFatherLink} href="https://telegram.me/BotFather" isExternal />
      <Text className={s.strongWord} as="span" />
    </Trans>
  </Box>
);
