import { httpGet, httpPostIdentity, httpPut } from "../../common/BaseApi";
import { formatDateToUTCString } from "../../common/utils/formatDate";

export const impersonateApi = (username: string, refresh: string | undefined) => {
  return httpPostIdentity(`/api/userManagement/impersonate`, { username, refresh });
};

export const getAllOrganisation = (username: string) => {
  return httpGet(`/api/admin/getOrganizations/${username}`);
};

export const getAllBillingPlansApi = () => {
  return httpGet(`/api/admin/getAllBillingPlans`);
};

export const setSubscriptionApi = (organizationId: string, BillingPlanId: string, EndDate: Date) => {
  const dateNow = new Date(Date.now());
  EndDate.setHours(dateNow.getHours());
  EndDate.setMinutes(dateNow.getMinutes());
  EndDate.setSeconds(dateNow.getSeconds());
  EndDate.setMilliseconds(dateNow.getMilliseconds());
  const startDate = formatDateToUTCString(dateNow);
  const endDate = formatDateToUTCString(EndDate);

  return httpPut(
    `/api/admin/setSubscription/${organizationId}?BillingPlanId=${BillingPlanId}&StartDate=${startDate}&EndDate=${endDate}`,
    {},
  );
};
