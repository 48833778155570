import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { FlowItemModel, FlowListModel } from "../AutomationModel";
import { useLocation, useNavigate } from "react-router-dom";
import s from "../Automation.module.scss";
import { useTranslation } from "react-i18next";
import { getTariffPlanAccessability } from "../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../common/AppEnums";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import { FlowListItem } from "./FlowListItem/FlowListItem";
import { useCallback, useEffect, useState } from "react";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import {
  closestCenter,
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  restrictToFirstScrollableAncestor,
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { reorderFlow, selectCanAddNewFlow } from "../AutomationSlice";
import { selectLanguage } from "../../../common/user/UserSlice";

interface Props {
  flowList?: FlowListModel;
  currentPage: number;
  onDelete: (flow: FlowItemModel) => void;
  onCopy: (flow: FlowItemModel) => void;
  botId: string;
}

export const FlowList = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const selectFlow = useCallback((currentFlow: FlowItemModel) => {
    navigate(`${currentFlow.id}`, { state: { search } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const accessibility = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.ActiveFlows);
  const accessibilityNodeCount = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.NodeCountPerFlow);
  const { IsUnlimited } = accessibility;
  const canAddFlow = useAppSelector(selectCanAddNewFlow) ?? false;

  const accessibilityImportExportFlow = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.ImportExportFlow);
  const { IsExportAvailable } = accessibilityImportExportFlow;
  const isDesktop = useMediaQuery("(min-width: 770px)")[0];
  const lng = useAppSelector(selectLanguage);
  const [items, setItems] = useState<Array<FlowItemModel>>([]);
  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(MouseSensor),
  );
  const showReorder = props.flowList ? props.flowList?.items.length > 1 : false;
  const [dragFlowItem, setFlowListItem] = useState<FlowItemModel | null>(null);

  useEffect(() => {
    setItems(props.flowList?.items || []);
  }, [props.flowList?.items]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setFlowListItem(null);

    if (active && over && over.id) {
      setItems(items => {
        let newItems = items.map(x => {
          if (x.id === active.id) {
            return { ...x, isDrag: false };
          }
          return x;
        });

        if (active.id !== over.id) {
          const oldIndex = items.findIndex(x => x.id === active.id);
          const newIndex = items.findIndex(x => x.id === over.id);

          newItems = arrayMove(newItems, oldIndex, newIndex);
          dispatch(
            reorderFlow({
              botId: props.botId,
              sourceFlowId: active.id.toString(),
              destinationFlowId: over.id.toString(),
            }),
          );
        }

        return newItems;
      });
    } else {
      setItems(items => {
        return items.map(x => {
          return { ...x, isDrag: false };
        });
      });
    }
  };

  const handleDragCancel = (event: DragCancelEvent) => {
    setFlowListItem(null);
    setItems(items => {
      return items.map(x => {
        return { ...x, isDrag: false };
      });
    });
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    if (active) {
      setItems(items => {
        return items.map(x => {
          if (x.id === active.id) {
            setFlowListItem(x);
            return { ...x, isDrag: true };
          }
          return x;
        });
      });
    }
  };

  return (
    <Box className={s.noselect} position={"relative"}>
      <Box className={`${s.flowListItemGrid} ${s.flowListFilter}`} width={"100%"} position="absolute">
        <Text noOfLines={1} marginLeft={"32px"}>
          {t("Name")}
        </Text>
        <Text>{tp("Nodes")}</Text>
        <Text noOfLines={1}>{t("Runs")}</Text>
        <Text noOfLines={1}>{t("Modified")}</Text>
        <div />
      </Box>
      <Box className={s.flowListBox} height={"95dvh"} overflow="auto" paddingLeft={isDesktop && !showReorder ? "22px" : "0px"}>
        <DndContext
          autoScroll={{
            enabled: true,
            acceleration: 10,
          }}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToParentElement, restrictToFirstScrollableAncestor]}
        >
          <SortableContext strategy={verticalListSortingStrategy} items={items.map(x => x.id)}>
            <Flex flexDirection="column">
              {items.map((flowItem: FlowItemModel) => {
                return (
                  <FlowListItem
                    showReorder={showReorder}
                    key={flowItem.id}
                    flowItem={flowItem}
                    lng={lng}
                    IsUnlimited={IsUnlimited}
                    accessibilityNodeCount={accessibilityNodeCount}
                    canAddFlow={canAddFlow}
                    IsExportAvailable={IsExportAvailable}
                    isDrag={flowItem.isDrag}
                    hideArrow={dragFlowItem ? dragFlowItem.id !== flowItem.id : false}
                    selectFlow={selectFlow}
                    onDelete={props.onDelete}
                    onCopy={props.onCopy}
                  />
                );
              })}
            </Flex>
          </SortableContext>
        </DndContext>
      </Box>
    </Box>
  );
};
