import { ButtonModel, StaticButtonModel } from "../../common/AppButtonsModel";
import { BroadcastReplyData, ContactData, CustomVariableData } from "./BroadcastData";
import {
  BaseBroadcatMessageModel,
  //BroadcastFormModel,
  BroadcastMessageTypeDescriminator,
  BroadcastModel,
  BroadcatMessageModel,
  ContactModel,
  FileModel,
  FileUploadType,
  MemberModel,
  RecipientFiltersModel,
  RecipientFormModel,
  RecipientModel,
} from "./BroadcastModel";
import { v1 as uuidv1 } from "uuid";
import { ComplexFilterFieldTypes, FilterParams } from "../complexFilter/ComplexFilterModel";
import { CustomVariableModel } from "../../common/AppEnums";
import "../../common/utils/stringUtils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { store } from "../..";

dayjs.extend(utc);

export function getMessage(message: BaseBroadcatMessageModel, currentFile?: FileModel): BroadcatMessageModel {
  const text = message.text.trim();
  const buttons = message.buttons;
  if (currentFile?.type === FileUploadType.photo) {
    return {
      text,
      buttons,
      typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastPhotoMessage,
      ...currentFile.file,
    };
  } else if (currentFile?.type === FileUploadType.document) {
    return {
      text,
      buttons,
      typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastDocumentMessage,
      ...currentFile.file,
    };
  }
  return {
    text,
    buttons,
    typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastTextMessage,
  };
}

export const getNodeVariableIds = (text: string, vars: CustomVariableData[]): string[] => {
  const regex = /@{([\wа-яА-ЯёЁ-]+):([\wа-яА-ЯёЁ-]+)}/g;
  const variables = text.match(regex);
  if (!variables) {
    return [];
  }

  const variableIds = variables
    .map(el => {
      const scopeAndKey = el.replace(regex, "$1:$2");
      const [scope, key] = scopeAndKey.split(":");
      const customVariable = vars.find(el => key && el.key === key && el.scope === scope);
      return customVariable?.id ?? "";
    })
    .filter(x => x.length > 0);
  return variableIds;
};

export const mapCustomVariableIds = (text: string | undefined, customVariables: CustomVariableData[]): string[] => {
  if (!text) {
    return [];
  }
  const customVariableIds = getNodeVariableIds(text, customVariables);
  return customVariableIds;
};

const mapContactModelToRecipientData = (model: ContactModel) => {
  const data: RecipientModel = {
    externalContactId: model.externalId,
    externalConversationId: model.conversationId,
    typeDiscriminator: "TelegramBroadcastRecipient",
    username: model.username,
    name: model.firstName,
    contactId: model.id,
    conversationId: model.conversationId,
  };
  return data;
};

export const mapBroadcastCreateModelToData = (
  broadcast: BroadcastModel,
  currentFile: FileModel | undefined,
  customVariables: CustomVariableData[],
) => {
  const recipientsState = store.getState().app.brodcastRecipientListState;
  const sentForAllFlag = recipientsState.isSelectAll;
  const includedRecipients = Object.values(recipientsState?.includedRecipients);
  const includedRecipientList = includedRecipients.map(e => mapContactModelToRecipientData(e));

  const recipients = recipientsState || !sentForAllFlag ? includedRecipientList : [];

  const message = getMessage(broadcast.message, currentFile);
  message.buttons = mapBroadcastUrlButtonToPayload(message.buttons);
  const customVariableIds = mapCustomVariableIds(message.text, customVariables);
  return { message, recipients, customVariableIds, sentForAllFlag };
};

export const mapBroadcastEditModelToData = (
  broadcast: BroadcastModel,
  currentFile: FileModel | undefined,
  customVariables: CustomVariableData[],
) => {
  const recipietnsListState = store.getState().app.brodcastRecipientListState;
  const recipientList = recipietnsListState.recipientList ? recipietnsListState.recipientList.items : [];
  const allContactList = recipietnsListState.contactList ? recipietnsListState.contactList.items : {};
  const excludedRecipientsList = Object.values(recipietnsListState.excludedRecipients).map(e =>
    mapContactModelToRecipientData(e),
  );
  const includedRecipientList = Object.values(recipietnsListState.includedRecipients).map(e => mapContactModelToRecipientData(e));
  const message = getMessage(broadcast.message, currentFile);
  message.buttons = mapBroadcastUrlButtonToPayload(message.buttons);
  const sentForAllFlag =
    recipietnsListState.isSelectAll || (!recipietnsListState.initialIsSelectAll && recipietnsListState.isUnCheckedFlag)
      ? true
      : false;
  const excludedAllFlag =
    (recipietnsListState.initialIsSelectAll && !recipietnsListState.isSelectAll && !recipietnsListState.isUnCheckedFlag) ||
    recipietnsListState.isCheckedUncheckedFlag
      ? true
      : false;

  const includedRecipients = excludedAllFlag
    ? Object.keys(recipietnsListState.includedRecipients).map(e => mapContactModelToRecipientData(allContactList[e]))
    : !excludedAllFlag && !recipietnsListState.isSelectAll && recipietnsListState.isCheckedUncheckedFlag
    ? includedRecipientList
    : includedRecipientList;

  const excludedRecipients = excludedAllFlag
    ? []
    : !excludedAllFlag &&
      !recipietnsListState.isSelectAll &&
      recipietnsListState.isUnCheckedFlag &&
      recipientList.length &&
      excludedRecipientsList.length > 0
    ? excludedRecipientsList
    : !excludedAllFlag && recipietnsListState.isSelectAll
    ? []
    : excludedRecipientsList;

  const customVariableIds = mapCustomVariableIds(message.text, customVariables);
  return { message, includedRecipients, excludedRecipients, customVariableIds, excludedAllFlag, sentForAllFlag };
};

export const mapContactListToRecipientsList = (data: ContactModel) => {
  const model: RecipientFormModel = {
    contactId: data.id,
    conversationId: data.conversationId,
    externalId: data.externalId,
  };
  return model;
};

export function mapRecipientsFiltersToData(filters: FilterParams[], variables: CustomVariableModel[] | undefined) {
  const findTargetCustomVar = (filter: FilterParams) => {
    const condition = filter.field?.groupType === "CustomVariables" && variables && variables.length && filter.field?.value;
    return condition ? variables.find(el => el.key === filter.field?.value)?.id : null;
  };

  const identifyKey = (filter: FilterParams) => {
    return filter.field?.groupType === "CustomVariables" && variables ? filter.field?.value : null;
  };

  const valueByType = (filter: FilterParams) => {
    switch (filter.field?.type) {
      case ComplexFilterFieldTypes.Boolean:
        const booleanValue = filter.condition?.value;
        if (booleanValue === "True" || booleanValue === "False") {
          return (booleanValue.toLowerCase() === "true").toString();
        }
        return "";
      case ComplexFilterFieldTypes.DateTime:
        if (!filter.conditionValue?.value) return "";
        const dateTimeValue = filter.conditionValue.value.toDate();
        const jsDate = dayjs.utc(dateTimeValue).format("YYYY-MM-DDTHH:mm:ss");
        return jsDate.toString();
      default:
        return filter.conditionValue?.value ?? "";
    }
  };

  const conditionByType = (filter: FilterParams) => {
    switch (filter.field?.type) {
      case "Boolean":
        const condition = filter.condition?.value;
        if (!(condition === "HasValue" || condition === "HasNoValue")) {
          return "equal";
        }
        return filter.condition?.value ?? "";
      default:
        return filter.condition?.value ?? "";
    }
  };

  const members: MemberModel[] = filters.map(filter => {
    return {
      typeDiscriminator: "BroadcastRecipientFilter",
      targetCustomVariableKey: identifyKey(filter),
      targetCustomVariable: findTargetCustomVar(filter),
      field: filter.field?.groupType === "CustomVariables" ? null : filter.field?.value ?? "",
      valueType: filter.field?.type ?? "",
      value: valueByType(filter),
      condition: conditionByType(filter),
    };
  });
  const actionFilter: RecipientFiltersModel = {
    typeDiscriminator: "BroadcastRecipientGroupFilter",
    members: members,
  };

  return [actionFilter];
}

export const mapBroadcastDataToModel = (broadcastData: BroadcastReplyData): BroadcastModel => {
  let buttons = broadcastData.message.buttons.map(button => ({
    ...button,
    id: uuidv1(),
  }));
  buttons = mapBroadcastPayloadToUrlButton(buttons);
  const broadcast: BroadcastModel = {
    ...broadcastData,
    message: { ...broadcastData.message, buttons },
    isScheduled: !!broadcastData.scheduledDate,
    isExecute: false,
    executeState: { dirty: false, execute: false },
  };
  return broadcast;
};

const mapBroadcastUrlButtonToPayload = (buttons: ButtonModel[]) => {
  let item = buttons as StaticButtonModel[];
  item = item.map(btn => {
    if (btn.type === "UrlButton") {
      return { ...btn, payload: btn.url };
    } else {
      return { ...btn };
    }
  });
  return item;
};

const mapBroadcastPayloadToUrlButton = (buttons: ButtonModel[]) => {
  let item = buttons as StaticButtonModel[];
  item = item.map(btn => {
    if (btn.type === "UrlButton") {
      return { ...btn, url: btn.payload };
    } else {
      return { ...btn };
    }
  });
  return item;
};

export const mapContactListDataToModel = (data: ContactData) => {
  const model: ContactModel = {
    id: data.id,
    conversationId: data.conversationId,
    externalId: data.externalId,
    username: data.username,
    phoneNumber: data.phoneNumber,
    firstName: data.firstName,
    lastName: data.lastName,
    isSelected: data.isSelected ? data?.isSelected : false,
    isSelectedInitial: data.isSelected ? data?.isSelected : false,
  };
  return model;
};

export const mapContactItemDataToModel = (data: ContactData) => {
  const model: ContactModel = {
    id: data.id,
    conversationId: data.conversationId,
    externalId: data.externalId,
    username: data.username,
    phoneNumber: data.phoneNumber,
    firstName: data.firstName,
    lastName: data.lastName,
    isSelected: data.isSelected || false,
    isSelectedInitial: data.isSelected,
  };
  return model;
};

export const mapRecipientModalToRecipientFormModal = (data: RecipientModel) => {
  const model: RecipientFormModel = {
    contactId: data.contactId,
    conversationId: data.conversationId,
    externalId: data.externalContactId,
  };
  return model;
};

export const mapContactItemDataToRecipientFormModel = (data: ContactModel) => {
  const model: RecipientFormModel = {
    contactId: data.id,
    conversationId: data.conversationId,
    externalId: data.externalId,
  };
  return model;
};
