import { Tour } from "./OnboardingToursState";

//data = backend
export const mapToursDataToToursModel = (data: Tour[]): Tour[] => {
  const toursResult: Tour[] = data.map(el => {
    return { ...el, run: false };
  });
  return toursResult;
};

export const mapStartTourDataToTourModel = (tours: Tour[], tourName: string): Tour[] => {
  const existingTour = tours.find(tour => tour.tourName === tourName);

  if (existingTour) {
    return tours.map(tour => (tour.tourName === tourName ? { ...tour, run: true } : tour));
  } else {
    return [...tours, { tourName, isCompleted: false, run: true, isSkipped: false }];
  }
};

export const mapSkipTourDataToTourModel = (tours: Tour[], tourName: string): Tour[] => {
  const existingTour = tours.find(tour => tour.tourName === tourName);

  if (existingTour) {
    return tours.map(tour => (tour.tourName === tourName ? { ...tour, run: false, isSkipped: true } : tour));
  }
  return tours;
};

export const mapCompleteTourDataToTourModel = (tours: Tour[], tourName: string): Tour[] => {
  const existingTour = tours.find(tour => tour.tourName === tourName);

  if (existingTour) {
    return tours.map(tour => (tour.tourName === tourName ? { ...tour, run: false, isSkipped: false, isCompleted: true } : tour));
  }
  return tours;
};
