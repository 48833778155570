import { Box, Flex, Input, InputGroupProps, InputProps } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { notHaveOnlySpaces } from "../../../common/validation/defaultValidators";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";

import s from "./InputVariable.module.scss";
import { CustomVariableContextMenu } from "../../../components/modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../components/modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableModel, CustomVariableScope, CustomVariableType } from "../../../common/AppEnums";

interface Props extends InputProps {
  text?: string | undefined;
  inputGroupStyles?: InputGroupProps;
  setText: (value: string) => void;
  classProp?: string;
  variables: CustomVariableModel[];
  selectCustomVariable: (variable: CustomVariableModel) => void;
  onlyVariableType?: CustomVariableType | undefined;
  excludeVariableType?: CustomVariableType | undefined;
  onlyVariableScope?: CustomVariableScope[] | undefined;
  isDisabled?: boolean;
}
export default function InputVariable(props: Props) {
  const onChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const errors = notHaveOnlySpaces()(event.target.value);
    if (errors) return;
    props.setText(event.target.value);
  };

  const onDeleteSearchText = () => {
    props.setText("");
  };

  const filterVariablesType = props.onlyVariableType
    ? props.variables.filter(el => el.type === props.onlyVariableType)
    : props.variables;

  const filterVariablesScope = props.onlyVariableScope
    ? filterVariablesType.filter(el => props.onlyVariableScope?.includes(CustomVariableScope[el.scope]))
    : filterVariablesType;
  return (
    <Flex
      alignItems="center"
      border="1px solid var(--chakra-colors-line)"
      borderRadius="8px"
      _hover={!props.isDisabled ? { borderColor: "var(--chakra-colors-darkPurple)" } : { borderColor: "none" }}
    >
      <Input border="none" isInvalid={false} value={props.text} onChange={onChangeSearchText} {...props} data-pw="input" />
      <Box>
        {props.text && <CrossIcon cursor={"pointer"} className={s.crossIcon} onClick={onDeleteSearchText} />}
        {!props.text && (
          <CustomVariableContextMenu
            type={CustomVariableContextMenuType.Default}
            selectCustomVariable={props.selectCustomVariable}
            variables={filterVariablesScope}
            disableBtn={props.isDisabled}
          />
        )}
      </Box>
    </Flex>
  );
}
