export interface GetTokenRequestBaseModel {
  fingerprint: string | null;
  inviteToken?: string;
}

export interface GetTokenPasswordRequestModel extends GetTokenRequestBaseModel {
  identity: string;
  password: string;
}

export interface GetTokenAuthCodeRequestModel extends GetTokenRequestBaseModel {
  authCode: string;
}

export interface GetTokenResponseModel {
  accessToken: string;
  refresh: string;
  isExpired: boolean;
  expiration: Date;
}

export enum TabNames {
  "signIn" = "signIn",
  "signUp" = "signUp",
  "emailConfirmation" = "emailConfirmation",
}

export enum ButtonConfirmTypes {
  "signIn" = "sign-in-button",
  "signUp" = "sign-up-button",
}
