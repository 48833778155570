import { ContactModel } from "../../BroadcastModel";

const isSelected = (
  isSelectContact: boolean,
  index: number,
  isIncludeAndExcludeEmpty: boolean,
  currentRecipientFlag?: boolean,
  includedFlag?: boolean,
  excludedFlag?: boolean,
  isSelectAll?: boolean,
  isInititalSelectAll?: boolean,
  accessability?: { MaxCount: number; IsUnlimited: boolean },
  isChangeSelectAll?: boolean,
) => {
  //ToDo изменить логику первым делом проверяем isFilter(прокинуть этот флаг,чувствую при фильтре чуть другие правила будут работать)->разветвление-> проверяем isInititalSelectAll->разветвление->проверяем isSelectAll->разветвление->дальше проверяем ограничения ->разветвление->дальше проверяем пустой ли include и exclude ->разветвление-> выбираем из 3 флагов(isSelectContact,includedFlag,excludedFlag) нужный нам ответ
  if (isInititalSelectAll === true && !isSelectAll) {
    let resultBoolean = isSelectContact;
    if (includedFlag !== undefined) {
      resultBoolean = includedFlag;
    }

    if (excludedFlag !== undefined) {
      resultBoolean = excludedFlag;
    }

    return isIncludeAndExcludeEmpty ? false : resultBoolean;
  }

  if (isInititalSelectAll && isSelectAll && isChangeSelectAll === false) {
    let resultBoolean = isSelectContact;
    if (includedFlag !== undefined) {
      resultBoolean = includedFlag;
    }

    if (excludedFlag !== undefined) {
      resultBoolean = excludedFlag;
    }
    return resultBoolean;
  }

  if (isSelectAll) {
    if (accessability && accessability?.IsUnlimited) {
      return true;
    }
    if (accessability && !accessability.IsUnlimited) {
      return currentRecipientFlag !== undefined ? currentRecipientFlag : index < accessability.MaxCount;
    }
  }

  if (includedFlag !== undefined) {
    return includedFlag;
  }

  if (excludedFlag !== undefined) {
    return excludedFlag;
  }

  return currentRecipientFlag || isSelectContact;
};
export const ContactListDataToContactListModel = (
  oldItems: { [key: string]: ContactModel },
  newItems: ContactModel[],
  currentPage: number,
  isIncludeAndExcludeEmpty: boolean,
  includedList?: { [key: string]: ContactModel },
  excludedList?: { [key: string]: ContactModel },
  isSelectAll?: boolean,
  isInititalSelectAll?: boolean,
  accessability?: { MaxCount: number; IsUnlimited: boolean },
  currentRecipientList?: { [key: string]: ContactModel },
  isChangeSelectAll?: boolean,
  isFilter?: boolean,
): { [key: string]: ContactModel } => {
  const result: { [key: string]: ContactModel } = { ...oldItems };
  newItems.forEach((el, ind) => {
    const current =
      currentRecipientList && currentRecipientList[el.id] === undefined
        ? undefined
        : currentRecipientList
        ? currentRecipientList[el.id]?.isSelected
        : undefined;
    result[el.id] = {
      ...el,
      isSelected:
        currentPage > 1 || isFilter
          ? isSelected(
              Boolean(el?.isSelected),
              20 * (currentPage - 1) + ind,
              isIncludeAndExcludeEmpty,
              current,
              includedList && includedList[el.id] === undefined
                ? undefined
                : includedList
                ? includedList[el.id].isSelected
                : undefined,
              excludedList && excludedList[el.id] === undefined
                ? undefined
                : excludedList
                ? excludedList[el.id].isSelected
                : undefined,
              isSelectAll,
              isInititalSelectAll,
              accessability,
              isChangeSelectAll,
            )
          : el.isSelected,
    };
  });
  return result;
};
