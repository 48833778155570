import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import s from "./Paginator.module.scss";

interface Props {
  lastPage: number;
  totalPages: number;
  currentPage: number;
  totalItems: number;
  getPage: (page: number) => void;
  isFirstLoad: boolean;
}

export const PaginatorNumber = (props: Props) => {
  function isCurrentPage(page: number) {
    return props.currentPage === page;
  }

  function getLastComponent() {
    return (
      <Button
        isDisabled={props.isFirstLoad}
        size={"2px"}
        onClick={() => {
          if (props.currentPage !== props.lastPage) {
            props.getPage(props.lastPage);
          }
        }}
        variant="dominoGhostPaginator"
        isActive={isCurrentPage(props.lastPage)}
        data-pw="paginator-last-page"
      >
        {props.lastPage}
      </Button>
    );
  }

  function getFirstComponent() {
    return (
      <Button
        isDisabled={props.isFirstLoad}
        size={"2px"}
        onClick={() => {
          if (props.currentPage !== 1) {
            props.getPage(1);
          }
        }}
        variant="dominoGhostPaginator"
        isActive={isCurrentPage(1)}
      >
        1
      </Button>
    );
  }

  function getCurrentState() {
    if (props.currentPage < 5) {
      return (
        <Stack spacing={"4px"} align="center" direction="row">
          {getFirstComponent()}
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== 2) {
                props.getPage(2);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(2)}
            data-pw="paginator-page-2"
          >
            2
          </Button>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== 3) {
                props.getPage(3);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(3)}
            data-pw="paginator-page-3"
          >
            3
          </Button>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== 4) {
                props.getPage(4);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(4)}
            data-pw="paginator-page-4"
          >
            4
          </Button>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== 5) {
                props.getPage(5);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(5)}
            data-pw="paginator-page-5"
          >
            5
          </Button>
          <Text paddingTop={"1.5px"}>...</Text>
          {getLastComponent()}
        </Stack>
      );
    }

    if (props.currentPage >= 5 && props.currentPage + 4 <= props.totalPages) {
      return (
        <Stack spacing={"4px"} align="center" direction="row">
          {getFirstComponent()}
          <Text paddingTop={"1.5px"}>...</Text>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== props.currentPage - 1) {
                props.getPage(props.currentPage - 1);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(props.currentPage - 1)}
            data-pw="paginator-page-before"
          >
            {props.currentPage - 1}
          </Button>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(props.currentPage)}
            data-pw="paginator-current-page"
          >
            {props.currentPage}
          </Button>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== props.currentPage + 1) {
                props.getPage(props.currentPage + 1);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(props.currentPage + 1)}
            data-pw="paginator-page-after"
          >
            {props.currentPage + 1}
          </Button>
          <Text paddingTop={"1.5px"}>...</Text>
          {getLastComponent()}
        </Stack>
      );
    }

    if (props.currentPage + 5 > props.totalPages) {
      return (
        <Stack spacing={"4px"} align="center" direction="row">
          {getFirstComponent()}
          <Text paddingTop={"1.5px"}>...</Text>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== props.totalPages - 4) {
                props.getPage(props.totalPages - 4);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(props.totalPages - 4)}
          >
            {props.totalPages - 4}
          </Button>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== props.totalPages - 3) {
                props.getPage(props.totalPages - 3);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(props.totalPages - 3)}
          >
            {props.totalPages - 3}
          </Button>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== props.totalPages - 2) {
                props.getPage(props.totalPages - 2);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(props.totalPages - 2)}
          >
            {props.totalPages - 2}
          </Button>
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            onClick={() => {
              if (props.currentPage !== props.totalPages - 1) {
                props.getPage(props.totalPages - 1);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(props.totalPages - 1)}
          >
            {props.totalPages - 1}
          </Button>
          {getLastComponent()}
        </Stack>
      );
    }
  }

  function getStateOfPaginator() {
    if (props.totalPages <= 7 && props.totalPages >= 0) {
      const numbers = Array.from({ length: props.totalPages }, (_, i) => i + 1);
      return numbers.map(number => {
        return (
          <Button
            isDisabled={props.isFirstLoad}
            size={"2px"}
            key={number}
            onClick={() => {
              if (props.currentPage !== number) {
                props.getPage(number);
              }
            }}
            variant="dominoGhostPaginator"
            isActive={isCurrentPage(number)}
            data-pw={`paginator-page-${number}`}
          >
            {number}
          </Button>
        );
      });
    }

    if (props.totalPages >= 8) {
      return <div>{getCurrentState()}</div>;
    }

    return <div></div>;
  }

  return (
    <Flex className={s.paginatorNumber} gap="4px">
      {getStateOfPaginator()}
    </Flex>
  );
};
