import { Box, Flex, Skeleton } from "@chakra-ui/react";
import EditContactItem from "../EditContactItem/EditContactItem";
import { ContactVariableListModel, ContactVariableModel } from "../../../ContactModel";
import { Dispatch, SetStateAction } from "react";
import { CustomVariableType } from "../../../../../common/AppEnums";
import { useTranslation } from "react-i18next";
import s from "./EditContactVariables.module.scss";

interface Props {
  contactVariablesList: ContactVariableListModel;
  getValueInput: (contactVar: ContactVariableModel) => JSX.Element;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleOnBlurDescription: (contactVar: ContactVariableModel, variablesDescription: Record<string, unknown> | undefined) => void;
  setVariablesDescription: Dispatch<SetStateAction<Record<string, unknown> | undefined>>;
  variableDeleteConfirm: (variable: ContactVariableModel) => void;
  variablesDescription: Record<string, unknown> | undefined;
  executeMobileModal?: (type: CustomVariableType, contactVar: ContactVariableModel) => void;
}

export const EditContactVariables = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "contact" });

  return (
    <>
      {props.contactVariablesList.items ? (
        <Box>
          <>
            {props.contactVariablesList?.items.length > 0 ? (
              props.contactVariablesList?.items?.map(contactVar => (
                <EditContactItem
                  contactVar={contactVar}
                  getValueInput={props.getValueInput}
                  handleKeyPress={props.handleKeyPress}
                  handleOnBlurDescription={props.handleOnBlurDescription}
                  setVariablesDescription={props.setVariablesDescription}
                  variableDeleteConfirm={props.variableDeleteConfirm}
                  variablesDescription={props.variablesDescription}
                  key={contactVar.id}
                  executeMobileModal={props.executeMobileModal}
                />
              ))
            ) : (
              <Box p={"0 24px 24px 24px"} color={"darkGrey"} textAlign={"center"}>
                {t("No Contact Fields yet")}
              </Box>
            )}
          </>
        </Box>
      ) : (
        <Flex flexDirection="column" gap="4px" pb="4px">
          {Array(10)
            .fill(1)
            .map((el, ind) => (
              <Box p="0 24px" key={ind}>
                <Skeleton className={s.skeleton} speed={0.5} startColor="line" endColor="bgLight" />
              </Box>
            ))}
        </Flex>
      )}
    </>
  );
};
