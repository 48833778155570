import { Button, Menu, MenuButton, MenuItem, MenuList, Icon, Flex, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { v1 as uuidv1 } from "uuid";
import { ReactComponent as GlobeIcon } from "../../../../assets/icons/globeIcon.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/checkIcon.svg";
import { LanguageCodeEnum, LanguageEnum } from "../../../../common/user/UserModel";
import GTM from "../../../../common/ga/GAEventTracker";
import { useAppDispatch } from "../../../../common/state/store";
import { setLanguageCompleted } from "../../../../common/user/UserSlice";
import s from "./LanguageMenuContainer.module.scss";

export const LanguageMenuContainer = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const trackEvent = GTM("Signin");

  const languageList: { language: string; abbreviation: LanguageCodeEnum }[] = [
    { language: "English", abbreviation: LanguageCodeEnum.en },
    { language: "Русский", abbreviation: LanguageCodeEnum.ru },
  ];

  const handleMenuButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    trackEvent("SigninLanguageChoose");
  };

  const handleLanguageChange = (abbreviation: LanguageCodeEnum) => {
    i18n.changeLanguage(abbreviation);
    trackEvent("SigninLanguageClick");
    dispatch(setLanguageCompleted(abbreviation));
  };

  return (
    <Menu autoSelect={false} size="max" variant="dominoDotsMenu" gutter={4}>
      <MenuButton
        as={Button}
        variant="langButtonTheme"
        leftIcon={<Icon as={GlobeIcon} boxSize="18px" />}
        onClick={handleMenuButtonClick}
      >
        {LanguageEnum[i18n.resolvedLanguage as keyof typeof LanguageEnum]}
      </MenuButton>
      <MenuList>
        {languageList.map(item => (
          <MenuItem key={uuidv1()} className={s.langMenuItem} onClick={() => handleLanguageChange(item.abbreviation)}>
            <Flex className={s.checkidLang}>
              <Box>{item.language}</Box>
              {i18n.resolvedLanguage === item.abbreviation && <Icon as={CheckIcon} boxSize="20px" />}
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
