import s from "../../UserProfile.module.scss";
import { Flex, Text, Box } from "@chakra-ui/react";
import { UserState } from "../../../../common/user/UserState";
import { BotListModel } from "../../../dashboard/DashboardModel";
import { UserProfilePushItem } from "./components/UserProfilePushItem";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectOrganizationList, setUserProfileNotifications } from "../../UserProfileSlice";
import { NotificationBotsModel, NotificationSettingsModel, OrganisationProfileModel } from "../../UserProfileModel";
import { UserProfilePushTitle } from "./components/UserProfilePushTitle";
import { OverflowEllipsesText } from "../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import { useTranslation } from "react-i18next";
import { UserNotificationsProvider } from "../../../../common/user/UserModel";

interface Props {
  changeDominoPush: (notifications: NotificationSettingsModel[]) => void;
  userProfile: UserState;
  bots: BotListModel | undefined;
  telegramBots: NotificationBotsModel[] | undefined;
  getPage: (page: number) => void;
  notifications?: NotificationSettingsModel[];
  currentOrganization: string;
}

export const UserProfilePushNotifications = (props: Props) => {
  const organizationList: OrganisationProfileModel[] | undefined = useAppSelector(selectOrganizationList);
  const { t } = useTranslation("translation", { keyPrefix: "userProfile" });
  const dispatch = useAppDispatch();

  function getNotification(id: string) {
    return props.notifications?.find(notification => notification.organizationId === id);
  }

  function setBot(notification?: NotificationSettingsModel) {
    const pushes = props.notifications?.map(existingNotification => {
      if (existingNotification.organizationId === notification?.organizationId) {
        return notification;
      } else {
        return existingNotification;
      }
    });
    if (pushes) {
      props.changeDominoPush(pushes as NotificationSettingsModel[]);
    }
  }

  function setNewBot(notification: NotificationSettingsModel) {
    let pushes: NotificationSettingsModel[];
    if (props.notifications) {
      pushes = [...props.notifications, notification];
    } else {
      pushes = [notification];
    }
    props.changeDominoPush(pushes as NotificationSettingsModel[]);
  }

  function disableBot(disabledNotification: NotificationSettingsModel) {
    let pushes = props.notifications?.filter(notification => notification.organizationId !== disabledNotification.organizationId);
    if (pushes) {
      pushes = [...pushes, disabledNotification];
      props.changeDominoPush(pushes as NotificationSettingsModel[]);
    }
  }
  //do not remove
  const disableAllBots = () => {
    if (props.notifications) {
      const notifications = props.notifications.map(notification => {
        return {
          ...notification,
          provider: UserNotificationsProvider.none,
          customBotId: "none",
        };
      });
      dispatch(
        setUserProfileNotifications({
          notifications: notifications,
          telegramBots: props.telegramBots ?? [],
        }),
      );
    }
    return;
  };

  return (
    <Box className={s.userProfilePushWrp}>
      <UserProfilePushTitle disableAllBots={disableAllBots} />
      {organizationList?.map(organization => {
        return (
          <Flex className={s.push} key={organization.id}>
            <Box className={s.organizationNameWrp}>
              <OverflowEllipsesText
                text={organization.name}
                content={
                  <Text className={s.organizationName} pr={{ base: "0px", sm: "30px" }}>
                    {organization.name}
                  </Text>
                }
              />
            </Box>
            <Flex className={s.userProfilePushSelectWrp}>
              {props.telegramBots && props.telegramBots.length > 0 ? (
                <Text className={s.selectText}>{t("received from")}</Text>
              ) : (
                <></>
              )}
              <UserProfilePushItem
                userProfile={props.userProfile}
                bots={props.bots}
                telegramBots={props.telegramBots}
                getPage={props.getPage}
                notification={getNotification(organization.id)}
                setBot={setBot}
                setNewBot={setNewBot}
                disableBot={disableBot}
                organizationId={organization.id}
              />
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};
