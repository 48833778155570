import { CustomVariableType } from "../../../../common/AppEnums";

export const getPlaceholder = (type: CustomVariableType) => {
  if (type === CustomVariableType.Array) {
    return "Enter array like 111,two,THREE";
  }
  if (type === CustomVariableType.Number) {
    return "Enter digits";
  }
  return "Enter text";
};

export const numberValidated = (text: string) => {
  const onlyNumbersRegex = /^\d+$/;
  return onlyNumbersRegex.test(text);
};
