import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import s from "./DurationEditor.module.scss";
import { CalendarFlowActionModel, FlowActionModel } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";

interface Props {
  onDataChange: (flowAction: FlowActionModel) => void;
  propsFlowAction: FlowActionModel;
  minutesLimit?: number;
  hoursLimit?: number;
}

export default function DurationEditor({ onDataChange, propsFlowAction, minutesLimit, hoursLimit }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const flowAction = propsFlowAction as CalendarFlowActionModel;

  const maxHours = hoursLimit ? hoursLimit : 24;
  const maxMinutes = minutesLimit ? minutesLimit : 45;
  const hours = [];
  for (let i = 0; i <= maxHours; i++) {
    hours.push(i);
  }
  const minutes = [];
  for (let i = 0; i <= maxMinutes; ) {
    minutes.push(i);
    i = i + 15;
  }

  return (
    <Flex align="center" gap="16px">
      <Flex align="center" gap="8px">
        <Menu variant="dominoDurationMenu" matchWidth>
          <MenuButton className={s.menuButton} as={Button} data-pw="hours-editor">
            {flowAction.gap?.hours !== undefined
              ? flowAction.gap?.hours > 9
                ? flowAction.gap?.hours
                : `0${flowAction.gap?.hours}`
              : `00`}
          </MenuButton>
          <MenuList w="84px" minW="84px" maxH="188px" overflow="auto">
            {hours.map(el => (
              <MenuItem
                onClick={t => {
                  if (el === 0 && flowAction.gap === undefined) {
                    return;
                  }
                  onDataChange({
                    ...flowAction,
                    gap: el === 0 && !flowAction.gap?.minutes ? undefined : { ...flowAction.gap, hours: el },
                  } as CalendarFlowActionModel);
                }}
                key={`duration${el}`}
                data-pw={`duration${el}`}
              >
                {el > 9 ? el : `0${el}`}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Text variant="largeBold">{t("Hours")}</Text>
      </Flex>
      <Flex align="center" gap="8px">
        <Menu variant="dominoDurationMenu">
          <MenuButton className={s.menuButton} as={Button} data-pw="minutes-editor">
            {flowAction.gap?.minutes !== undefined
              ? flowAction.gap?.minutes > 9
                ? flowAction.gap?.minutes
                : `0${flowAction.gap?.minutes}`
              : `00`}
          </MenuButton>
          <MenuList w="84px" minW="84px" maxH="188px" overflow="auto">
            {minutes.map(el => (
              <MenuItem
                onClick={t => {
                  if (el === 0 && flowAction.gap === undefined) {
                    return;
                  }
                  onDataChange({
                    ...flowAction,
                    gap: el === 0 && !flowAction.gap?.hours ? undefined : { ...flowAction.gap, minutes: el },
                  } as CalendarFlowActionModel);
                }}
                key={`duration${el}`}
                data-pw={`duration${el}`}
              >
                {el > 9 ? el : `0${el}`}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Text variant="largeBold">{t("Minutes")}</Text>
      </Flex>
    </Flex>
  );
}
