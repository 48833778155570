import s from "./FlowTriggerPopup.module.scss";
import { ReactComponent as keyIcon } from "../../../../../assets/icons/keyIcon.svg";
import { ReactComponent as messageIcon } from "../../../../../assets/icons/messageIcon.svg";
import { ReactComponent as flashIcon } from "../../../../../assets/icons/flashIcon.svg";
import { ReactComponent as refUrlIcon } from "../../../../../assets/icons/refUrlIcon.svg";
import { ReactComponent as buttonClickIcon } from "../../../../../assets/icons/buttonClickIcon.svg";
import { ReactComponent as RedTrashIcon } from "../../../../../assets/icons/redTrashIcon.svg";
import { ReactComponent as commandIcon } from "../../../../../assets/icons/commandTriggerIcon.svg";
import { ReactComponent as scheduleTriggerIcon } from "../../../../../assets/icons/scheduleTriggerIcon.svg";
import {
  editNode,
  selectCustomVariables,
  selectFlow,
  selectNodeEditorState,
  setNodeEvent,
  setNodes,
  setNodeValidation,
  setTrigger,
  setTriggers,
  validateTrigger,
} from "../../../FlowBuilderSlice";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  KeywordMatchType,
  keywordMatchTypeName,
  NodeValidation,
  ScheduleTriggerTypeDiscriminator,
  TriggerModel,
  TriggerNodeModel,
  TriggerTypeDiscriminator,
  TriggerTypeName,
} from "../../../FlowBuilderModel";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { KeywordTriggerPopup } from "../KeywordTriggerPopup/KeywordTriggerPopup";
import { RefUrlEditPopup } from "../RefUrlEditPopup/RefUrlEditPopup";
import { useEffect, useState } from "react";
import { ButtonClickTriggerPopup } from "../ButtonClickTriggerPopup/ButtonClickTriggerPopup";
import { v1 as uuidv1 } from "uuid";
import { useTranslation } from "react-i18next";
import { CustomVariableScope } from "../../../../../common/AppEnums";
import { CommandTriggerPopup } from "../CommandTriggerPopup/CommandTriggerPopup";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { ScheduleTriggerPopup } from "../ScheduleTriggerPopup/ScheduleTriggerPopup";
import { addTranslationPrefix, getVariableType } from "../../../../../common/utils/convertPascalCaseToText";
import { formatDateTimeToView } from "../../../../../common/utils/formatDate";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";

interface Props {
  triggers: TriggerModel[];
  onClose: () => void;
  validation: NodeValidation;
  botUserName: string;
}

export const FlowTriggerPopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const cft = useTranslation("translation", { keyPrefix: "complexFilter.conditionsByType" }).t;
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectFlow);
  const keywordTrigger = props.triggers.find(el => el.typeDiscriminator === TriggerTypeDiscriminator.KeywordMatch);
  const refUrlTrigger = props.triggers.find(el => el.typeDiscriminator === TriggerTypeDiscriminator.RefUrl);
  const buttonClickTrigger = props.triggers.find(el => el.typeDiscriminator === TriggerTypeDiscriminator.ButtonClick);
  const commandTrigger = props.triggers.find(el => el.typeDiscriminator === TriggerTypeDiscriminator.CommandMatch);
  const scheduleTrigger = props.triggers.find(el => el.typeDiscriminator === TriggerTypeDiscriminator.Schedule);
  const nodeEditor = useAppSelector(selectNodeEditorState) as TriggerNodeModel;

  let customVariables = useAppSelector(selectCustomVariables);
  customVariables = customVariables?.filter(el => el.scope !== CustomVariableScope.System);

  const [isButtonTriggerPopup, setIsButtonTriggerPopup] = useState<boolean>(false);
  const [isKeywordTriggerPopup, setIsKeywordTriggerPopup] = useState<boolean>(false);
  const [isCommandTriggerPopup, setIsCommandTriggerPopup] = useState<boolean>(false);
  const [isScheduleTriggerPopup, setIsScheduleTriggerPopup] = useState<boolean>(false);
  const [deleteButton, setDeleteButtonState] = useState<string | undefined>();
  const refUrlPopupState = useDisclosure();
  const refKeywordTriggerPopupState = useDisclosure();
  const refButtonClickTriggerPopupState = useDisclosure();
  const refCommandTriggerPopupState = useDisclosure();
  const refScheduleTriggerPopupState = useDisclosure();
  const locale = localStorage.getItem("i18nextLng") ?? "ru";

  useEffect(() => {
    if (!props.validation.errors.length) {
      refButtonClickTriggerPopupState.onClose();
      refKeywordTriggerPopupState.onClose();
      refCommandTriggerPopupState.onClose();
      refScheduleTriggerPopupState.onClose();
    }
    // eslint-disable-next-line
  }, [props.triggers]);

  useEffect(() => {
    if (keywordTrigger && keywordTrigger.keywords?.find((el, index) => index === 0 && !el.length) !== undefined) {
      refKeywordTriggerPopupState.onOpen();
    }
    if (buttonClickTrigger && !buttonClickTrigger.buttonIds?.length) {
      refButtonClickTriggerPopupState.onOpen();
    }
    if (commandTrigger && !commandTrigger.command?.length) {
      refCommandTriggerPopupState.onOpen();
    }
    if (scheduleTrigger) {
      refScheduleTriggerPopupState.onClose();
    }
    // eslint-disable-next-line
  }, [props.triggers]);

  useEffect(() => {
    if (nodeEditor?.newTriggerType) {
      onAddTriggerType(nodeEditor.newTriggerType);
    }
    // eslint-disable-next-line
  }, [nodeEditor?.newTriggerType]);

  const TriggerTypeIcons = {
    ConversationStartFlowTrigger: flashIcon,
    IncomingMessageFlowTrigger: messageIcon,
    KeywordMatchFlowTrigger: keyIcon,
    TelegramRefUrlFlowTrigger: refUrlIcon,
    ButtonClickTrigger: buttonClickIcon,
    TelegramCommandFlowTrigger: commandIcon,
    SchedulerFlowTrigger: scheduleTriggerIcon,
  };

  if (!flow) {
    return <></>;
  }

  const nodes = flow.nodes;

  const ForceOpenTypes = [
    "KeywordMatchFlowTrigger",
    "ButtonClickTrigger",
    "TelegramCommandFlowTrigger",
    "SchedulerFlowTrigger",
    "TelegramRefUrlFlowTrigger",
  ];

  const onTriggerDelete = (triggerId: string) => {
    const updatedTriggers = props.triggers.filter(el => el.id !== triggerId);
    if (props.triggers.find(el => el.id === triggerId)?.typeDiscriminator === TriggerTypeDiscriminator.RefUrl) {
      const updatedNodes = nodes.map((el, index) => {
        if (!index) {
          return {
            ...el,
            data: [],
          };
        }
        return el;
      });
      dispatch(setNodes(updatedNodes));
    }
    if (updatedTriggers && updatedTriggers.length) {
      dispatch(setTriggers(updatedTriggers));
    }
  };

  const deleteTrigger = (triggerId: string, triggerType: TriggerTypeDiscriminator) => {
    dispatch(
      setNodeEvent({
        eventType: "deleteConfirmationPopup",
        eventData: {
          title: "Trigger",
          itemTitle: TriggerTypeName[triggerType],
          onDelete: () => onTriggerDelete(triggerId),
        },
      }),
    );
  };

  const onSaveTrigger = (trigger: TriggerModel) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { newTriggerType, isTriggerPopupOpen, newVariableId, ...rest } = nodeEditor;

    const newTrigger = trigger.id
      ? trigger
      : {
          ...trigger,
          id: uuidv1(),
        };
    dispatch(
      setTrigger({
        triggers: props.triggers,
        trigger: newTrigger,
      }),
    );

    dispatch(editNode(rest));

    switch (trigger.typeDiscriminator) {
      case TriggerTypeDiscriminator.RefUrl: {
        refUrlPopupState.onClose();
        break;
      }
    }
  };

  const onCancelEdit = (triggerType: TriggerTypeDiscriminator) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { newTriggerType, isTriggerPopupOpen, newVariableId, ...rest } = nodeEditor;
    dispatch(editNode(rest));

    dispatch(setNodeValidation({ errors: [] }));
    switch (triggerType) {
      case TriggerTypeDiscriminator.ButtonClick: {
        refButtonClickTriggerPopupState.onClose();
        break;
      }
      case TriggerTypeDiscriminator.KeywordMatch: {
        refKeywordTriggerPopupState.onClose();
        break;
      }
      case TriggerTypeDiscriminator.RefUrl: {
        refUrlPopupState.onClose();
        break;
      }
      case TriggerTypeDiscriminator.CommandMatch: {
        refCommandTriggerPopupState.onClose();
        break;
      }
      case TriggerTypeDiscriminator.Schedule: {
        refScheduleTriggerPopupState.onClose();
        break;
      }
      default: {
        break;
      }
    }
  };

  const onValidateTrigger = (trigger: TriggerModel) => {
    dispatch(validateTrigger(trigger));
  };

  const onAddTriggerType = (typeDiscriminator: TriggerTypeDiscriminator) => {
    const groupId = Math.floor(Math.random() * 1000);
    switch (typeDiscriminator) {
      case TriggerTypeDiscriminator.ButtonClick: {
        setIsButtonTriggerPopup(true);
        refButtonClickTriggerPopupState.onOpen();
        break;
      }
      case TriggerTypeDiscriminator.KeywordMatch: {
        setIsKeywordTriggerPopup(true);
        refKeywordTriggerPopupState.onOpen();
        break;
      }
      case TriggerTypeDiscriminator.RefUrl: {
        const newTrigger = {
          id: uuidv1(),
          typeDiscriminator,
          isEnabled: true,
          triggerGroupId: groupId,
          ...(typeDiscriminator === TriggerTypeDiscriminator.RefUrl && { triggerRefNumber: generateTriggerRefNumber() }),
        };
        const newTriggers = [...flow.triggers, newTrigger];
        dispatch(setTriggers(newTriggers));
        refUrlPopupState.onOpen();
        break;
      }
      case TriggerTypeDiscriminator.ConversationStart: {
        const newTrigger = {
          id: uuidv1(),
          typeDiscriminator,
          isEnabled: true,
          triggerGroupId: groupId,
        };
        const newTriggers = [...flow.triggers, newTrigger];
        dispatch(setTriggers(newTriggers));
        break;
      }
      case TriggerTypeDiscriminator.CommandMatch: {
        setIsCommandTriggerPopup(true);
        refCommandTriggerPopupState.onOpen();
        break;
      }
      case TriggerTypeDiscriminator.Schedule: {
        setIsScheduleTriggerPopup(true);
        refScheduleTriggerPopupState.onOpen();
        break;
      }
      default: {
        break;
      }
    }
  };

  const openButtonPopup = () => {
    setIsButtonTriggerPopup(true);
    refButtonClickTriggerPopupState.onOpen();
  };

  const openKeywordPopup = () => {
    setIsKeywordTriggerPopup(true);
    refKeywordTriggerPopupState.onOpen();
  };

  const openCommandPopup = () => {
    setIsCommandTriggerPopup(true);
    refCommandTriggerPopupState.onOpen();
  };

  const openSchedulePopup = () => {
    setIsScheduleTriggerPopup(true);
    refScheduleTriggerPopupState.onOpen();
  };

  const generateTriggerRefNumber = () => {
    const randomNumber = Math.floor(Math.random() * (99999999 - 10000000) + 10000000);
    const refNumberPrefix = "w";
    return `${refNumberPrefix}${randomNumber}`;
  };

  const onCloseValidate = () => {
    props.onClose();
  };

  const changeIsEnabled = (id: string) => {
    const updatedTriggers = props.triggers.map(el => {
      if (el.id === id) {
        return {
          ...el,
          isEnabled: !el.isEnabled,
        };
      }
      return el;
    });
    if (updatedTriggers) dispatch(setTriggers(updatedTriggers));
  };

  if (
    nodeEditor?.newTriggerType &&
    !nodeEditor.isTriggerPopupOpen &&
    !ForceOpenTypes.find(el => el === nodeEditor.newTriggerType)
  ) {
    props.onClose();
    return <></>;
  }

  return (
    <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoNewGreen">
      <DrawerContent p="8px">
        <DrawerHeader className={s.header}>
          <Heading fontSize="17px" color="green">
            {t("Starting Step")}
          </Heading>
        </DrawerHeader>
        <DrawerBody px="16px">
          <div className={s.popup}>
            <div className={s.triggerBlock}>
              <Heading size="sm" my="16px" display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                {t("editTriggerPopup.When this happens")}
                <TooltipWithTouch
                  variant="dominoLight"
                  whiteSpace={"pre-wrap"}
                  label={t(
                    "Trigger is an event that starts a flow.  You can set up multiple triggers at the same time - the flow will be started when at least one of the conditions is met",
                  )}
                >
                  <QuestionOutlineIcon ml={"8px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
                </TooltipWithTouch>
              </Heading>
              {props.triggers.map((el, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      if (el.typeDiscriminator === TriggerTypeDiscriminator.KeywordMatch) {
                        openKeywordPopup();
                      }
                      if (el.typeDiscriminator === TriggerTypeDiscriminator.RefUrl) {
                        refUrlPopupState.onOpen();
                      }
                      if (el.typeDiscriminator === TriggerTypeDiscriminator.ButtonClick) {
                        openButtonPopup();
                      }
                      if (el.typeDiscriminator === TriggerTypeDiscriminator.CommandMatch) {
                        openCommandPopup();
                      }
                      if (el.typeDiscriminator === TriggerTypeDiscriminator.Schedule) {
                        openSchedulePopup();
                      }
                    }}
                    onMouseEnter={() => setDeleteButtonState(el.id)}
                    onMouseLeave={() => setDeleteButtonState(undefined)}
                    className={s.triggerItem}
                  >
                    {props.triggers.length > 1 && deleteButton === el.id && (
                      <IconButton
                        aria-label="Delete Trigger"
                        variant="ghost"
                        icon={<RedTrashIcon />}
                        onClick={e => {
                          e.stopPropagation();
                          deleteTrigger(el.id, el.typeDiscriminator);
                        }}
                        size="xs"
                        className={s.deleteIcon}
                        data-pw="delete-trigger"
                      />
                    )}
                    <Icon
                      as={TriggerTypeIcons[el.typeDiscriminator as keyof typeof TriggerTypeIcons]}
                      color="green"
                      boxSize="26px"
                    />
                    <div className={s.eventBlock}>
                      <div className={s.event}>{t(TriggerTypeName[el.typeDiscriminator as keyof typeof TriggerTypeName])}</div>
                      {el.keywords && (
                        <div className={s.wrapper}>
                          {el.keywordMatchType !== KeywordMatchType.Any ? (
                            <div className={s.badge}>
                              {t("Message")} {t(keywordMatchTypeName[el.keywordMatchType as keyof typeof keywordMatchTypeName])}:
                            </div>
                          ) : (
                            <div className={s.badge}>
                              {t("Message")} {t(keywordMatchTypeName[el.keywordMatchType as keyof typeof keywordMatchTypeName])}
                            </div>
                          )}
                          <div className={s.nodeContent}>
                            {el.keywords.map((el, index, array) => {
                              return (
                                <span key={index} className={s.eventTypeMarked} color="midDeepBlue">{`${el}${
                                  array.length - 1 !== index ? ", " : ""
                                }`}</span>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {el.buttonIds && (
                        <div className={s.wrapper}>
                          <div className={s.badge}>{t("Button Payload")}:</div>
                          <div className={s.nodeContent}>
                            {el.buttonIds.map((el, index, array) => {
                              return (
                                <span key={index} className={s.eventTypeMarked} color="midDeepBlue">{`${el}${
                                  array.length - 1 !== index ? ", " : ""
                                }`}</span>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {el.command && (
                        <div className={s.wrapper}>
                          <div className={s.badge}>
                            {t("Command is")}{" "}
                            <span key={index} className={s.eventTypeMarked} color="midDeepBlue">
                              /{el.command}
                            </span>
                          </div>
                        </div>
                      )}
                      {el.scheduler && (
                        <div className={s.wrapper}>
                          <Box mb="20px">
                            {el.conditions?.map(elem => {
                              return (
                                <Box key={index}>
                                  <Flex key={index} flexWrap="wrap">
                                    <Text className={s.highlightedWord}>{elem.conditionCustomVariableName}&nbsp;</Text>
                                    <Text>
                                      {!!elem.condition &&
                                        cft(
                                          addTranslationPrefix(
                                            elem.condition,
                                            getVariableType(customVariables, elem.conditionCustomVariableId || "") || "",
                                          ),
                                        )}
                                      &nbsp;
                                    </Text>
                                  </Flex>
                                  <Text className={s.nodeContent}>
                                    {formatDateTimeToView(
                                      getVariableType(customVariables, elem.conditionCustomVariableId || ""),
                                      elem.value,
                                      locale,
                                    )}
                                  </Text>
                                </Box>
                              );
                            })}
                          </Box>
                          {el.scheduler.value && (
                            <div className={s.badge}>
                              {t("editTriggerPopup.Repeat Interval")}{" "}
                              <span key={index} className={s.eventTypeMarked} color="midDeepBlue">
                                {el.scheduler?.value}{" "}
                                {t(`delayNodeTimeUnits.${el.scheduler.unit}`, { count: parseInt(el.scheduler.value) })}
                              </span>
                            </div>
                          )}
                          <div className={s.badge}>
                            {el.scheduler?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.once ? (
                              <>
                                {t("editTriggerPopup.Scheduled time")}&nbsp;
                                <span className={s.eventTypeMarked} color="midDeepBlue">
                                  {formatDateTimeToView("DateTime", el.scheduler?.scheduledTime ?? "", locale ?? "ru")}
                                </span>
                              </>
                            ) : (
                              <>
                                {t("editTriggerPopup.Start time")}&nbsp;
                                <span className={s.eventTypeMarked} color="midDeepBlue">
                                  {formatDateTimeToView("DateTime", el.scheduler?.startTime ?? "")}
                                </span>
                              </>
                            )}
                          </div>
                          {el.scheduler.endTime && (
                            <div className={s.badge}>
                              {t("editTriggerPopup.End time")}{" "}
                              <span key={index} className={s.eventTypeMarked} color="midDeepBlue">
                                {formatDateTimeToView("DateTime", el.scheduler?.endTime ?? "")}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <label onClick={e => e.stopPropagation()} className={s.switch}>
                      <input
                        onChange={() => {
                          changeIsEnabled(el.id);
                        }}
                        type="checkbox"
                        checked={props.triggers[index].isEnabled}
                      />
                      <span data-pw="trigger-switch" className={`${s.slider} ${s.round}`}></span>
                    </label>
                  </div>
                );
              })}
            </div>
            <Button
              size="sm"
              isDisabled={props.triggers.length === 6}
              onClick={() => {
                dispatch(
                  setNodeEvent({
                    eventType: "addNewTriggerPopup",
                    eventData: {},
                  }),
                );
              }}
              variant="dominoDashedViolet"
              data-pw="add-trigger-button"
            >
              + {t("Add Trigger")}
            </Button>
          </div>

          {refUrlTrigger && (
            <RefUrlEditPopup
              botUserName={props.botUserName}
              validation={props.validation}
              triggerNode={refUrlTrigger}
              nodeEditor={nodeEditor}
              customVariables={customVariables ?? []}
              isOpen={refUrlPopupState.isOpen}
              onSave={onSaveTrigger}
              onCancel={onCancelEdit}
              onValidate={onValidateTrigger}
            />
          )}
          {isKeywordTriggerPopup && (
            <KeywordTriggerPopup
              validation={props.validation}
              triggerNode={keywordTrigger}
              isOpen={refKeywordTriggerPopupState.isOpen}
              onSave={onSaveTrigger}
              onCancel={onCancelEdit}
              onValidate={onValidateTrigger}
              customVariables={customVariables ?? []}
              nodeEditor={nodeEditor}
            />
          )}
          {isButtonTriggerPopup && (
            <ButtonClickTriggerPopup
              customVariables={customVariables ?? []}
              validation={props.validation}
              triggerNode={buttonClickTrigger}
              nodeEditor={nodeEditor}
              isOpen={refButtonClickTriggerPopupState.isOpen}
              onSave={onSaveTrigger}
              onCancel={onCancelEdit}
              onValidate={onValidateTrigger}
            />
          )}
          {isCommandTriggerPopup && (
            <CommandTriggerPopup
              customVariables={customVariables ?? []}
              validation={props.validation}
              triggerNode={commandTrigger}
              nodeEditor={nodeEditor}
              isOpen={refCommandTriggerPopupState.isOpen}
              onSave={onSaveTrigger}
              onCancel={onCancelEdit}
              onValidate={onValidateTrigger}
            />
          )}
          {isScheduleTriggerPopup && (
            <ScheduleTriggerPopup
              validation={props.validation}
              triggerNode={scheduleTrigger}
              isOpen={refScheduleTriggerPopupState.isOpen}
              onSave={onSaveTrigger}
              onCancel={onCancelEdit}
              onValidate={onValidateTrigger}
            />
          )}
        </DrawerBody>
        <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => onCloseValidate()} data-pw="apply-button">
          {t("Apply")}
        </Button>
      </DrawerContent>
    </Drawer>
  );
};
