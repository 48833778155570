import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest, call, put, select, delay } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { getToursApi, skipTourApi } from "./OnboardingToursApi";
import { onboardingSlice, selectTours, setTour } from "./OnboardingToursSlice";
import { OnboardingState, Tour } from "./OnboardingToursState";
import { completeTourApi } from "./OnboardingToursApi";
import { selectUser, getProfileCompleted } from "../../common/user/UserSlice";
import { UserProfileModel } from "../userProfile/UserProfileModel";
import { setNodeCenter } from "../flowBuilder/FlowBuilderSlice";
import { FLOW_BUILDER_TOUR_NAME } from "./toursConstants";
import { BadRequestError } from "../../common/ErrorModel";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import {
  mapCompleteTourDataToTourModel,
  mapSkipTourDataToTourModel,
  mapStartTourDataToTourModel,
  mapToursDataToToursModel,
} from "./OnboardingToursMapper";

export function* onboardingTourSaga() {
  yield takeLatest(getProfileCompleted, getTourSaga);
  yield takeLatest(onboardingSlice.actions.completeTour, completeTourSaga);
  yield takeLatest(onboardingSlice.actions.startTour, startTourSaga);
  yield takeLatest(onboardingSlice.actions.skipTour, skipTourSaga);
  yield takeLatest(onboardingSlice.actions.setTourStep, setTourStepSaga);
}

function* getTourSaga() {
  try {
    yield put(beginScope("getTourSaga"));
    const user: UserProfileModel = yield select(selectUser);
    if (user.username) {
      const tours: OnboardingState = yield call(getToursApi, user.username);

      if (tours.tours) {
        const model = mapToursDataToToursModel(tours.tours);
        yield put(onboardingSlice.actions.setTour(model));
      }
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getTourSaga"));
  }
}

function* startTourSaga(action: PayloadAction<string>) {
  try {
    yield put(beginScope("startTourSaga"));
    const tours: Tour[] = yield select(selectTours);
    const currentTour = tours.find(tour => tour.tourName === action.payload);
    if (
      action.payload === FLOW_BUILDER_TOUR_NAME &&
      ((currentTour?.step === undefined && (currentTour?.isCompleted || currentTour?.isSkipped)) ||
        currentTour?.step === 2 ||
        currentTour?.step === 0)
    ) {
      yield put(setNodeCenter(true));
      yield delay(1000);
    }
    if (tours) {
      const model = mapStartTourDataToTourModel(tours, action.payload);
      yield put(setTour(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("startTourSaga"));
  }
}

function* skipTourSaga(action: PayloadAction<string>) {
  try {
    yield put(beginScope("skipTourSaga"));
    const tours: Tour[] = yield select(selectTours);
    const currentTour = tours.find(tour => tour.tourName === action.payload);

    if (!currentTour?.isSkipped && !currentTour?.isCompleted) {
      yield call(skipTourApi, action.payload);
    }
    if (tours) {
      const model = mapSkipTourDataToTourModel(tours, action.payload);
      yield put(setTour(model));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("skipTourSaga"));
  }
}

function* setTourStepSaga(action: PayloadAction<Tour>) {
  try {
    yield put(beginScope("skipTourSaga"));
    const tours: Tour[] = yield select(selectTours);
    const currentTour = tours.find(tour => tour.tourName === action.payload.tourName);
    if (currentTour) {
      yield put(
        setTour(tours.map(tour => (tour.tourName === action.payload.tourName ? { ...tour, step: action.payload.step } : tour))),
      );
    } else {
      yield put(setTour([...tours, { tourName: action.payload.tourName, step: action.payload.step }]));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("skipTourSaga"));
  }
}

export function* completeTourSaga(action: PayloadAction<string>) {
  try {
    const tourName = action.payload;
    const tours: Tour[] | undefined = yield select(selectTours);
    const currentTour = tours?.find((t: Tour) => t.tourName === tourName);
    if (tours) {
      const model = mapCompleteTourDataToTourModel(tours, action.payload);
      yield put(setTour(model));
    }
    if (currentTour) {
      yield call(completeTourApi, tourName);
    }
  } catch (e) {
    if (e instanceof BadRequestError) {
      return;
    }
    yield handleException(e);
  }
}
