import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import s from "../ComplexFilter.module.scss";
import { ReactComponent as DeleteContainerIcon } from "../../../assets/icons/containerCloseIcon.svg";
import {
  ComplexFilterFieldTypes,
  ComplexFilterItemsModel,
  ConditionsByField,
  FieldParamModel,
  FilterParams,
  FilterParamTypes,
  MenuButtonTypes,
} from "../ComplexFilterModel";
import { FieldToFilterMenu } from "./FieldToFilterMenu/FieldToFilterMenu";
import { ConditionToFilterMenu } from "./ConditionToFilterMenu/ConditionToFilterMenu";
import { ComplexFilterPopover } from "./ComplexFilterPopover/ComplexFilterPopover";
import { CSSProperties, useState } from "react";
import { pascalize } from "../../../common/utils/pascalizeString";
import { useTranslation } from "react-i18next";
import { v1 as uuidv1 } from "uuid";

interface Props {
  isVariablesToCompareWithForbidden?: boolean;
  filterFields: ComplexFilterItemsModel;
  filterParams: FilterParams[];
  allowedTypes?: ComplexFilterFieldTypes[];
  menuStyle?: string;
  wrapperStyle?: CSSProperties;
  onSetFilterParam: (filterParamType: FilterParamTypes, fieldParamValue: FieldParamModel, id: string) => void;
  onDeleteConditionBlock?: (paramsToDelete: FilterParams) => void;
  addCondition?: () => void;
  componentSlice?: string;
}

export const ConditionBox = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "complexFilter",
  });
  const [conditionBlockHoverId, setConditionBlockHoverId] = useState<number | undefined>();

  const isConditionValueNeeded = (conditionValue: string) => {
    return !ConditionsByField.noValueConditions.includes(conditionValue);
  };

  const filterFieldsByAllowedTypes = () => {
    let groups = props.filterFields.groups;
    if (props.allowedTypes) {
      groups = groups.map(el => {
        return {
          ...el,
          items: el.items.filter(e => (e.type ? props.allowedTypes?.includes(e.type) : false)),
        };
      });
    }
    return { groups };
  };

  const filterParamsByType = (filterType: ComplexFilterFieldTypes) => {
    switch (filterType) {
      case ComplexFilterFieldTypes.Text:
        return ConditionsByField.textConditions.map(el => {
          return {
            fieldName: t(`conditionsByType.${el.title}`),
            fieldValue: pascalize(el.title),
          };
        });
      case ComplexFilterFieldTypes.Date:
      case ComplexFilterFieldTypes.DateTime:
        return ConditionsByField.dateTimeDateConditions.map(el => {
          return {
            fieldName: t(`conditionsByType.date.${el.title}`),
            fieldValue: pascalize(el.value ?? el.title),
          };
        });
      case ComplexFilterFieldTypes.Number:
        return ConditionsByField.numberDateConditions.map(el => {
          return {
            fieldName: t(`conditionsByType.${el.title}`),
            fieldValue: pascalize(el.value ?? el.title),
          };
        });
      case ComplexFilterFieldTypes.Boolean:
        return ConditionsByField.booleanConditions.map(el => {
          return {
            fieldName: t(`conditionsByType.${el.title}`),
            fieldValue: pascalize(el.value ?? el.title),
          };
        });
      case ComplexFilterFieldTypes.Array:
        return ConditionsByField.arrayConditions.map(el => {
          return {
            fieldName: t(`conditionsByType.${el.title}`),
            fieldValue: pascalize(el.title),
          };
        });
      default:
        return [];
    }
  };

  const filterFieldsBySourceType = (condition: FilterParams) => {
    const filterFields = props.filterFields.groups.map(el => {
      const isArrayConditionType = condition.field?.type === ComplexFilterFieldTypes.Array;
      const items = el.items.filter(item => {
        if (isArrayConditionType) {
          return item.fieldValue !== condition.field?.value && item.type !== ComplexFilterFieldTypes.Array;
        }
        return item.type === condition.field?.type && item.fieldValue !== condition.field?.value;
      });
      return {
        label: el.label,
        items,
      };
    });
    return { groups: filterFields };
  };

  const isValidFilter = (filter: FilterParams) => {
    if (
      (filter.conditionValue && filter.condition && filter.field) ||
      (filter.field && filter.condition && !isConditionValueNeeded(filter.condition.value))
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Box className={s.conditionBoxWrapper} style={props.wrapperStyle}>
        {props.filterParams.map((el, index, array) => {
          return (
            <Flex
              key={el.id}
              minH="42px"
              overflow="visible"
              flexDir="column"
              alignItems="flex-end"
              onMouseEnter={() => setConditionBlockHoverId(index)}
              onMouseLeave={() => setConditionBlockHoverId(undefined)}
            >
              <Box className={s.deleteButton}>
                {conditionBlockHoverId === index && array.length > 1 && (
                  <IconButton
                    aria-label="deleteContainer"
                    icon={<DeleteContainerIcon style={{ marginBottom: "-15px" }} />}
                    variant="containerCloseButton"
                    boxSize="20px"
                    onClick={() => {
                      if (props.onDeleteConditionBlock) {
                        props.onDeleteConditionBlock(el);
                      }
                    }}
                    data-pw="delete-container"
                  />
                )}
              </Box>

              <Flex flexWrap="wrap" className={s.conditionBox} data-pw="condition-box">
                <Text as="b" className={s.conditionBoxItem}>
                  <FieldToFilterMenu
                    filterFields={filterFieldsByAllowedTypes()}
                    onSetFilterParam={props.onSetFilterParam}
                    filterParamType={FilterParamTypes.field}
                    menuButtonProps={{
                      buttonType: MenuButtonTypes.Underline,
                      title: el.field?.title,
                    }}
                    menuStyle={props.menuStyle}
                    placementCondition="top"
                    id={el.id ?? uuidv1()}
                    defaultIsOpen={false || !el.field}
                    componentSlice={props.componentSlice}
                  />
                </Text>
                <Text as="b">&nbsp;&nbsp;&nbsp;</Text>
                {!!el.field && (
                  <Text as="b" className={s.conditionBoxItem}>
                    <ConditionToFilterMenu
                      filterFields={filterParamsByType(el.field.type ?? ComplexFilterFieldTypes.Text)}
                      onSetFilterParam={props.onSetFilterParam}
                      menuButtonProps={{
                        buttonType: MenuButtonTypes.Underline,
                        title: el.condition?.title ?? "",
                      }}
                      menuStyle={props.menuStyle}
                      id={el.id ?? uuidv1()}
                    />
                  </Text>
                )}
                <Text as="b">&nbsp;&nbsp;&nbsp;</Text>
                {!!el.condition && isConditionValueNeeded(el.condition.value) && (
                  <ComplexFilterPopover
                    isVariablesForbidden={props.isVariablesToCompareWithForbidden}
                    filterFields={filterFieldsBySourceType(el)}
                    menuStyle={props.menuStyle}
                    filterParams={el}
                    id={el.id ?? uuidv1()}
                    onSetFilterParam={props.onSetFilterParam}
                    componentSlice={props.componentSlice}
                  />
                )}
              </Flex>
            </Flex>
          );
        })}
      </Box>
      {props.addCondition && (
        <Button
          w="calc(100% - 15px)"
          isDisabled={!isValidFilter(props.filterParams[props.filterParams.length - 1])}
          mt="40px"
          variant="dominoDashedViolet"
          onClick={props.addCondition}
          data-pw="add-condition-button"
        >
          + {t("Condition")}
        </Button>
      )}
    </>
  );
};
