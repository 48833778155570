import { httpGet, httpPost, httpPostIdentity } from "../../common/BaseApi";
import { ChangeUserPasswordSendData, UserProfileNotificationsData } from "./UserProfileData";

export const getUserProfileApi = () => {
  return httpGet(`/api/user/profile`);
};

export const changePasswordApi = (changePasswordSendData: ChangeUserPasswordSendData) => {
  return httpPostIdentity(`/changePassword`, changePasswordSendData);
};

export const getUserProfileBillingPlan = () => {
  return httpGet(`/api/billingPlan`);
};

export const getUserProfileNotificationSettings = () => {
  return httpGet(`/api/user/profile/notifications`);
};

export const setUserProfileNotificationSettings = (settings: UserProfileNotificationsData) => {
  return httpPost(`/api/user/profile/notifications`, settings);
};
