import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { ContactListHeader } from "../../../contactList/components/ContactListHeader";
import { Box, Flex } from "@chakra-ui/react";
import { ContactListContainer } from "../../../contactList/ContactListContainer";
import { useAppSelector } from "../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";
import { MOBILE_VIEW, TABLET_VIEW } from "../../LayoutHelper/ResolutionConst";
import { useNowWidthView } from "../../LayoutHelper/ResolutionHooks";

export const ContactsPage = () => {
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const nowWidth = useNowWidthView();
  return (
    <>
      <Flex flexDirection={"column"} height="100dvh" maxHeight="100dvh">
        <ProtectedHeaderContainer>
          <ContactListHeader />
        </ProtectedHeaderContainer>
        <Box
          bg="bgLight"
          height={
            nowWidth <= TABLET_VIEW
              ? `calc(100dvh - ${isTrial && nowWidth <= MOBILE_VIEW ? "240px" : "180px"})`
              : "calc(100dvh - 60px)"
          }
        >
          <ContactListContainer />
        </Box>
      </Flex>
    </>
  );
};
