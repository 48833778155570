import { useTranslation } from "react-i18next";
import { FLOW_BUILDER_TOUR_NAME, getFlowBuilderOnboardingSteps } from "./toursConstants";
import { OnboardingToursContainer } from "./OnboardingToursContainer";

export const FlowBuilderOnboardingTour = () => {
  const { t } = useTranslation("translation", { keyPrefix: "onboardingTour" });
  const steps = getFlowBuilderOnboardingSteps(t);

  return (
    <OnboardingToursContainer currentTourName={FLOW_BUILDER_TOUR_NAME} tourLevelNameForGAEvent="OnboardingTwo" steps={steps} />
  );
};
