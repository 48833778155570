import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, Text } from "@chakra-ui/react";

import { v1 as uuidv1 } from "uuid";
import {
  AssignFlowActionModel,
  ConditionFlowActionModel,
  CurlFlowActionModel,
  DelayFlowActionModel,
  FlowActionModel,
  FlowActionType,
  NodeModel,
  NodeType,
  QuestionActionType,
  QuestionFlowActionModel,
  CalendarFlowActionModel,
  SendAudioFlowActionModel,
  SendDocumentFlowActionModel,
  SendPhotoFlowActionModel,
  SendTextFlowActionModel,
  SendVideoFlowActionModel,
  StartFlowActionModel,
  SystemActionFlowActionModel,
  TimeUnits,
  PaymentFlowActionModel,
  ShoppingCartFlowActionModel,
} from "../../FlowBuilderModel";
import s from "./CreationMenuNode.module.scss";
import { Handle, Node, Position, useReactFlow } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { editNode, selectFlow, setEdges, setNodes } from "../../FlowBuilderSlice";
import { useEffect, useState } from "react";
import CreationMenuNodeButton from "./components/CreationMenuNodeButton/CreationMenuNodeButton";
import { mapToNodeModel } from "../../FlowBuilderMapper";
import { useTranslation } from "react-i18next";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { ReplyKeyboardButtonModel, ReplyKeyboardMarkupModel } from "../../../../common/AppButtonsModel";
import { v4 as uuidv4 } from "uuid";
import { ButtonType, ButtonTypeDiscriminator, FlowMarkupTypeDiscriminator } from "../../../../common/AppEnums";

export default function CreationMenuNode() {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const telegramNodes = ["Text", "Question", "Image", "Document", "Video", "Audio"];
  const { setCenter } = useReactFlow();

  const extrasNodes = [
    "HTTP request",
    "Condition",
    "System Action",
    "Delay",
    "Assign",
    "Start Flow",
    "Calendar",
    "Payment",
    "Catalog",
  ];
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectFlow);
  const [defaultIndex, setDefaultIndex] = useState<number | undefined>(undefined);
  const trackEvent = useGAWithCustomParameters("Flow");

  useEffect(() => {
    setTimeout(() => setDefaultIndex(0), 0);
  }, []);

  const addNode = (type: NodeType, typeDiscriminator: FlowActionType) => {
    trackEvent("FlowAddNode", "", { target: "Node" });
    let nodeData: FlowActionModel | null = null;

    if (typeDiscriminator === FlowActionType.SendTextFlowAction) {
      nodeData = {
        text: "",
        typeDiscriminator,
        file: null,
      } as SendTextFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.QuestionFlowAction) {
      nodeData = {
        customVariableIds: [],
        typeDiscriminator,
        retryCount: 3,
        file: null,
        questionActionType: QuestionActionType.Text,
      } as QuestionFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.SendPhotoFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SendPhotoFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.SendDocumentFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SendDocumentFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.CurlFlowAction) {
      nodeData = {
        typeDiscriminator,
        targetCustomVariables: [{ contentPath: "", targetCustomVariableId: null }],
      } as CurlFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.SendVideoFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SendVideoFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.SendAudioFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SendAudioFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.ConditionFlowAction) {
      nodeData = {
        typeDiscriminator,
        conditions: [{}],
      } as ConditionFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.SystemActionFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SystemActionFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.DelayFlowAction) {
      nodeData = {
        typeDiscriminator,
        unit: TimeUnits.Minutes,
        value: "",
      } as DelayFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.AssignFlowAction) {
      nodeData = {
        typeDiscriminator,
        operatorId: null,
        operatorName: null,
        teamId: null,
        teamName: null,
      } as AssignFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.StartSubFlowAction) {
      nodeData = {
        typeDiscriminator,
        flowId: "",
      } as StartFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.SendAppointmentFlowAction) {
      nodeData = {
        typeDiscriminator,
        flowId: "",
      } as CalendarFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.SendPaymentFlowAction) {
      nodeData = {
        typeDiscriminator,
        flowId: "",
        gap: { hours: 0, minutes: 15 },
      } as PaymentFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.ShoppingCartFlowAction) {
      const replyButton: ReplyKeyboardButtonModel = {
        id: uuidv4(),
        label: t("editNodePopup.Name your order button"),
        typeDiscriminator: ButtonTypeDiscriminator.FlowReplyKeyboardButton,
        targetNodeId: "",
        type: ButtonType.CallbackButton,
        ...{ requestOrder: true },
      };

      const replyKeyboardMarkup: ReplyKeyboardMarkupModel = {
        buttons: [[replyButton]],
        typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup,
      };
      nodeData = {
        typeDiscriminator,
        flowId: "",
        replyMarkup: replyKeyboardMarkup,
      } as ShoppingCartFlowActionModel;
    }

    if (nodeData) {
      const newNode: NodeModel = {
        id: uuidv1(),
        type,
        position: { x: 100, y: 100 },
        flowAction: nodeData,
      };
      const openNode: Node[] = [];
      let positions: { x: number; y: number } = { x: 0, y: 0 };

      const updateNodes = flow?.nodes.map(el => {
        if (el.id === "creation_menu") {
          const elem = {
            ...el,
            id: newNode.id,
            data: newNode.flowAction,
            type: newNode.type,
          };
          positions = { x: el.position.x, y: el.position.y };
          openNode.push(elem);
          return elem;
        } else {
          return el;
        }
      });
      const updateEdges = flow?.edges.map(el => {
        if (el.target === "creation_menu") {
          const elem = {
            ...el,
            target: newNode.id,
            updatable: true,
          };
          return elem;
        } else {
          return el;
        }
      });

      if (updateNodes && updateEdges) {
        dispatch(setNodes(updateNodes));
        dispatch(setEdges(updateEdges));
        dispatch(editNode(mapToNodeModel(openNode[0])));
      }
      const averageStartNodeHeight = 400;
      const averageStartNodeWidth = 300;
      const x = positions.x + averageStartNodeWidth / 2;
      const y = positions.y + averageStartNodeHeight / 2;
      const zoom = 0.7;
      setCenter(x, y, { zoom, duration: 1000 });
    }
  };

  return (
    <Accordion index={defaultIndex} onClick={e => e.stopPropagation()} className={s.creationMenu}>
      <AccordionItem border="none">
        <AccordionButton onClick={() => setDefaultIndex(0)} className={s.button} position={"relative"}>
          <Handle isConnectable={false} type="target" style={{ opacity: 0 }} position={Position.Left} id="accord-common-source" />
          <Box as="span" flex="1" textAlign="left">
            <Text variant="medium">Telegram</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p={0}>
          {telegramNodes.map(el => (
            <CreationMenuNodeButton addNode={addNode} key={el} title={el} />
          ))}
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem border="none">
        <AccordionButton onClick={() => setDefaultIndex(1)} className={s.button} borderTop="1px solid #F1F4F9">
          <Box as="span" flex="1" textAlign="left">
            <Text variant="medium">{t("Extras")}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p={0}>
          {extrasNodes.map(el => (
            <CreationMenuNodeButton addNode={addNode} key={el} title={el} />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
