/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { ChangeUserPasswordSendData } from "./UserProfileData";
import {
  LinkedAccountModel,
  NotificationBotsModel,
  NotificationSettingsModel,
  UserProfileModel,
  UserProfileNotificationsModel,
} from "./UserProfileModel";

export interface UserProfileState {
  data?: UserProfileModel;
  notifications?: NotificationSettingsModel[];
  bots?: NotificationBotsModel[];
  //email: string;
  linkedAccounts: LinkedAccountModel[];
}

const initialState: UserProfileState = {
  linkedAccounts: [],
};

export const userProfileSlice = createSlice({
  name: "userProfileSlice",
  initialState,
  reducers: {
    changePassword: (state, action: PayloadAction<ChangeUserPasswordSendData>) => {
      return {
        ...state,
      };
    },
    getUserProfile: state => {
      return {
        ...state,
      };
    },
    getUserProfileSucceed: (state, action: PayloadAction<UserProfileModel>) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    setUserProfile: (state, action: PayloadAction<UserProfileModel>) => {
      return {
        ...state,
      };
    },
    getUserProfileNotifications: state => {
      return {
        ...state,
      };
    },
    getUserProfileNotificationsSucceed: (state, action: PayloadAction<UserProfileNotificationsModel>) => {
      return {
        ...state,
        notifications: action.payload.notifications,
        bots: action.payload.telegramBots,
      };
    },
    setUserProfileNotifications: (state, action: PayloadAction<UserProfileNotificationsModel>) => {
      return {
        ...state,
      };
    },
    // setLinkedAccountCompleted: (state, action: PayloadAction<LinkedAccountModel>) => {
    //   return {
    //     ...state.bots,
    //     linkedAccounts: action.payload,
    //   };
    // },
  },
});

export const {
  changePassword,
  getUserProfileNotifications,
  setUserProfileNotifications,
  setUserProfile,
  getUserProfile,
  //setLinkedAccountCompleted,
} = userProfileSlice.actions;
export const selectNotificationSettingsInUserProfile = (state: RootState) => state.app.userProfileState.notifications;
export const selectNotificationBotsInUserProfile = (state: RootState) => state.app.userProfileState.bots;
export const selectOrganizationList = (state: RootState) => state.app.sidebarState.organisationList;
export const selectCurrentOrganization = (state: RootState) => state.app.organisationState.organisation?.name;
export const selectUserEmail = (state: RootState) => state.app.userProfileState.data?.email;
export const selectUserProfileName = (state: RootState) => state.userState;
export const selectLinkedAccounts = (state: RootState) => state.app.userProfileState.linkedAccounts;
export default userProfileSlice.reducer;
