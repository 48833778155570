import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import s from "../../components/CreateConnectBot/CreateConnectBotContainer.module.scss";

interface InstructionStepProps {
  i18nKey?: string;
  stepNumber: number;
  children: ReactNode;
}

interface TranslationWithStrongWordProps {
  i18nKey: string;
}

export const InstructionStep: React.FC<InstructionStepProps> = ({ i18nKey, stepNumber, children }) => (
  <Flex className={s.instructionStep}>
    <Badge variant="dominoInstructionsNumberList">{stepNumber}</Badge>
    {children}
  </Flex>
);

export const TranslationWithStrongWord: React.FC<TranslationWithStrongWordProps> = ({ i18nKey }) => (
  <Box>
    <Trans i18nKey={i18nKey}>
      <Text as="span" className={s.strongWord} />
    </Trans>
  </Box>
);
