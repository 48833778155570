import { useCallback } from "react";
import { Box, Flex, Tab, TabList, Tabs } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../common/state/store";
import { selectOrganisation } from "./OrganisationSlice";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { selectIsOwner } from "../../common/user/UserSlice";
import { getTariffPlanAccessability } from "../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../common/AppEnums";
import s from "./Organisation.module.scss";
import { CurrentPlanInfo } from "./components/CurrentPlanInfo/CurrentPlanInfo";
import { GenerateLinkButton } from "./components/GenerateLinkButton/GenerateLinkButton";
import { selectTariffPlan } from "../../common/tariffPlan/TariffPlanSlice";

export const OrganisationContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const navigate = useNavigate();
  const location = useLocation();
  const isOwner = useAppSelector(selectIsOwner);
  const organisation = useAppSelector(selectOrganisation);

  const tariffPlans = useAppSelector(selectTariffPlan);

  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.TeamsCount, tariffPlans);
  const { MaxCount, IsUnlimited } = accessability;

  const getIndex = useCallback(() => {
    if (location.pathname.includes("members")) {
      return 0;
    }
    if (location.pathname.includes("teams")) {
      return 1;
    }
    return 0;
  }, [location.pathname]);

  return (
    <Box className={s.organisationContainer}>
      <Flex className={s.organisationTopFlex}>
        <CurrentPlanInfo />
        {isOwner && <GenerateLinkButton MaxCount={MaxCount} IsUnlimited={IsUnlimited} organisation={organisation} />}
      </Flex>
      <Tabs className={s.tabsContainer} index={getIndex()} isManual>
        <TabList mx="0">
          <Tab pl={0} onClick={() => navigate("/organisation/operators")}>
            {t("Operators")}
          </Tab>
          <Tab onClick={() => navigate("/organisation/teams")}>{t("Teams")}</Tab>
        </TabList>
      </Tabs>
      <Box className={isOwner ? s.boxContainerOwner : s.boxContainerNotOwner}>
        <Outlet />
      </Box>
    </Box>
  );
};
