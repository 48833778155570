/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TelegramAuthData } from "./TelegramAuthData";
import { RootState } from "../state/store";

export interface TelegramAuthState {
  isTelegramAuth: boolean;
}
const initialState = {
  isTelegramAuth: false,
};

export const telegramAuthSlice = createSlice({
  name: "telegramAuthSlice",
  initialState,
  reducers: {
    telegramAuthenticate: (state, action: PayloadAction<{ telegramData: TelegramAuthData; redirectUrlLogin?: string }>) => {
      return {
        ...state,
      };
    },
    telegramLinkedAccountAuthenticate: (state, action: PayloadAction<TelegramAuthData>) => {
      return {
        ...state,
      };
    },
  },
});

//export const selectTelegramAuth = (state: RootState) => state.app.telegramAuthState.isTelegramAuth;

export const { telegramAuthenticate, telegramLinkedAccountAuthenticate } = telegramAuthSlice.actions;

export default telegramAuthSlice.reducer;
