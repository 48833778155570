import ReactGA from "react-ga4";
import { AppSettings } from "../AppSettings";
import TagManager from "react-gtm-module";

declare const appSettings: AppSettings;

export const useGA = (category: string) => {
  const trackEvent = (action: string, label?: string) => {
    if (appSettings.GAIds) {
      ReactGA.event({ category, action, label });
    }

    if (appSettings.GTMId) {
      const tagManagerArgs = {
        dataLayer: {
          event: action,
          eventCategory: category,
          eventAction: action,
          eventLabel: label,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  };
  return trackEvent;
};

export const useGAWithCustomParameters = (category: string) => {
  const trackEvent = (action: string, label?: string, data?: unknown) => {
    if (appSettings.GAIds) {
      if (data != null) {
        const event_params = {
          category,
          label,
          ...data,
        };

        ReactGA.event(action, event_params);
      } else {
        ReactGA.event({ category, action, label });
      }
    }

    if (appSettings.GTMId) {
      if (data != null) {
        const tagManagerArgs = {
          dataLayer: {
            event: action,
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            ...data,
          },
        };

        TagManager.dataLayer(tagManagerArgs);
      } else {
        const tagManagerArgs = {
          dataLayer: {
            event: action,
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
          },
        };

        TagManager.dataLayer(tagManagerArgs);
      }
    }
  };
  return trackEvent;
};

export const setUserId = (userId: string) => {
  if (appSettings.GAIds) {
    ReactGA.set({ userId: userId });
  }

  if (appSettings.GTMId) {
    TagManager.dataLayer({ dataLayer: { userId: userId } });
  }
};

export const setUserProperty = (data: unknown) => {
  if (appSettings.GAIds) {
    ReactGA.gtag("set", "user_properties", data);
  }
  if (appSettings.GTMId) {
    TagManager.dataLayer({ dataLayer: data as object });
  }
};

export default useGA;
