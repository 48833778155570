import dayjs from "dayjs";
import { ComplexFilterFieldTypes, ComplexFilterGroupTypes, FilterParams } from "./ComplexFilterModel";
import { getTitleByFieldValue } from "./CreateFilterFields";
import { MemberModel } from "../broadcast/BroadcastModel";

export const mapFilterArrayToQueryString = (filterArray: FilterParams[]) => {
  let queryString = "";
  filterArray.forEach((el, index, array) => {
    const values = getValuesByType(el);
    const isLastElement = index === array.length - 1;
    const isCustomVariableGroup = el.field?.groupType === ComplexFilterGroupTypes.CustomVariables;
    const queryParam = `filters[${index}].field=${isCustomVariableGroup ? "CustomVariables->" : ""}${
      values?.field
    }&filters[${index}].condition=${values?.condition}&filters[${index}].valueType=${el.field?.type}${
      values?.value ? `&filters[${index}].value=${values.value}` : ""
    }${!isLastElement ? "&" : ""}`;
    queryString += queryParam;
  });
  return queryString;
};

export const formatDateTimeValueToData = (value: string | undefined) => {
  if (value) {
    return encodeURIComponent(dayjs(value as string).format("YYYY-MM-DDTHH:mm:ssZZ"));
  }
  return value;
};

const getValuesByType = (param: FilterParams) => {
  switch (param?.field?.type) {
    case ComplexFilterFieldTypes.Text:
    case ComplexFilterFieldTypes.Number:
    case ComplexFilterFieldTypes.Date:
      return {
        field: param.field?.value,
        condition: param.condition?.value,
        value: param.conditionValue?.value,
      };
    case ComplexFilterFieldTypes.DateTime:
      return {
        field: param.field?.value,
        condition: param.condition?.value,
        value: formatDateTimeValueToData(param.conditionValue?.value),
      };
    case ComplexFilterFieldTypes.Boolean:
      const isEqualCondition = ["False", "True"].includes(param.condition?.value ?? "");
      return {
        field: param.field?.value,
        condition: isEqualCondition ? "equal" : param.condition?.value,
        ...(isEqualCondition && { value: param.condition?.value }),
      };

    default:
      break;
  }
};

export const parseRecipientFilterConditionTitle = (member: MemberModel) => {
  const datePrefix = "date.";
  const numberPrefix = "number.";

  if (member.valueType === "Boolean" && member.value) {
    if (member.condition === "HasValue" || member.condition === "HasNoValue") {
      return `${getTitleByFieldValue(member.condition)}`;
    }
    return member.value === "True" ? "is True" : "is False";
  }

  if (member.valueType === "DateTime" || member.valueType === "Date") {
    return `${datePrefix}${getTitleByFieldValue(member.condition)}`;
  }

  if (member.valueType === "Number") {
    return `${numberPrefix}${getTitleByFieldValue(member.condition)}`;
  }

  return `${getTitleByFieldValue(member.condition)}`;
};

export const parseRecipientFilterConditionValue = (member: MemberModel) => {
  if (member.valueType === "Boolean" && member.value.firstCharToUpperCase()) {
    if (member.condition === "HasValue" || member.condition === "HasNoValue") {
      return member?.condition;
    }
    return member.value;
  }
  return member?.condition;
};
