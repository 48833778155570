import { call, put, takeLatest } from "redux-saga/effects";
import { GetTokenAuthCodeRequestModel, GetTokenPasswordRequestModel } from "./LoginModel";
import { loginSlice } from "./LoginSlice";
import * as loginApi from "./LoginApi";
import { PayloadAction } from "@reduxjs/toolkit";
import { setToken } from "../../common/auth/AuthRepo";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { GetTokenResponseData } from "./LoginData";
import { userSlice } from "../../common/user/UserSlice";
import { routerSlice } from "../../common/router/RouterSlice";
import { handleException } from "../../common/SagaHelper";
import GTM, { setUserProperty, setUserId } from "../../common/ga/GAEventTracker";
import { BadRequestError } from "../../common/ErrorModel";
import { AppSettings } from "../../common/AppSettings";

declare const appSettings: AppSettings;

export function* loginSaga() {
  yield takeLatest(loginSlice.actions.getTokenRequested, getTokenByPassword);
  yield takeLatest(loginSlice.actions.getTokenByAuthCodeRequested, getTokenByAuthCode);
}

function* getTokenByPassword(
  action: PayloadAction<{
    tokenModel: GetTokenPasswordRequestModel;
    redirectUrl?: string;
  }>,
) {
  try {
    yield put(beginScope("signIn/signUp"));
    const data: GetTokenResponseData = yield call(loginApi.getToken, action.payload.tokenModel);
    const redirectUrlLogin = action.payload.redirectUrl;
    yield handleTokenResponse(data, redirectUrlLogin, action.payload.tokenModel.identity);
  } catch (e: unknown) {
    if (e instanceof BadRequestError) {
      if (e.message !== "Invalid identity or password") {
        const redirectUrl = action.payload.redirectUrl;
        const redirecturlToTab = `/auth?redirect_url=${redirectUrl}`;
        const authUrl = redirectUrl ? redirecturlToTab : "/auth";
        yield put(routerSlice.actions.redirect(authUrl));
      }
    }
    yield handleException(e);
  } finally {
    yield put(completeScope("signIn/signUp"));
  }
}

function* getTokenByAuthCode(action: PayloadAction<GetTokenAuthCodeRequestModel>) {
  try {
    yield put(beginScope("signIn/signUp"));
    const data: GetTokenResponseData = yield call(loginApi.getTokenByAuthCode, action.payload);
    yield handleTokenResponse(data);
  } catch (e: unknown) {
    if (e instanceof BadRequestError) {
      if (e.message !== "Invalid username or password") {
        yield put(routerSlice.actions.redirect("/auth"));
      }
    }
    yield handleException(e);
  } finally {
    yield put(completeScope("signIn/signUp"));
  }
}

function* handleTokenResponse(data: GetTokenResponseData, redirectUrlLogin?: string, identity?: string) {
  const sessionOrgIds = sessionStorage.getItem("usersOrganizationId");
  const availableOrganizations = JSON.parse(atob(data.token.split(".")[1])).AvailableOrganizations;
  let newOrganisationId: { [key: string]: string } = {};
  const organizationId = JSON.parse(atob(data.token.split(".")[1])).Organization;
  try {
    const parseSessionOrg = JSON.parse(sessionOrgIds || "");
    newOrganisationId = { ...parseSessionOrg };
  } catch {}

  if (identity) {
    sessionStorage.setItem("username", identity);
  }

  if (sessionOrgIds) {
    const orgIdsInLocalStorage = Object.values(newOrganisationId);
    const includedOrganizations = orgIdsInLocalStorage.filter(el => !availableOrganizations.includes(el));

    newOrganisationId = Object.keys(newOrganisationId)
      .filter(objKey => !includedOrganizations.includes(newOrganisationId[objKey]))
      .reduce((newObj: { [key: string]: string }, key) => {
        newObj[key] = newOrganisationId[key];
        return newObj;
      }, {});
    localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
    sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
  } else if (identity) {
    newOrganisationId[identity] = organizationId;
    localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
    sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
  }

  if (data?.errorMessage) {
    yield put(loginSlice.actions.getTokenFailed(data.errorMessage));
    return;
  }

  const token = {
    accessToken: data.token,
    refresh: data.refreshToken,
    isExpired: false,
    expiration: data.expiration,
  };
  setToken(token);

  const tokenClaims = JSON.parse(atob(token.accessToken.split(".")[1]));
  const userId = tokenClaims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

  setUserId(userId);
  setUserProperty({ organizationId: organizationId });

  const trackEvent = GTM("Signin");
  trackEvent("SigninCompleted");

  let currentUrl = sessionStorage.getItem("currentUrl");

  const redirectUrl = () => {
    if (currentUrl === "/") {
      currentUrl = "/bots";
    }
  };
  redirectUrl();
  yield put(userSlice.actions.getProfile());
  if (redirectUrlLogin && redirectUrlLogin.startsWith(appSettings.billingPortalUrl)) {
    window.location.replace(redirectUrlLogin);
  }
  yield put(routerSlice.actions.redirect(currentUrl ? currentUrl : "/bots"));
}
