import { ChevronLeftIcon, ChevronRightIcon, CloseIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  CustomVariablesModel,
  KeywordMatchType,
  keywordMatchTypeName,
  NodeValidation,
  TriggerModel,
  TriggerNodeModel,
  TriggerTypeDiscriminator,
} from "../../../FlowBuilderModel";
import s from "./KeywordTriggerPopup.module.scss";
import { useTranslation } from "react-i18next";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";
import { AddKeywordButton } from "./components/AddKeywordButton";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";
import { createCustomVariable } from "../../../FlowBuilderSlice";
import { useAppDispatch } from "../../../../../common/state/store";

type Props = {
  isOpen?: boolean;
  triggerNode?: TriggerModel;
  validation: NodeValidation;
  onCancel: (triggerType: TriggerTypeDiscriminator) => void;
  onSave: (trigger: TriggerModel) => void;
  onValidate: (trigger: TriggerModel) => void;
  customVariables: CustomVariablesModel[];
  nodeEditor: TriggerNodeModel;
};

const defaultTriggerNode: TriggerModel = {
  id: "",
  typeDiscriminator: TriggerTypeDiscriminator.KeywordMatch,
  isEnabled: true,
  triggerGroupId: Math.floor(Math.random() * 1000),
  keywords: [],
  keywordMatchType: KeywordMatchType.Is,
  matchScore: 50,
};

export const KeywordTriggerPopup = ({
  isOpen,
  triggerNode = defaultTriggerNode,
  validation,
  onCancel,
  onSave,
  onValidate,
  customVariables,
  nodeEditor,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const vt = useTranslation("validation").t;

  const dispatch = useAppDispatch();

  const [sliderValue, setSliderValue] = useState(triggerNode.matchScore);
  const [sliderThumbActive, setSliderThumbActive] = useState(false);

  const [keywords, setKeywords] = useState<{ isActive: boolean; value: string }[]>([]);
  const [keywordTriggerInfo, setKeywordTriggerInfo] = useState<TriggerModel>(triggerNode);
  const [chosenVariable, setChosenVariable] = useState<CustomVariablesModel | undefined>();

  const anyMessageCondition = keywordTriggerInfo.keywordMatchType !== KeywordMatchType.Any;

  useEffect(() => {
    if (isOpen) {
      const mappedKeywords = keywords?.map(el => {
        return { isActive: false, value: el.value };
      });
      const triggerNodeMappedKeywords = triggerNode.keywords?.map(el => {
        return { isActive: false, value: el };
      });
      if (mappedKeywords?.length) {
        setKeywords(mappedKeywords);
      } else if (triggerNodeMappedKeywords?.length) {
        setKeywords(triggerNodeMappedKeywords);
      }
      setSliderValue(triggerNode.matchScore);

      const customVariable = customVariables.find(el => triggerNode.targetCustomVariableId === el?.id);
      const newCustomVariable = customVariables.find(el => nodeEditor.newVariableId === el?.id);
      if (newCustomVariable) {
        setChosenVariable(newCustomVariable);
        return;
      }
      if (customVariable) {
        setChosenVariable(customVariable);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, triggerNode, nodeEditor]);

  const setKeyword = (keyword: string, index: number, isActive: boolean) => {
    let updatedKeywords = keywords.map((el, i) => {
      if (i === index) {
        return {
          el,
          isActive,
          value: keyword,
        };
      }
      return el;
    });
    updatedKeywords = updatedKeywords.filter(el => el.isActive || (!el.isActive && el.value.trim().length));

    setKeywords(updatedKeywords);
    validate(updatedKeywords.map(e => e.value));
  };

  const addKeyword = () => {
    const updatedValues = [...keywords, { isActive: true, value: "" }];
    setKeywords(updatedValues);
  };

  const onKeywordDelete = (index: number) => {
    const updatedKeywords = keywords.filter((_, i) => index !== i);
    setKeywords(updatedKeywords);
    validate(updatedKeywords.map(el => el.value));
  };

  const onCreate = () => {
    const mappedKeywords = anyMessageCondition ? keywords.map(e => e.value) : [];
    const matchScore = keywordTriggerInfo.keywordMatchType === KeywordMatchType.Like ? sliderValue : 100;

    const newTrigger: TriggerModel = {
      ...keywordTriggerInfo,
      keywords: mappedKeywords,
      matchScore,
      targetCustomVariableId: chosenVariable?.id,
    };

    onSave(newTrigger);
  };

  const validate = (values: string[]) => {
    const newTrigger: TriggerModel = {
      ...triggerNode,
      keywords: values,
    };
    onValidate(newTrigger);
  };

  const selectVariable = (variable: CustomVariableModel) => {
    setChosenVariable(variable);
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  return (
    <Drawer
      placement="right"
      size={"xs"}
      isOpen={isOpen ?? false}
      onClose={() => onCancel(triggerNode.typeDiscriminator)}
      isFullHeight={false}
      blockScrollOnMount={false}
    >
      <DrawerContent p="8px">
        <DrawerHeader className={s.header}>
          <Heading fontSize="17px" color="defaultGreen">
            {t("editTriggerPopup.Keyword")}
          </Heading>
        </DrawerHeader>
        <DrawerBody px={"14px"}>
          <Flex flexDirection={"column"}>
            <Flex fontSize={"16px"} mt={"18px"} justifyContent={"space-between"} alignItems={"center"}>
              <Flex alignItems={"center"} fontSize={"15px"}>
                <div>{t("editTriggerPopup.If")}&nbsp;</div>
                <Menu variant="dominoDotsMenu" gutter={0} offset={[0, -2]}>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        as={Text}
                        className={s.dropdownText}
                        mt={"2px"}
                        color={isOpen ? "mainPurple" : "black"}
                        data-pw="keyword-match-type"
                      >
                        {t("editTriggerPopup.messageInput")}&nbsp;
                        {t(
                          keywordMatchTypeName[keywordTriggerInfo.keywordMatchType as keyof typeof keywordMatchTypeName] ??
                            KeywordMatchType.Is,
                        )}
                      </MenuButton>
                      <MenuList>
                        {Object.keys(KeywordMatchType).map((el, i) => {
                          return (
                            <MenuItem
                              onClick={() =>
                                setKeywordTriggerInfo({
                                  ...keywordTriggerInfo,
                                  keywordMatchType: KeywordMatchType[el as keyof typeof KeywordMatchType],
                                })
                              }
                              key={i}
                              data-pw={keywordMatchTypeName[el as keyof typeof keywordMatchTypeName]}
                            >
                              {" "}
                              {t("editTriggerPopup.message")} {t(keywordMatchTypeName[el as keyof typeof keywordMatchTypeName])}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Flex>
              <TooltipWithTouch
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t("editTriggerPopup.Set a keyword. If contact's message matches the keyword, flow will be started.")}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </TooltipWithTouch>
            </Flex>
            <Flex w="100%" alignItems="center" flexWrap="wrap" mt={"16px"}>
              {keywords.length && anyMessageCondition ? (
                keywords.map((el, index, array) => {
                  return (
                    <>
                      <Button
                        position={"relative"}
                        className={`${s.button} ${el.isActive ? s.buttonActive : ""}`}
                        onClick={() => setKeyword(el.value, index, true)}
                        data-pw="keywordBut"
                      >
                        <InputGroup>
                          {el.isActive ? (
                            <InputGroup w="100%">
                              <Input
                                onChange={e => setKeyword(e.target.value, index, true)}
                                onBlur={e => setKeyword(e.target.value, index, false)}
                                value={el.value}
                                cursor={"pointer"}
                                autoFocus
                                maxLength={100}
                                className={s.input}
                                placeholder={`${t("editTriggerPopup.Type Text")}`}
                                data-pw="keywordInp"
                              />
                            </InputGroup>
                          ) : (
                            <Flex alignItems={"center"} maxW={"100%"}>
                              <Box
                                style={{
                                  fontSize: "15px",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                {el.value}
                              </Box>
                            </Flex>
                          )}
                          <InputRightElement className={s.closeIconWrp}>
                            <CloseIcon
                              className={s.closeIcon}
                              onMouseDown={e => e.preventDefault()}
                              onClick={e => {
                                e.stopPropagation();
                                onKeywordDelete(index);
                              }}
                              data-pw="close-icon"
                            />
                          </InputRightElement>
                        </InputGroup>
                      </Button>
                      {index + 1 === array.length && (
                        <Button className={s.addKeywordButtonWrp} onClick={() => addKeyword()} data-pw="add-keyword">
                          <Box className={s.addKeywordButton}>+ {t("editTriggerPopup.Keyword")}</Box>
                        </Button>
                      )}
                    </>
                  );
                })
              ) : (
                <AddKeywordButton addKeyword={addKeyword} keywordMatchType={keywordTriggerInfo.keywordMatchType} />
              )}
            </Flex>
            <Box mt={"20px"}>
              <h4>{t("editTriggerPopup.Save Payload to a Custom Field")}</h4>
              <CustomVariableContextMenu
                clearVariable={() => setChosenVariable(undefined)}
                mt="12px"
                type={CustomVariableContextMenuType.Dropdown}
                selectCustomVariable={selectVariable}
                addVariable={onCreateVariable()}
                variables={customVariables?.filter(el => el.type !== CustomVariableType.Order) || []}
                chosenVariable={chosenVariable}
                excludeVariableType={CustomVariableType.Order}
              />
            </Box>
            {keywordTriggerInfo.keywordMatchType === KeywordMatchType.Like && (
              <Flex mt={"10%"} flexDirection={"column"}>
                <Heading fontSize={"15px"}>{t("editTriggerPopup.String Match Percent")}</Heading>
                <Slider
                  ml={"7%"}
                  width={"85%"}
                  mt={"10%"}
                  defaultValue={sliderValue}
                  min={10}
                  max={100}
                  step={10}
                  onChange={val => setSliderValue(val)}
                >
                  <SliderMark mt={"10px"} fontSize={"13px"} value={0}>
                    10%
                  </SliderMark>
                  <SliderMark mt={"10px"} fontSize={"13px"} value={100}>
                    100%
                  </SliderMark>
                  <SliderTrack bg="#D9D9D9">
                    <Box position="relative" height="4px" />
                    <SliderFilledTrack bg="#0084FF" />
                  </SliderTrack>
                  <SliderThumb
                    style={{
                      background: "#F2F5F7",
                      border: "1px solid #D9D9D9",
                      borderRadius: "8px",
                      width: "60px",
                      height: "22px",
                      fontSize: "15px",
                      zIndex: 0,
                    }}
                    _active={{
                      backgroundColor: "white !important",
                      borderColor: "#0084FF !important",
                    }}
                    onMouseEnter={() => setSliderThumbActive(true)}
                    onMouseLeave={() => setSliderThumbActive(false)}
                  >
                    {sliderThumbActive && <ChevronLeftIcon />}
                    {`${sliderValue}%`}
                    {sliderThumbActive && <ChevronRightIcon />}
                  </SliderThumb>
                </Slider>
              </Flex>
            )}
          </Flex>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={"100%"} flexDirection={"column"}>
            {anyMessageCondition && validation.errors.find(el => el.field === "keywordTrigger") && (
              <Box mb={"24px"}>
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>
                    {vt(validation.errors.find(el => el.field === "keywordTrigger")?.message ?? "")}
                  </AlertDescription>
                </Alert>
              </Box>
            )}
            <Flex w={"100%"} justifyContent={"space-between"}>
              <Button
                data-pw="cancel-button"
                onClick={() => onCancel(keywordTriggerInfo.typeDiscriminator)}
                size={"sm"}
                variant="dominoOutlineViolet"
              >
                {triggerNode.id ? ct("Discard") : ct("Cancel")}
              </Button>
              <Button data-pw="create-button" onClick={() => onCreate()} size={"sm"} variant="dominoViolet">
                {triggerNode.id ? ct("Apply") : ct("Create")}
              </Button>
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
