import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { AutomationLayout } from "../../../automation/AutomationLayout";
import { AutomationHeaderContainer } from "../../../automation/components/Header/AutomationHeaderContainer";
import { clearAutomationState } from "../../../automation/AutomationSlice";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { useNowWidthView } from "../../LayoutHelper/ResolutionHooks";
import { MOBILE_VIEW, TABLET_VIEW } from "../../LayoutHelper/ResolutionConst";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";

export const AutomationPage = () => {
  const dispatch = useAppDispatch();
  const nowWidth = useNowWidthView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  useEffect(() => {
    return () => {
      dispatch(clearAutomationState());
    };
  }, [dispatch]);

  return (
    <Flex flexDirection="column" height="100dvh">
      <ProtectedHeaderContainer>
        <AutomationHeaderContainer />
      </ProtectedHeaderContainer>
      <Box
        bg="bgLight"
        height={
          nowWidth <= TABLET_VIEW
            ? `calc(100dvh - ${isTrial && nowWidth <= MOBILE_VIEW ? "180px" : "120px"})`
            : "calc(100dvh - 60px)"
        }
      >
        <Loading />
        <AutomationLayout />
      </Box>
    </Flex>
  );
};
