import { Flex, Text, Image } from "@chakra-ui/react";
import s from "../FlowTemplatesModal.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { createImportFlowTemplate } from "../../../../flowBuilder/FlowBuilderSlice";
import { selectCurrentBotId } from "../../../../sidebar/SidebarSlice";

interface Props {
  icon: string;
  label: string;
  description: string;
  id: string;
}

export default function FlowTemplateItem({ icon, label, description, id }: Props) {
  const dispatch = useAppDispatch();
  const selectBotId = useAppSelector(selectCurrentBotId);

  const importFlowTemplate = (flowTemplateId: string) => {
    if (selectBotId) {
      dispatch(createImportFlowTemplate({ botId: selectBotId, flowTemplateId: flowTemplateId }));
    }
  };

  return (
    <Flex data-pw={label} onClick={() => importFlowTemplate(id)} className={s.flowTemplateItem}>
      <Flex className={s.flexIconTemplate}>
        <Image src={icon} className={s.iconTemplate} />
      </Flex>

      <Flex className={s.textBoxItem}>
        <Text variant={"medium"}>{label}</Text>
        <Text noOfLines={3} variant={"small"} className={s.descriptionTemplate}>
          {description}
        </Text>
      </Flex>
    </Flex>
  );
}
