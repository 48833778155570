import {
  Container,
  FormControl,
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  FormLabel,
  Icon,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Text,
} from "@chakra-ui/react";

import s from "../Broadcast/Broadcast.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { ReactComponent as StarPlan } from "../../../../assets/icons/starPlan.svg";

import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { RecipientList } from "./RecipientList";
import { useTranslation } from "react-i18next";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { useSelector } from "react-redux";
import { useAppSelector, RootState } from "../../../../common/state/store";
import { selectContactList } from "./RecipientListSlice";
import { selectCustomVariables } from "../../BroadcastSlice";
import { FilterParams } from "../../../complexFilter/ComplexFilterModel";
import { memo, useState } from "react";

export const RecipientListContainer = memo(function RecipientListContainer() {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const accessibility = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.BroadcastContacts);
  const { MaxCount, IsUnlimited } = accessibility;
  const variables = useAppSelector(selectCustomVariables);
  const [broadcastFilter, setBroadcastFilter] = useState<FilterParams[]>([{}]);

  const contactList = useAppSelector(selectContactList);
  const recipientList = useSelector((state: RootState) => state.app.broadcastState.recipientList);

  return (
    <Container variant="dominoContentBlock">
      <FormControl className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveContactList}`}>
        {!IsUnlimited && contactList?.totalItems && contactList?.totalItems > MaxCount ? (
          <Flex flexDir="column">
            <Box w="max-content">
              <Popover trigger={"hover"}>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Flex className={sp.boxHover}>
                        <Box>
                          <FormLabel>{t("Contacts list")}</FormLabel>
                        </Box>
                        <Box mt="2px">
                          <Icon as={StarPlan} boxSize="20px" ml="-4px" />
                        </Box>
                      </Flex>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                        <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                        <Flex alignItems="center" justifyContent="center" direction="column">
                          <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                            {tp("Advanced feature")}
                          </PopoverHeader>
                          <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                            {tp("Please upgrade your plan to select more recipients")}
                          </PopoverBody>
                          <TariffPlanRedirectButton onClose={onClose} />
                        </Flex>
                      </PopoverContent>
                    </Portal>
                  </>
                )}
              </Popover>
            </Box>
            <Box w="60%">
              <Text fontSize={15} color={"#8592A3"}>
                {tp(`No more than ${MaxCount} recipients on your current plan`)}
              </Text>
            </Box>
          </Flex>
        ) : (
          <FormLabel>{recipientList?.recipients ? t("Recipients list") : t("Contacts list")}</FormLabel>
        )}
        <RecipientList
          variables={variables}
          broadcastRecipientsFilter={broadcastFilter}
          setBroadcastFilter={setBroadcastFilter}
        />
        <Flex id="filter-container" className={s.flexFilter}></Flex>
      </FormControl>
    </Container>
  );
});
