import { Flex } from "@chakra-ui/react";
import { ReactComponent as LogoIcon } from "../../../assets/icons/sidebar/logoIcon.svg";
import { ReactComponent as LogoText } from "../../../assets/icons/sidebar/logoText.svg";
import { LanguageMenuContainer } from "./LanguageMenuContainer/LanguageMenuContainer";
import s from "./PublicHeaderContainer.module.scss";

export const PublicHeaderContainer = () => {
  return (
    <>
      <Flex className={s.headerBlock}>
        <Flex alignItems="center">
          <LogoIcon className={s.logoIcon} />
          <LogoText className={s.logoText} />
        </Flex>
        <LanguageMenuContainer />
      </Flex>
    </>
  );
};
