const LOAD_SCRIPT = "LOAD_SCRIPT";
const EXEC_PLUGIN = "EXEC_PLUGIN";

interface BaseMessage {
  type: string;
  message: object;
}
const isCorrectlyData = <T extends BaseMessage>(ev: MessageEvent<T>, type: string) => {
  if (typeof ev.data !== "object") return;
  if (!ev.data?.type) return;
  if (ev.data.type !== type) return;
  if (!ev.data.message) return;
  return true;
};

export interface LoadScript {
  type: string;
  message: {
    pluginId: string;
  };
}
export const loadScript = (ev: MessageEvent<LoadScript>, getFrameUrl: () => string | undefined) => {
  const frameUrl = getFrameUrl();
  if (ev.origin === frameUrl?.slice(0, frameUrl.length - 1)) {
    if (!isCorrectlyData(ev, LOAD_SCRIPT)) return;

    const { pluginId } = ev.data.message;

    const isPluginExists = !!document.getElementById(pluginId);

    if (!isPluginExists) {
      const container = document.getElementById("pluginContainer");
      const script = document.createElement("script");
      script.src = frameUrl + pluginId;
      script.id = pluginId;
      container?.appendChild(script);
    }
  }
};

export interface ExecPlugin {
  type: string;
  message: {
    pluginName: keyof Window;
    methodName: string;
    args?: unknown[];
  };
}
export const execPlugin = (ev: MessageEvent<ExecPlugin>, getFrameUrl: () => string | undefined) => {
  const frameUrl = getFrameUrl();
  if (ev.origin === frameUrl?.slice(0, frameUrl.length - 1)) {
    if (!isCorrectlyData(ev, EXEC_PLUGIN)) return;

    const { pluginName, methodName, args } = ev.data.message;

    window[pluginName][methodName](args);
  }
};
