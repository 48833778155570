/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";

import { Edge, Node } from "reactflow";
import { FlowBuilderState } from "./FlowBuilderState";
import {
  NodeModel,
  TriggerModel,
  FlowModel,
  PostFilePayload,
  CustomVariablesModel,
  NodeValidation,
  TriggerTypeDiscriminator,
  NodeType,
  NodeEvent,
  OperatorsModel,
  TeamsModel,
  FlowListModel,
} from "./FlowBuilderModel";
import { CustomVariableScope } from "../../common/AppEnums";
import { t } from "i18next";

export const initialState: FlowBuilderState = {
  nodeValidationState: {
    errors: [],
  },
  nodeEvent: null,
};

export const flowBuilderSlice = createSlice({
  name: "flowBuilderSlice",
  initialState,
  reducers: {
    createFlow: (state: FlowBuilderState, action: PayloadAction<{ botId: string; flowId: string; newFlowId?: string }>) => {
      return {
        ...initialState,
        flow: {
          id: action.payload.flowId,
          newFlowId: action.payload.newFlowId,
          botId: action.payload.botId,
          title: t("flow.My New flow") ?? "My New flow",
          nodes: [
            {
              id: "1",
              type: NodeType.Trigger,
              position: { x: 100, y: 5 },
              data: {},
            },
          ],
          edges: [],
          triggers: [
            {
              id: "1t",
              typeDiscriminator: TriggerTypeDiscriminator.ConversationStart,
              isEnabled: true,
              triggerGroupId: 0,
            },
          ],
        },
        nodeValidation: {
          valid: true,
          errors: [],
        },
      };
    },
    createImportFlow: (
      state: FlowBuilderState,
      action: PayloadAction<{ file?: File | null; botId: string; submitWarnings?: boolean }>,
    ) => {
      return {
        ...state,
      };
    },
    getDraftYamlFile: (state: FlowBuilderState) => {
      return {
        ...state,
      };
    },
    getDraftYamlFileCompleted: (state: FlowBuilderState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAvailableFileShoppingCartFile: action.payload,
      };
    },
    loadYamlFile: (state: FlowBuilderState, action: PayloadAction<{ file: File | null; addFile: (file: string) => void }>) => {
      return {
        ...state,
      };
    },
    clearYamlFile: (state: FlowBuilderState) => {
      return {
        ...state,
        isAvailableFileShoppingCartFile: undefined,
      };
    },
    publishYamlFile: (state: FlowBuilderState) => {
      return {
        ...state,
      };
    },
    createImportFlowTemplate: (state: FlowBuilderState, action: PayloadAction<{ botId: string; flowTemplateId: string }>) => {
      return {
        ...state,
      };
    },
    transferFlowCompleted: (state: FlowBuilderState, action: PayloadAction<FlowModel>) => {
      return {
        ...state,
        flow: action.payload,
      };
    },
    setFlowTitle: (state: FlowBuilderState, action: PayloadAction<string>) => {
      return {
        ...state,
        flow: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.flow!,
          title: action.payload,
        },
      };
    },
    editNode: (state: FlowBuilderState, action: PayloadAction<NodeModel>) => {
      return {
        ...state,
        nodeEditor: action.payload,
      };
    },
    editNodeCompleted: (state: FlowBuilderState) => {
      return {
        ...state,
        nodeEditor: undefined,
      };
    },
    setTriggers: (state: FlowBuilderState, action: PayloadAction<TriggerModel[]>) => {
      return {
        ...state,
        flow: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.flow!,
          triggers: action.payload,
        },
      };
    },

    setTrigger: (state: FlowBuilderState, action: PayloadAction<{ triggers: TriggerModel[]; trigger: TriggerModel }>) => {
      return {
        ...state,
      };
    },

    setTriggerCompleted: (state: FlowBuilderState, action: PayloadAction<TriggerModel[]>) => {
      return {
        ...state,
        flow: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.flow!,
          triggers: action.payload,
        },
      };
    },

    validateTrigger: (state: FlowBuilderState, action: PayloadAction<TriggerModel>) => {
      return {
        ...state,
      };
    },
    setNodes: (state: FlowBuilderState, action: PayloadAction<Node[]>) => {
      return {
        ...state,
        flow: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.flow!,
          nodes: [...action.payload],
        },
      };
    },
    setEdges: (state: FlowBuilderState, action: PayloadAction<Edge[]>) => {
      return {
        ...state,
        flow: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.flow!,
          edges: [...action.payload],
        },
      };
    },
    saveFlow: (state: FlowBuilderState, action: PayloadAction<FlowModel>) => {
      return {
        ...state,
        nodeValidationState: {
          valid: true,
          errors: [],
        },
      };
    },
    saveFlowCompleted: (state: FlowBuilderState, action: PayloadAction<FlowModel>) => {
      return {
        ...state,
        flow: action.payload,
      };
    },
    getFlow: (state: FlowBuilderState, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getFlowCompleted: (state: FlowBuilderState, action: PayloadAction<FlowModel>) => {
      return {
        ...state,
        flow: action.payload,
      };
    },
    getFlowList: (state, action: PayloadAction<{ botId: string; page: number; filter?: string }>) => {
      return {
        ...state,
      };
    },
    getFlowListCompleted: (state, action: PayloadAction<FlowListModel>) => {
      return {
        ...state,
        flowList: action.payload,
      };
    },
    getOperators: (state, action: PayloadAction<{ page: number; filter?: string }>) => {
      return {
        ...state,
      };
    },
    getOperatorsCompleted: (state, action: PayloadAction<OperatorsModel>) => {
      return {
        ...state,
        operators: action.payload,
      };
    },
    getTeams: (state, action: PayloadAction<{ page: number; filter?: string }>) => {
      return {
        ...state,
      };
    },
    getTeamsCompleted: (state, action: PayloadAction<TeamsModel>) => {
      return {
        ...state,
        teams: action.payload,
      };
    },
    postFile: (state: FlowBuilderState, action: PayloadAction<PostFilePayload>) => {
      return {
        ...state,
      };
    },
    getCustomVariablesCompleted: (state: FlowBuilderState, action: PayloadAction<CustomVariablesModel[]>) => {
      return {
        ...state,
        customVariables: action.payload,
      };
    },
    saveNode: (state: FlowBuilderState, action: PayloadAction<{ flow: FlowModel; node: NodeModel }>) => {
      return {
        ...state,
      };
    },
    saveNodeCompleted: (state: FlowBuilderState, action: PayloadAction<{ flow: FlowModel; nodes: Node[] }>) => {
      return {
        ...state,
        flow: {
          ...action.payload.flow,
          nodes: action.payload.nodes,
        },
      };
    },
    setNodeValidation: (state: FlowBuilderState, action: PayloadAction<NodeValidation>) => {
      return {
        ...state,
        nodeValidationState: action.payload,
      };
    },
    createCustomVariable: (
      state: FlowBuilderState,
      action: PayloadAction<{ variable: CustomVariablesModel; nodeEditorInfo?: unknown }>,
    ) => {
      return {
        ...state,
      };
    },
    discardFlow: (state: FlowBuilderState, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    updateDraftFlowState: (state: FlowBuilderState, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        draftFlowId: action.payload,
      };
    },
    changeNodePosition: (state: FlowBuilderState) => {
      return {
        ...state,
      };
    },
    setNodeEvent: (state: FlowBuilderState, action: PayloadAction<NodeEvent>) => {
      return {
        ...state,
        nodeEvent: action.payload,
      };
    },
    setNodeCenter: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        setCenter: action.payload,
      };
    },
    setLinkPlayAudio: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        linkPlayAudio: action.payload,
      };
    },
  },
});

export const {
  createFlow,
  setFlowTitle,
  loadYamlFile,
  clearYamlFile,
  publishYamlFile,
  getDraftYamlFile,
  createImportFlow,
  createImportFlowTemplate,
  editNode,
  editNodeCompleted,
  setNodes,
  setEdges,
  setTriggers,
  setTrigger,
  validateTrigger,
  getCustomVariablesCompleted,
  saveFlow,
  saveFlowCompleted,
  getFlow,
  getFlowList,
  getFlowListCompleted,
  getTeams,
  getOperators,
  getFlowCompleted,
  postFile,
  createCustomVariable,
  saveNode,
  transferFlowCompleted,
  saveNodeCompleted,
  discardFlow,
  setNodeValidation,
  changeNodePosition,
  setNodeEvent,
  setNodeCenter,
  setLinkPlayAudio,
} = flowBuilderSlice.actions;

export const selectFlowBuilderState = (state: RootState) => state.app.flowBuilderState;

export const selectFlow = (state: RootState) => state.app.flowBuilderState.flow;
export const selectIsAvaliableShoppingCartFile = (state: RootState) => state.app.flowBuilderState.isAvailableFileShoppingCartFile;
export const selectFlowList = (state: RootState) => state.app.flowBuilderState.flowList;
export const selectTeams = (state: RootState) => state.app.flowBuilderState.teams;
export const selectOperators = (state: RootState) => state.app.flowBuilderState.operators;
export const selectNodeEditorState = (state: RootState) => state.app.flowBuilderState.nodeEditor;

export const selectNodeEvent = (state: RootState) => state.app.flowBuilderState.nodeEvent;

export const selectCustomVariables = (state: RootState) => state.app.flowBuilderState.customVariables;
export const selectCustomVariablesWithoutSystem = (state: RootState) =>
  state.app.flowBuilderState.customVariables?.filter(el => el.scope !== CustomVariableScope.System);

export const selectDraftFlowId = (state: RootState) => state.app.flowBuilderState.draftFlowId;

export const selectNodeValidationState = (state: RootState) => state.app.flowBuilderState.nodeValidationState;
export const selectIsSetCenter = (state: RootState) => state.app.flowBuilderState.setCenter;
export const selectLinkPlayAudio = (state: RootState) => state.app.flowBuilderState.linkPlayAudio;

export default flowBuilderSlice.reducer;
