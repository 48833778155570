import { PATTERN_FOR_PASSWORD } from "../../../common/Regexp";
import { ButtonConfirmTypes } from "../LoginModel";

// eslint-disable-next-line
export const EMAIL_PATTERN = /^[\w-\.]*\+?[\w-\.]+@([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z0-9]{2,}$/;

export const validateIdentity = (dataPwConfirmButton: string, identity: string | null) => {
  const isIdentityError =
    dataPwConfirmButton === ButtonConfirmTypes.signUp
      ? identity?.length === 0 || (identity !== null && !EMAIL_PATTERN.test(identity))
      : identity?.length === 0;
  return isIdentityError;
};

export const validatePassword = (password: string | null) => {
  const isPasswordError =
    password !== null && password.length > 0 && (!PATTERN_FOR_PASSWORD.test(password) || password.length < 8);
  return isPasswordError;
};
