import { Box, Textarea, TextareaProps } from "@chakra-ui/react";
import { Dispatch, FocusEventHandler, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomVariablesModel, FlowActionModel } from "../../../FlowBuilderModel";
import s from "./UrlTextEditor.module.scss";
import { InputWrapper } from "../../../../inputWrapper/InputWrapper";
import { InputType } from "../../../../inputWrapper/InputWrapperModel";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableModel } from "../../../../../common/AppEnums";
import { insertStringIntoText } from "../../../../../common/utils/insertStringIntoText";
//import { addFormatting } from "../../../../../common/formattedText/addFormatting";

interface Props {
  isUrlTextEditorFocused: boolean;
  text: string;
  variables: CustomVariablesModel[];
  placeholder?: string;
  isInvalid?: boolean;
  maxLength: number;
  variant: string;
  onFocus: FocusEventHandler<HTMLTextAreaElement>;
  onBlur: FocusEventHandler<HTMLTextAreaElement>;
  TextAreaProps?: TextareaProps | undefined;
  maxHeightWithoutScroll?: number;
  flowAction: FlowActionModel;
  onChange: (text: string) => void;
  addCustomVariableUrl: Dispatch<
    SetStateAction<{
      text: CustomVariablesModel;
      maxLength: number;
      cursorPosition: number;
    } | null>
  >;
}

export const UrlTextEditor = ({ text, variables, isUrlTextEditorFocused, ...props }: Props) => {
  const textareaUrlRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation("translation", { keyPrefix: "flow.editNodePopup" });
  const [cursorPosition, setCursorPosition] = useState<number>(text.length);

  useEffect(() => {
    if (textareaUrlRef.current) {
      textareaUrlRef.current.style.height = "0px";
      const scrollHeight = textareaUrlRef.current.scrollHeight;
      textareaUrlRef.current.style.height = scrollHeight + "px";
      if (props.maxHeightWithoutScroll && scrollHeight >= props.maxHeightWithoutScroll) {
        textareaUrlRef.current.style.overflow = "scroll";
      } else {
        textareaUrlRef.current.style.overflow = "hidden";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const onAddCustomVariable = (variable: CustomVariableModel) => {
    props.addCustomVariableUrl({ text: variable, maxLength: props.maxLength, cursorPosition: cursorPosition });
    const textWithVariable = insertStringIntoText(text, "@{" + variable.scope + ":" + variable.key + "}", cursorPosition);
    props.onChange(textWithVariable);
  };

  return (
    <Box
      borderRadius={"8px"}
      className={`${s.textUrlAreaBox} ${isUrlTextEditorFocused && s.textareaFocus} ${props.isInvalid && s.error}`}
    >
      <InputWrapper
        cursorPosition={cursorPosition}
        type={InputType.TextArea}
        maxLength={props.maxLength}
        charactersNumber={text.length}
        variablesProps={{
          addCustomVariableText: onAddCustomVariable,
          variables: variables ? variables.filter(variable => variable.type === "Text") : [],
          ContextModelType: CustomVariableContextMenuType.NodeEditor,
        }}
        buttonBlockStyle={{ background: "none", border: "1px solid white", borderRadius: "8px" }}
        childRef={textareaUrlRef}
      >
        <Textarea
          tabIndex={-1}
          autoFocus={isUrlTextEditorFocused}
          ref={textareaUrlRef}
          onChange={e => props.onChange(e.target.value)}
          onSelect={e => {
            setCursorPosition(e.currentTarget.selectionStart);
          }}
          name="url"
          id="text"
          minHeight={"10px !important"}
          overflowY={"auto"}
          maxLength={props.maxLength}
          placeholder={props.placeholder ?? `${t("Enter text here")} ...`}
          value={text}
          height={"auto"}
          onBlur={props.onBlur}
          resize={"none"}
          overflow={"hidden"}
          p={2}
          border={"none"}
          onFocus={props.onFocus}
          _focus={{ boxShadow: "none", borderColor: "white", _focusVisible: "none" }}
          {...props.TextAreaProps}
          data-pw="url-text-editor"
        />
      </InputWrapper>
    </Box>
  );
};
