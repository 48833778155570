import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { signalRConnection } from "../../middlewares/signalRMiddleware";
import { store } from "../../index";
import { chatListSlice } from "../chatList/ChatListSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleException } from "../../common/SagaHelper";
import { conversationViewerSlice, selectCurrentChatViewer, selectOperatorViewersInChats } from "./ConversationViewerSlice";
import * as ConversationViewerApi from "./ConversationViewerApi";
import { CurrentChatViewerModel, OperatorJoinModel, OperatorLeftModel } from "./ConversationViewerModel";
import {
  ActiveOperatorsViewChatData,
  OperatorJoinData,
  OperatorLeftData,
  UpdateOperatorsViewData,
} from "./ConversationViewerData";
import {
  mapListActiveJoinOperatorsDataToModel,
  mapListOperatorJoinDataToOperatorJoinModel,
  mapOperatorJoinModelToData,
  mapOperatorLeftModelToData,
} from "./ConversationViewerMapper";
import { logout } from "../../common/auth/AuthSlice";

export function* conversationViewerSaga() {
  yield takeEvery(conversationViewerSlice.actions.operatorLeftConversation, operatorLeftConversation);
  yield takeEvery(chatListSlice.actions.clearChatListState, operatorLeftChat);
  yield takeEvery(conversationViewerSlice.actions.logoutViewer, operatorLogout);
  yield takeLatest(conversationViewerSlice.actions.operatorViewConversation, operatorViewConversation);
  yield takeLatest(conversationViewerSlice.actions.currentOperatorViewersInConversation, getCurrentOperatorViewersInConversation);

  const operatorView: OperatorJoinModel[] = yield select(selectOperatorViewersInChats);
  signalRConnection.addHandler("receiveOperatorView", (data: OperatorJoinData) => {
    const model: OperatorJoinModel = { ...data };
    if (operatorView) {
      addOperatorInExistingList(operatorView, model);
    } else {
      store.dispatch(conversationViewerSlice.actions.currentOperatorViewersInConversation());
    }
  });
  signalRConnection.addHandler("receiveOperatorsUpdated", (data: UpdateOperatorsViewData) => {
    const model: OperatorJoinModel[] = mapListOperatorJoinDataToOperatorJoinModel(data.operators);
    const filteredOperators = excludeCurrentOperator(model);
    store.dispatch(conversationViewerSlice.actions.operatorLeftConversationCompleted(filteredOperators));
  });
}

function* operatorViewConversation(action: PayloadAction<OperatorJoinModel>) {
  try {
    const data: OperatorJoinData = mapOperatorJoinModelToData(action.payload);
    yield call(ConversationViewerApi.postOperatorJoin, data);
    const operator: CurrentChatViewerModel = { operatorName: data.operatorName };
    yield put(conversationViewerSlice.actions.setViewerInConversation(operator));
  } catch (e: unknown) {
    handleException(e);
  }
}

function* operatorLeftConversation(action: PayloadAction<OperatorLeftModel>) {
  try {
    const data: OperatorLeftData = mapOperatorLeftModelToData(action.payload);
    yield call(ConversationViewerApi.postOperatorLeft, data);
  } catch (e: unknown) {
    handleException(e);
  }
}

function* operatorLeftChat() {
  const operator: CurrentChatViewerModel = yield select(selectCurrentChatViewer);
  if (!operator) return;

  try {
    const data: OperatorLeftData = { operatorName: operator.operatorName };
    yield call(ConversationViewerApi.postOperatorLeft, data);
    yield put(conversationViewerSlice.actions.endViewerSession());
  } catch (e: unknown) {
    handleException(e);
  }
}

function* operatorLogout(action: PayloadAction<OperatorLeftModel>) {
  try {
    yield call(ConversationViewerApi.postOperatorLogout, action.payload);
    yield put(conversationViewerSlice.actions.endViewerSession());
    yield put(logout({}));
  } catch (e: unknown) {
    handleException(e);
  }
}

function* getCurrentOperatorViewersInConversation() {
  try {
    const data: ActiveOperatorsViewChatData = yield call(ConversationViewerApi.getCurrentOperatorViewers);
    const model = mapListActiveJoinOperatorsDataToModel(data.operators);
    const filteredOperators = excludeCurrentOperator(model);
    yield put(conversationViewerSlice.actions.operatorViewConversationCompleted(filteredOperators));
  } catch (e: unknown) {
    handleException(e);
  }
}

function addOperatorInExistingList(operatorView: OperatorJoinModel[], model: OperatorJoinModel) {
  if (!operatorView.filter(x => x.operatorName === model.operatorName)) {
    operatorView.push(model);
    store.dispatch(conversationViewerSlice.actions.operatorViewConversationCompleted(operatorView));
  } else {
    const newOperators = operatorView.filter(x => x.operatorName !== model.operatorName);
    newOperators.push(model);
    const filteredOperators = excludeCurrentOperator(newOperators);
    store.dispatch(conversationViewerSlice.actions.operatorViewConversationCompleted(filteredOperators));
  }
}

function excludeCurrentOperator(operators: OperatorJoinModel[]) {
  const user = store.getState().userState;
  return operators.filter(x => x.operatorName !== user.username);
}
