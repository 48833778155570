import { httpGet, httpPost } from "../../common/BaseApi";
import { OperatorJoinData, OperatorLeftData } from "./ConversationViewerData";

export const postOperatorJoin = (data: OperatorJoinData) => {
  return httpPost(`/api/conversation/operatorJoin`, data);
};

export const postOperatorLeft = (data: OperatorLeftData) => {
  return httpPost(`/api/conversation/operatorLeft`, data);
};

export const postOperatorLogout = (data: OperatorLeftData) => {
  return httpPost(`/api/conversation/operatorLogout`, data);
};

export const getCurrentOperatorViewers = () => {
  return httpGet(`/api/conversation/currentOperators`);
};
