import { httpGet, httpPostForm } from "../../../../common/BaseApi";

export const getContactListApi = (botId: string, queryFilterParams: string | undefined) => {
  return httpGet(`/api/broadcast/bot/recipients/${botId}?${queryFilterParams ? `&${queryFilterParams}` : ""}`);
};

export const getDraftContactListApi = (botId: string, broadcastId: string, queryFilterParams: string | undefined) => {
  return httpGet(
    `/api/broadcast/bot/recipients/${botId}?broadcastId=${broadcastId}${queryFilterParams ? `&${queryFilterParams}` : ""}`,
  );
};

export const importRecipientsByFileApi = (botId: string | undefined, file: File | null) => {
  const formData = new FormData();
  if (file) {
    formData.append("file", file, file.name);
  }
  return httpPostForm(`/api/broadcast/recipientByFile/${botId}?excludeUnavailable=true&limit=1000`, formData); // предупредить пользователя про лимит
};
