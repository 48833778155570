import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { deleteCookie, getTokenFromStorage, setToken } from "./AuthRepo";
import { authSlice } from "./AuthSlice";
import { updateToken } from "./AuthApi";
import { GetTokenResponseModel } from "../../components/login/LoginModel";
import { GetTokenResponseData } from "../../components/login/LoginData";
import { routerSlice } from "../router/RouterSlice";
import { handleException } from "../SagaHelper";
import { userSlice } from "../user/UserSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { notificationSlice } from "../notifications/NotificationSlice";
import { store } from "../../index";

export function* authSaga() {
  yield takeLatest(authSlice.actions.getToken, getToken);
  yield takeLeading(authSlice.actions.refreshToken, refreshToken);
  yield takeLatest(authSlice.actions.logout, logout);
  //yield takeLatest(authSlice.actions.tokenRejected, tokenRejected);
}

function* getToken(action: ReturnType<typeof authSlice.actions.getToken>) {
  const apiName = action.payload;

  try {
    const token = getTokenFromStorage();

    if (token && token.expiration < new Date()) {
      yield put(
        authSlice.actions.refreshToken({
          apiName: apiName,
          refresh: token.refresh,
          token: token.accessToken,
          expiration: token.expiration,
        }),
      );

      return;
    }

    if (token) {
      yield put(
        authSlice.actions.tokenReceived({
          apiName: apiName,
          token: token.accessToken,
          refresh: token.refresh,
          expiration: token.expiration,
        }),
      );
      const accessTokenData = JSON.parse(atob(token.accessToken.split(".")[1]));

      const isImpersonated = !!accessTokenData?.ImpersonateToken;
      yield put(userSlice.actions.setIsImpersonated(isImpersonated));
      return;
    }

    yield put(authSlice.actions.tokenRejected({ apiName: apiName, message: "Token does not exists" }));
    yield put(authSlice.actions.logout({}));
  } catch (e) {
    if (e instanceof Error) {
      yield put(authSlice.actions.tokenRejected({ apiName: apiName, message: e.message }));
      yield put(authSlice.actions.logout({}));
    }
  }
}

export function* refreshToken(action: ReturnType<typeof authSlice.actions.refreshToken>) {
  try {
    const res: GetTokenResponseData = yield call(updateToken, {
      refreshToken: action.payload.refresh,
      token: action.payload.token,
    });

    if (res.responseStatus === 401) {
      yield put(authSlice.actions.tokenRejected({ apiName: action.payload.apiName, message: "auth" }));
      yield put(authSlice.actions.logout({}));
      return;
    }

    const newToken: GetTokenResponseModel = {
      accessToken: res.token,
      refresh: res.refreshToken,
      isExpired: false,
      expiration: res.expiration,
    };

    setToken(newToken);

    yield put(
      authSlice.actions.tokenReceived({
        apiName: action.payload.apiName,
        token: newToken.accessToken,
        refresh: newToken.refresh,
        expiration: newToken.expiration,
      }),
    );
    return;
  } catch (e) {
    if (e instanceof Error) {
      yield put(authSlice.actions.tokenRejected({ apiName: action.payload.apiName, message: e.message }));
      store.dispatch(
        notificationSlice.actions.notify({
          message: "Connection problem. Reconnecting...",
          type: "warning",
          duration: 1000,
        }),
      );
    }
  }
}

function* logout(action: PayloadAction<{ inviteToken?: string }>) {
  try {
    localStorage.clear();
    deleteCookie("token");
    sessionStorage.removeItem("username");
    const inviteToken = action.payload.inviteToken;
    yield put(routerSlice.actions.redirect(`/auth/${inviteToken ? `?inviteToken=${action.payload.inviteToken}` : ""}`));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
