import { ActionCreatorWithOptionalPayload, AnyAction, Dispatch } from "@reduxjs/toolkit";
import { ButtonModel } from "../../../../common/AppButtonsModel";
import { BroadcastModel, BroadcatMessageModel } from "../../BroadcastModel";

export const addButton =
  (
    values: BroadcastModel | undefined,
    dispatch: Dispatch<AnyAction>,
    slice: ActionCreatorWithOptionalPayload<BroadcatMessageModel | undefined>,
  ) =>
  (newButton: ButtonModel) => {
    const newMessage: BroadcatMessageModel | undefined = values?.message
      ? { ...values?.message, buttons: [...values.message.buttons, newButton] }
      : values?.message;
    dispatch(slice(newMessage));
  };

export const editButton =
  (
    values: BroadcastModel | undefined,
    dispatch: Dispatch<AnyAction>,
    slice: ActionCreatorWithOptionalPayload<BroadcatMessageModel | undefined>,
  ) =>
  (button: ButtonModel) => {
    const newMessage: BroadcatMessageModel | undefined = values?.message
      ? {
          ...values?.message,
          buttons: values.message.buttons.map(buttonItem => (buttonItem.id === button.id ? button : buttonItem)),
        }
      : values?.message;
    dispatch(slice(newMessage));
  };

export const deleteButton =
  (
    values: BroadcastModel | undefined,
    dispatch: Dispatch<AnyAction>,
    slice: ActionCreatorWithOptionalPayload<BroadcatMessageModel | undefined>,
  ) =>
  (buttonId: string) => {
    const newMessage: BroadcatMessageModel | undefined = values?.message
      ? { ...values?.message, buttons: values.message.buttons.filter(button => button.id !== buttonId) }
      : values?.message;
    dispatch(slice(newMessage));
  };
export const setButtons =
  (
    values: BroadcastModel | undefined,
    dispatch: Dispatch<AnyAction>,
    slice: ActionCreatorWithOptionalPayload<BroadcatMessageModel | undefined>,
  ) =>
  (buttons: ButtonModel[]) => {
    const newMessage: BroadcatMessageModel | undefined = values?.message
      ? { ...values?.message, buttons: buttons }
      : values?.message;
    dispatch(slice(newMessage));
  };
