import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { Button, Flex } from "@chakra-ui/react";
import s from "../audioPlayer/AudioPlayer.module.scss";
import { FileNameSplitter } from "../../atoms/fileNameSplitter/FileNameSplitter";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import { selectLinkPlayAudio, setLinkPlayAudio } from "../../../components/flowBuilder/FlowBuilderSlice";

interface Props {
  name?: string;
  link?: string;
  blockClick: boolean;
  setBlockClick: Dispatch<SetStateAction<boolean>>;
}

export const AudioPlayer = (props: Props) => {
  const audio = useMemo(() => new Audio(props.link), [props.link]);
  const audioElement = useRef(audio);
  const [playerState, setPlayerState] = useState(false);
  const [currentSign, setCurrentSign] = useState("▶");
  const nowLink = useAppSelector(selectLinkPlayAudio);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (nowLink !== props.link) {
      audioElement.current.pause();
      audioElement.current.currentTime = 0;
      setPlayerState(false);
      setCurrentSign("▶");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowLink]);

  const handleClick = () => {
    if (playerState) {
      stop();
    } else {
      play();
    }
  };

  function play() {
    dispatch(setLinkPlayAudio(props.link));
    const audioPromise = audioElement.current.play();
    audioPromise
      .then(() => {
        setPlayerState(true);
        setCurrentSign("II");
      })
      .catch(err => {
        console.log(err);
      });
  }

  function stop() {
    audioElement.current.pause();
    setPlayerState(false);
    setCurrentSign("▶");
  }

  useEffect(() => {
    return () => {
      stop();
    };
  }, []);

  useEffect(() => {
    stop();
    audioElement.current = audio;
  }, [audio]);

  return (
    <>
      <Flex
        className={s.audioPlayer}
        onMouseEnter={() => props.setBlockClick(true)}
        onMouseLeave={() => props.setBlockClick(false)}
      >
        <Button className={s.audioPlayerButtons} onClick={handleClick}>
          {currentSign}
        </Button>
        <FileNameSplitter fileName={props.name ?? ""} style={s.audioPlayerText} variant={"medium"} />
      </Flex>
    </>
  );
};
