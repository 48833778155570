import { RootState } from "../../common/state/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentChatViewerModel, OperatorJoinModel, OperatorLeftModel } from "./ConversationViewerModel";

export interface ConversationViewerState {
  currentChatViewer?: CurrentChatViewerModel;
  operatorChatViewers?: OperatorJoinModel[];
  canUploadConversations?: boolean;
}

const initialState: ConversationViewerState = {};

export const conversationViewerSlice = createSlice({
  name: "conversationViewerSlice",
  initialState,
  reducers: {
    setViewerInConversation: (state: ConversationViewerState, action: PayloadAction<CurrentChatViewerModel>) => {
      return {
        ...state,
        currentChatViewer: action.payload,
      };
    },
    operatorViewConversation: (state: ConversationViewerState, action: PayloadAction<OperatorJoinModel>) => {
      return {
        ...state,
      };
    },
    operatorViewConversationCompleted: (state: ConversationViewerState, action: PayloadAction<OperatorJoinModel[]>) => {
      return {
        ...state,
        operatorChatViewers: action.payload,
      };
    },
    operatorLeftConversation: (state: ConversationViewerState, action: PayloadAction<OperatorLeftModel>) => {
      return {
        ...state,
      };
    },
    operatorLeftConversationCompleted: (state: ConversationViewerState, action: PayloadAction<OperatorJoinModel[]>) => {
      return {
        ...state,
        operatorChatViewers: action.payload,
      };
    },
    currentOperatorViewersInConversation: (state: ConversationViewerState) => {
      return {
        ...state,
      };
    },
    logoutViewer: (state: ConversationViewerState, action: PayloadAction<OperatorLeftModel>) => {
      return {
        ...state,
      };
    },
    endViewerSession: (state: ConversationViewerState) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { setViewerInConversation, operatorViewConversation, operatorLeftConversation, logoutViewer } =
  conversationViewerSlice.actions;

export const selectOperatorViewersInChats = (state: RootState) => state.app.conversationViewerState.operatorChatViewers;
export const selectCurrentChatViewer = (state: RootState) => state.app.conversationViewerState.currentChatViewer;

export default conversationViewerSlice.reducer;
