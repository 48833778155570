import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Link,
  Skeleton,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BotFlowModel, BotVariableModel } from "../../BotModel";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { ContactsBroadcastsModel } from "../../../contact/ContactModel";

interface Props {
  flows?: BotFlowModel[];
  broadcasts?: ContactsBroadcastsModel[];
  variable: BotVariableModel | undefined;
  onClose: () => void;
  onConfirmDelete: (id?: string) => void;
}

export const BotVariableDeletePopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "bot.deleteVariable",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const ctct = useTranslation("translation", { keyPrefix: "contact" }).t;

  const onDelete = () => {
    props.onConfirmDelete(props.variable?.id);
  };

  const variableNotInAFlow: boolean | undefined = props.flows && props.flows.length < 1;
  const variableNotInBroadcasts: boolean | undefined = props.broadcasts && props.broadcasts.length < 1;
  return (
    <Modal isOpen={props.variable !== undefined} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent width={{ base: "340px", sm: "448px" }}>
        <ModalHeader>{t("Delete Variable")}</ModalHeader>
        <ModalCloseButton
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
          data-pw="close-button"
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody paddingTop={"0"}>
          {!props.flows && (
            <Flex flexDirection="column" gap="4px">
              <Skeleton speed={0.5} startColor="line" endColor="bgLight" height="20px" width="100%" />
              <Skeleton speed={0.5} startColor="line" endColor="bgLight" height="20px" width="100%" />
              <Skeleton speed={0.5} startColor="line" endColor="bgLight" height="20px" width="100%" />
            </Flex>
          )}

          {((props.flows && props.flows.length < 1 && !props.broadcasts) || (variableNotInAFlow && variableNotInBroadcasts)) && (
            <Box>
              {t("You are going to delete")} <b>{props.variable?.key}</b>
            </Box>
          )}

          {props.flows && props.flows.length > 0 && (
            <Box marginBottom={"20px"}>
              <Box>
                <b>{props.variable?.key} </b>
                {t("can't be deleted because it is used in flow(s)")}:
              </Box>
              {props.flows.map(flow => (
                <Box key={flow.id}>
                  <Link href={`/automation/flows/${flow.id}`} isExternal={true}>
                    <b>{flow.name}</b>
                  </Link>
                </Box>
              ))}
              <Box>{t("Please, remove variable from the flow(s) and try again.")}</Box>
            </Box>
          )}

          {props.broadcasts && props.broadcasts.length > 0 && (
            <Box>
              <Box>
                <b>{props.variable?.key} </b>
                {ctct("can't be deleted because it is used in broadcast(s)")}:
              </Box>
              {props.broadcasts.map(broadcast => (
                <Box key={broadcast.id}>
                  <Link href={`/broadcasts/${broadcast.id}/edit`} isExternal={true}>
                    <b>{broadcast.name}</b>
                  </Link>
                </Box>
              ))}
              <Box>{ctct("Please, remove variable from the broadcast(s) and try again.")}</Box>
            </Box>
          )}
        </ModalBody>
        <ModalFooter paddingBottom="32px" justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={props.onClose} data-pw="cancel-button">
            {ct("Cancel")}
          </Button>
          <Button
            variant="dominoPrimaryRed"
            mr={3}
            isDisabled={!props.flows || props.flows.length > 0 || (props.broadcasts && props.broadcasts?.length > 0)}
            onClick={onDelete}
            data-pw="delete-button"
          >
            {ct("Delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
