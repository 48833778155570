/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRolesEnum } from "../AppEnums";
import { RootState } from "../state/store";
import { LanguageCodeEnum, UserModel } from "./UserModel";
import { UserState } from "./UserState";
import { UserBillingPlanModel } from "../../components/userProfile/UserProfileModel";
import i18n from "../i18next/i18n";

const initialState: UserState = {
  id: "",
  locale: i18n.language as LanguageCodeEnum,
  roles: [],
  hasPassword: false,
  username: "",
  isImpersonated: false,
  email: "",
  linkedAccounts: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getProfile: state => {
      return {
        ...state,
      };
    },
    getProfileCompleted: (state, action: PayloadAction<UserModel>) => {
      return {
        ...state,
        id: action.payload.id,
        hasPassword: action.payload.hasPassword,
        username: action.payload.username,
        email: action.payload.email ?? "",
        linkedAccounts: action.payload.linkedAccounts,
      };
    },
    getLanguageCompleted: (state, action: PayloadAction<LanguageCodeEnum>) => {
      return {
        ...state,
        locale: action.payload,
      };
    },
    setLanguageCompleted: (state, action: PayloadAction<LanguageCodeEnum>) => {
      return {
        ...state,
        locale: action.payload,
      };
    },
    setRoles: (state, action: PayloadAction<UserRolesEnum[]>) => {
      return {
        ...state,
        roles: action.payload,
      };
    },
    setIsImpersonated: (state, action: PayloadAction<boolean | undefined>) => {
      return {
        ...state,
        isImpersonated: action.payload,
      };
    },
    getBillingPlan: state => {
      return {
        ...state,
      };
    },
    getBillingPlanSucceed: (state, action: PayloadAction<UserBillingPlanModel>) => {
      return {
        ...state,
        billingPlan: {
          billingPlanName: action.payload.billingPlanName,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    },
    setLinkedAccountCompleted: (state, action: PayloadAction<UserModel>) => {
      return {
        ...state,
        id: action.payload.id,
        username: action.payload.username,
        linkedAccounts: [...state.linkedAccounts.concat(action.payload.linkedAccounts)],
      };
    },
  },
});

export const { getProfile, getBillingPlan, getProfileCompleted, setLanguageCompleted, setLinkedAccountCompleted } =
  userSlice.actions;

export default userSlice.reducer;

export const selectLanguage = (state: RootState) => state.userState.locale;
export const selectRoles = (state: RootState) => state.userState.roles;
export const selectIsImpersonated = (state: RootState) => state.userState.isImpersonated;
export const selectIsOwner = (state: RootState) => state.userState.roles.some(role => role === UserRolesEnum.Owner);
export const selectIsSuperAdmin = (state: RootState) => state.userState.roles.some(role => role === UserRolesEnum.SuperAdmin);
export const selectUserId = (state: RootState) => state.userState.id;
export const selectBillingPlan = (state: RootState) => state.userState.billingPlan;
export const selectUser = (state: RootState) => state.userState;
export const selectLinkedAccounts = (state: RootState) => state.userState.linkedAccounts;
