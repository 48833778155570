import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AppSettings } from "../../../common/AppSettings";

declare const appSettings: AppSettings;

export const UserPlanManageButton = () => {
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });
  const link = appSettings.billingPortalUrl;

  const onOpenPlan = () => {
    window.open(link, "_blank");
  };

  return (
    <Button minW="166px" fontSize="sm" variant="dominoOutlineViolet" onClick={onOpenPlan}>
      {t("Manage your plan")}
    </Button>
  );
};
