import { List, ListItem, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BotFatherLink } from "./BotFatherLink";
import { InstructionStep, TranslationWithStrongWord } from "./InstructionStep";
import s from "../../components/CreateConnectBot/CreateConnectBotContainer.module.scss";

export const CreateBotTab = () => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });
  return (
    <>
      <Text variant="h2">{t("Setup instruction")}</Text>
      <List className={s.instructionStepsList} spacing={4}>
        <ListItem className={s.listItem}>
          <InstructionStep stepNumber={1}>
            <BotFatherLink i18nKey="addBot.First create bot instruction step" />
          </InstructionStep>
        </ListItem>
        <ListItem className={s.listItem}>
          <InstructionStep stepNumber={2}>
            <TranslationWithStrongWord i18nKey="addBot.Second create bot instruction step" />
          </InstructionStep>
        </ListItem>
        <ListItem className={s.listItem}>
          <InstructionStep stepNumber={3}>
            <TranslationWithStrongWord i18nKey="addBot.Third create bot instruction step" />
          </InstructionStep>
        </ListItem>
        <ListItem className={s.listItem}>
          <InstructionStep stepNumber={4}>
            <TranslationWithStrongWord i18nKey="addBot.Fourth create bot instruction step" />
          </InstructionStep>
        </ListItem>
        <ListItem className={s.listItem}>
          <InstructionStep stepNumber={5}>
            <TranslationWithStrongWord i18nKey="addBot.Fifth create bot instruction step" />
          </InstructionStep>
        </ListItem>
      </List>
    </>
  );
};
