export interface CreatedBotModel {
  id: string;
  externalId: string;
  username: string;
  name: string;
  accessToken: string;
  flowCount: number;
}

export enum BotIndexTabNames {
  "CREATE" = 0,
  "CONNECT" = 1,
}
