import { Box } from "@chakra-ui/react";
import s from "../../Sidebar.module.scss";
import { Dispatch, SetStateAction, useEffect } from "react";

interface Props {
  mobileOpenMenu: boolean;
  setMobileOpenMenu: Dispatch<SetStateAction<boolean>>;
}

export const SideBarBurgerLinesMobile = (props: Props) => {
  useEffect(() => {
    return () => {
      props.setMobileOpenMenu(false);
    };
  }, []);

  return (
    <Box
      className={`${s.burgerSidebar} ${props.mobileOpenMenu ? s.open : ""}`}
      cursor={"pointer"}
      onClick={() => props.setMobileOpenMenu(current => !current)}
    >
      <Box>
        <div className={s.burgerLine}></div>
        <div className={s.burgerLine}></div>
        <div className={s.burgerLine}></div>
      </Box>
    </Box>
  );
};
