import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../common/state/store";
import { selectImportRecipientList, selectRecipientCount } from "./RecipientListSlice";

export const RecipientsCounter = () => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const count = useAppSelector(selectRecipientCount);
  const importRecipientList = useAppSelector(selectImportRecipientList);

  return (
    <>
      <Text>
        <span data-pw="selected-contacts-count">{importRecipientList?.availableRecipients ?? count}</span>
        {` ${t("Selected")}`}
      </Text>
    </>
  );
};
