import { Paginator } from "../../../../../common/paginator/Paginator";
import { getContactVariables, selectPagingContactVariables } from "../../../ContactSlice";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { Box } from "@chakra-ui/layout";
import { EDIT_CONTACTS_PAGE_SIZE } from "../../../../../common/paginator/paginatorSizes";
import { TABLET_VIEW } from "../../../../layout/LayoutHelper/ResolutionConst";

export const EditContactPaginator = () => {
  const { contactId } = useParams();
  const dispatch = useAppDispatch();
  const contactPagingVariables = useAppSelector(selectPagingContactVariables);
  const getPage = (page: number) => {
    if (contactId) {
      dispatch(getContactVariables({ contactId, page: page.toString(), size: EDIT_CONTACTS_PAGE_SIZE.toString() }));
    }
  };
  return (
    <Box position={"sticky"} bottom={"0"} className="editContactPaginator">
      <Paginator
        sizeItems={EDIT_CONTACTS_PAGE_SIZE}
        loadingScope="getContactVariables"
        data={{ ...contactPagingVariables, items: contactPagingVariables.items || [] }}
        getPage={getPage}
        typeOfPage={"ContactVariables"}
        paddingRight={window.innerWidth > TABLET_VIEW ? "28px" : "20px"}
        paddingLeft="20px"
      />
    </Box>
  );
};
