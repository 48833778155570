import { call, put, select, takeLatest } from "redux-saga/effects";
import { SignupModel, SuccessSignupTokenModel } from "./SignUpModel";
import { signUpSlice } from "./SignUpSlice";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { PayloadAction } from "@reduxjs/toolkit";
import * as signUpApi from "./SignUpApi";
import { handleException } from "../../common/SagaHelper";
import GTM, { setUserId, setUserProperty } from "../../common/ga/GAEventTracker";
import { LanguageCodeEnum } from "../../common/user/UserModel";
import { selectLanguage, setLanguageCompleted } from "../../common/user/UserSlice";
import { setToken } from "../../common/auth/AuthRepo";
import { routerSlice } from "../../common/router/RouterSlice";
import { NotificationModel } from "../../common/notifications/NotificationModel";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import i18n from "../../common/i18next/i18n";

export function* signUpSaga() {
  yield takeLatest(signUpSlice.actions.signUp, signUp);
  yield takeLatest(signUpSlice.actions.setTokenRegistration, setTokenRegistration);
}

function* signUp(action: PayloadAction<SignupModel>) {
  try {
    const lng: LanguageCodeEnum = yield select(selectLanguage);
    yield put(beginScope("signIn/signUp"));
    yield call(signUpApi.SignUp, action.payload, lng);
    yield put(signUpSlice.actions.setSignUpSubmit(true));

    const trackEvent = GTM("Signin");
    trackEvent("SignUpConfirm");
  } catch (e: unknown) {
    yield put(signUpSlice.actions.setSignUpSubmit(false));
    yield handleException(e);
  } finally {
    yield put(completeScope("signIn/signUp"));
  }
}

function* setTokenRegistration(action: PayloadAction<string>) {
  try {
    yield put(beginScope("registrationSuccessful"));
    const token: string = action.payload;

    const claims = JSON.parse(atob(token.split(".")[1]));
    const locale = claims.Lang;
    i18n.changeLanguage(locale);
    yield put(setLanguageCompleted(locale));

    const lng: LanguageCodeEnum = yield select(selectLanguage);

    const data: SuccessSignupTokenModel = yield call(signUpApi.signUpConfirmationApi, token, lng);

    const newToken = {
      accessToken: data?.token,
      refresh: data?.refreshToken,
      isExpired: false,
      expiration: data?.expiration,
    };

    const newTokenClaims = JSON.parse(atob(newToken.accessToken.split(".")[1]));
    const userName = newTokenClaims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    localStorage.setItem("username", userName);
    setToken(newToken);

    const organizationId = newTokenClaims.Organization;
    const userId = newTokenClaims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

    userId !== undefined && setUserId(userId);
    setUserProperty({ organizationId: organizationId });

    const trackEvent = GTM("Signup");
    trackEvent("SignupCompleted");
  } catch (e: unknown) {
    yield handleException(e);
    const notification: NotificationModel = {
      message: i18n.t("commonWords.Time to confirmation"),
      type: "error",
      duration: 5000,
    };
    yield put(notificationSlice.actions.notify(notification));
    yield put(routerSlice.actions.redirect("/auth"));
  } finally {
    yield put(completeScope("registrationSuccessful"));
  }
}
