import { Box, Tooltip, Text, Input } from "@chakra-ui/react";
import s from "../../../Automation.module.scss";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/editIcon.svg";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../../common/state/store";
import { useGAWithCustomParameters } from "../../../../../common/ga/GAEventTracker";
import { renameFlow } from "../../../AutomationSlice";
import React from "react";
import { useNowWidthView } from "../../../../layout/LayoutHelper/ResolutionHooks";

interface Props {
  isEditFlowName: boolean;
  setIsEditFlowName: (val: boolean) => void;
  id: string;
  title: string;
}

export const FlowListItemInput = React.memo(function FlowListItemInput({ isEditFlowName, setIsEditFlowName, title, id }: Props) {
  const [currentText, setCurrentText] = useState("");
  const [lastText, setLastText] = useState("");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const dispatch = useAppDispatch();
  const trackEvent = useGAWithCustomParameters("Flow");
  const nowWidth = useNowWidthView();

  useEffect(() => {
    setCurrentText(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const onChange = (event: { target: { value: SetStateAction<string> } }) => {
    setCurrentText(event.target.value);
  };
  function saveRenamedFlow() {
    const newName = currentText.trim();
    if (!newName.length) {
      setIsEditFlowName(false);
      setCurrentText(!lastText.length ? title : lastText);
      return;
    }
    dispatch(renameFlow({ flowId: id, newName: currentText }));
    setIsEditFlowName(false);
    setCurrentText(currentText.trim());
    setLastText(!currentText.length ? title : currentText);
    trackEvent("FlowNameChange", "", { target: "ListScreen" });
  }
  const textRefDesk = useRef<HTMLParagraphElement>(null);
  const textRefMob = useRef<HTMLParagraphElement>(null);

  const toggleTooltipVisible = () => {
    const element = textRefMob.current ? textRefMob.current : textRefDesk.current;
    if (element) {
      if (element.offsetWidth < element.scrollWidth) {
        setIsTooltipVisible(true);
      } else {
        setIsTooltipVisible(false);
      }
    }
  };

  if (nowWidth <= 1100) {
    return (
      <Tooltip
        label={currentText}
        placement="top"
        isDisabled={!isTooltipVisible}
        variant={"dominoChat"}
        bg={"#FEF6DC"}
        hasArrow={true}
        openDelay={100}
      >
        <Text
          ref={textRefMob}
          className={`${s.flowListItemGridTitleMobile_text}`}
          style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {currentText}
        </Text>
      </Tooltip>
    );
  }

  return (
    <>
      {!isEditFlowName ? (
        <Box
          onClick={e => {
            setIsEditFlowName(true);
            e.stopPropagation();
          }}
          onMouseEnter={() => {
            toggleTooltipVisible();
          }}
          className={s.flowNameInput2}
        >
          <Tooltip
            label={currentText}
            placement="top"
            isDisabled={!isTooltipVisible}
            variant={"dominoChat"}
            bg={"#FEF6DC"}
            hasArrow={true}
            openDelay={100}
          >
            <Text
              ref={textRefDesk}
              style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              data-pw="flow-name"
              width={"max-content"}
            >
              {currentText}
            </Text>
          </Tooltip>

          <Box className={s.editIconBox} data-pw="edit-flow-name">
            <EditIcon className={s.icon} />
          </Box>
        </Box>
      ) : (
        <Box w="100%" display={"flex-inline"} gap={"6px"}>
          <Input
            data-pw="flow-name-input"
            className={s.flowNameInput}
            autoFocus={true}
            value={currentText}
            bg="white"
            placeholder={`Enter the new Flow name`}
            onChange={onChange}
            onBlur={() => {
              saveRenamedFlow();
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                saveRenamedFlow();
              }
            }}
            width="100%"
          />
        </Box>
      )}
    </>
  );
});
