import { Box, Flex, Text, Button, Menu, MenuButton, Icon, MenuList, MenuItem } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownArrowMenu } from "../../assets/images/downArrow.svg";
import DateRange from "../../UI/molecules/dateRange/DateRange";
import { useEffect, useState } from "react";
import {
  getBots,
  getSentMessages,
  getSentReceivedMessages,
  selectBots,
  selectSentMessages,
  selectSentReceivedMessages,
} from "./DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import DashboardItem from "./components/DashboardItem/DashboardItem";
import { EnumTypeDateRange, DashboardDateModel, DashboardLocalStorageModel, SelectedBotModel } from "./DashboardModel";
import dayjs from "dayjs";
import { selectLanguage } from "../../common/user/UserSlice";
import s from "./DashboardContainer.module.scss";
import { getDashboardFromStorage } from "./DashboardSaga";

export default function DashboardContainer() {
  const [initialState] = useState<DashboardLocalStorageModel>(
    getDashboardFromStorage() ?? {
      date: {
        from: getStartDateDay(7).toDateString(),
        to: new Date().toDateString(),
        type: EnumTypeDateRange.Week,
      },
      bot: {
        id: "",
        name: "",
      },
    },
  );

  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });
  const botList = useAppSelector(selectBots);
  const [selectedBot, setSelectedBot] = useState<SelectedBotModel>({
    id: initialState.bot.id,
    name: initialState.bot.name,
  });

  const [dataDate, setDataDate] = useState<DashboardDateModel>({
    from: new Date(initialState.date.from),
    to: new Date(initialState.date.to),
    typeDate: initialState.date.type,
  });

  const sentMessagesSelector = useAppSelector(selectSentMessages);
  const sentReceivedMessagesSelector = useAppSelector(selectSentReceivedMessages);

  useEffect(() => {
    dispatch(getBots(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dayjs.locale(language);
    dispatch(
      getSentMessages({
        date: {
          from: dayjs(dataDate.from.toString()).format("YYYY-MM-DD"),
          to: dayjs(dataDate.to.toString()).format("YYYY-MM-DD"),
          type: dataDate.typeDate,
        },
        bot: {
          id: selectedBot.id,
          name: selectedBot.name,
        },
      }),
    );
    dispatch(
      getSentReceivedMessages({
        date: {
          from: dayjs(dataDate.from.toString()).format("YYYY-MM-DD"),
          to: dayjs(dataDate.to.toString()).format("YYYY-MM-DD"),
          type: dataDate.typeDate,
        },
        bot: {
          id: selectedBot.id,
          name: selectedBot.name,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDate.to, selectedBot]);

  const getAdditionalBots = () => {
    if (botList) {
      dispatch(getBots(botList?.currentPage + 1));
    }
  };

  const onDateRangeChange = (startDate: Date | null, endDate: Date | null) => {
    if (startDate) {
      setDataDate({
        from: startDate,
        to: dataDate.to,
        typeDate: EnumTypeDateRange.CustomRange,
      });
    }
    if (endDate) {
      setDataDate({
        from: dataDate.from,
        to: endDate,
        typeDate: EnumTypeDateRange.CustomRange,
      });
    }
  };

  function getStartDateDay(n: number) {
    const nowDate = new Date();
    const date = nowDate;
    date.setDate(nowDate.getDate() - n);
    return date;
  }

  function getStartDateMonth(n: number) {
    const nowDate = new Date();
    const date = nowDate;
    date.setMonth(nowDate.getMonth() - n);
    return date;
  }

  return (
    <>
      <Box className={s.dashboardContainer} justifyContent={{ base: "flex-start", lg: "space-between" }}>
        <Flex className={s.dateContainer} px={{ base: "16px", lg: "32px" }}>
          <Box zIndex={3}>
            <Menu variant={{ base: "dominoDotsAdaptiveMenu", md: "dominoDotsMenu" }} gutter={0} offset={[-30, 0]}>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    variant="dashboardBar"
                    _hover={{
                      ".icon": {
                        backgroundColor: `${isOpen ? "#6D5BF7" : "#DCE7FB"}`,
                      },
                    }}
                    onClick={e => e.stopPropagation()}
                    leftIcon={
                      <Icon
                        className="icon"
                        as={DownArrowMenu}
                        boxSize="24px"
                        color={isOpen ? "white" : "black"}
                        borderRadius={"4px"}
                        _hover={{
                          backgroundColor: `${isOpen ? "#6D5BF7" : "#DCE7FB"}`,
                        }}
                        transition="ease-out .2s"
                        bg={isOpen ? "#6D5BF7" : ""}
                      />
                    }
                  >
                    <Flex maxW={"500px"}>
                      <Box w="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {!selectedBot.name ? t("All Bots") : selectedBot.name}
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList overflow="auto !important" w="190px" maxH="250px" ml={"25px"}>
                    <MenuItem
                      color={"black"}
                      onClick={() =>
                        setSelectedBot({
                          id: "",
                          name: "",
                        })
                      }
                    >
                      {t("All Bots")}
                    </MenuItem>
                    {!!botList?.items.length &&
                      botList.items.map(item => (
                        <MenuItem
                          color={"black"}
                          key={item.id}
                          onClick={() =>
                            setSelectedBot({
                              id: item.id,
                              name: item.name,
                            })
                          }
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    {!!botList?.items.length && botList.currentPage < botList.totalPages && (
                      <MenuItem closeOnSelect={false} onClick={getAdditionalBots} h="36px">
                        <Flex
                          alignItems={"center"}
                          justifyContent={"center"}
                          w={"100%"}
                          color={"black"}
                          cursor={"pointer"}
                          fontSize={{ base: "14px", lg: "15px" }}
                        >
                          <div>{t("show more")}</div>
                          <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                        </Flex>
                      </MenuItem>
                    )}
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
          <Flex className={s.dateControl} gap={"16px"} alignItems="center">
            <Box className={s.mainDate}>
              <Flex>
                <Button
                  variant={dataDate.typeDate === EnumTypeDateRange.Week ? "dominoViolet" : "dominoOutlineGray"}
                  borderTopRightRadius={0}
                  borderBottomRightRadius={0}
                  w="100%"
                  onClick={() =>
                    setDataDate({
                      from: getStartDateDay(7),
                      to: new Date(),
                      typeDate: EnumTypeDateRange.Week,
                    })
                  }
                >
                  {t("dashboardDateRange.Week")}
                </Button>
                <Button
                  variant={dataDate.typeDate === EnumTypeDateRange.Month ? "dominoViolet" : "dominoOutlineGray"}
                  borderRadius={0}
                  w="100%"
                  onClick={() =>
                    setDataDate({
                      from: getStartDateMonth(1),
                      to: new Date(),
                      typeDate: EnumTypeDateRange.Month,
                    })
                  }
                >
                  {t("dashboardDateRange.Month")}
                </Button>
                <Button
                  variant={dataDate.typeDate === EnumTypeDateRange.ThreeMonth ? "dominoViolet" : "dominoOutlineGray"}
                  borderRadius={0}
                  w="100%"
                  onClick={() =>
                    setDataDate({
                      from: getStartDateMonth(3),
                      to: new Date(),
                      typeDate: EnumTypeDateRange.ThreeMonth,
                    })
                  }
                >
                  {t("dashboardDateRange.ThreeMonth")}
                </Button>
                <Button
                  variant={dataDate.typeDate === EnumTypeDateRange.SixMonth ? "dominoViolet" : "dominoOutlineGray"}
                  borderTopLeftRadius={0}
                  borderBottomLeftRadius={0}
                  w="100%"
                  onClick={() =>
                    setDataDate({
                      from: getStartDateMonth(6),
                      to: new Date(),
                      typeDate: EnumTypeDateRange.SixMonth,
                    })
                  }
                >
                  {t("dashboardDateRange.SixMonth")}
                </Button>
              </Flex>
            </Box>
            <Box className={s.mobileDate}>
              <Menu variant="dominoDotsMenu" gutter={0} offset={[-30, 0]}>
                {({ isOpen, onClose }) => (
                  <>
                    <Menu variant="dominoDotsMenu" gutter={0} offset={[-26, -2]}>
                      {({ isOpen }) => (
                        <>
                          <MenuButton as={Text} className={s.retryText} color={isOpen ? "blueLink" : ""}>
                            {t(`dashboardDateRange.${dataDate.typeDate}`)}
                          </MenuButton>

                          <MenuList ml={"25px"}>
                            <MenuItem
                              color={"black"}
                              onClick={() =>
                                setDataDate({
                                  from: getStartDateDay(7),
                                  to: new Date(),
                                  typeDate: EnumTypeDateRange.Week,
                                })
                              }
                            >
                              {t("dashboardDateRange.Week")}
                            </MenuItem>
                            <MenuItem
                              color={"black"}
                              onClick={() =>
                                setDataDate({
                                  from: getStartDateMonth(1),
                                  to: new Date(),
                                  typeDate: EnumTypeDateRange.Month,
                                })
                              }
                            >
                              {t("dashboardDateRange.Month")}
                            </MenuItem>{" "}
                            <MenuItem
                              color={"black"}
                              onClick={() =>
                                setDataDate({
                                  from: getStartDateMonth(3),
                                  to: new Date(),
                                  typeDate: EnumTypeDateRange.ThreeMonth,
                                })
                              }
                            >
                              {t("dashboardDateRange.ThreeMonth")}
                            </MenuItem>{" "}
                            <MenuItem
                              color={"black"}
                              onClick={() =>
                                setDataDate({
                                  from: getStartDateMonth(6),
                                  to: new Date(),
                                  typeDate: EnumTypeDateRange.SixMonth,
                                })
                              }
                            >
                              {t("dashboardDateRange.SixMonth")}
                            </MenuItem>
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  </>
                )}
              </Menu>
            </Box>
            <DateRange
              onDateRangeChange={onDateRangeChange}
              startDate={dataDate.from}
              endDate={dataDate.to}
              setTypeDate={setDataDate}
            />
          </Flex>
        </Flex>
        <Box overflowY="auto" pt={"24px"} px={{ base: "16px", lg: "32px" }} flex={1}>
          <DashboardItem
            label={"Sent messages"}
            total={sentMessagesSelector?.total}
            labelCheckbox={"Messages"}
            origins={[
              { label: "bot", checked: true, total: sentMessagesSelector?.totalBot },
              { label: "broadcasts", checked: true, total: sentMessagesSelector?.totalBroadcasts },
              { label: "operators", checked: true, total: sentMessagesSelector?.totalOperators },
            ]}
            dataChart={sentMessagesSelector?.data}
          />
          <DashboardItem
            label={"Messages statistic"}
            total={sentReceivedMessagesSelector?.total}
            labelCheckbox={"Messages"}
            origins={[
              { label: "sent", checked: true, total: sentReceivedMessagesSelector?.totalSent },
              { label: "received", checked: true, total: sentReceivedMessagesSelector?.totalReceived },
            ]}
            dataChart={sentReceivedMessagesSelector?.data}
          />
        </Box>
      </Box>
    </>
  );
}
