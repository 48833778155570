import { Flex } from "@chakra-ui/react";
import WorkingTimeItem from "./components/WorkingTimeItem/WorkingTimeItem";
import { CalendarFlowActionModel, FlowActionModel, WorkingTimeModel } from "../../../../../FlowBuilderModel";
import { chooseDay } from "../../../../../FlowBuilderMapper";

interface Props {
  onDataChange: (flowAction: FlowActionModel) => void;
  flowAction: FlowActionModel;
}
const WORKING_TIMES = [
  { dayOfWeek: "Monday", order: 0, isAvailable: false, intervals: [] },
  { dayOfWeek: "Tuesday", order: 1, isAvailable: false, intervals: [] },
  { dayOfWeek: "Wednesday", order: 2, isAvailable: false, intervals: [] },
  { dayOfWeek: "Thursday", order: 3, isAvailable: false, intervals: [] },
  { dayOfWeek: "Friday", order: 4, isAvailable: false, intervals: [] },
  { dayOfWeek: "Saturday", order: 5, isAvailable: false, intervals: [] },
  { dayOfWeek: "Sunday", order: 6, isAvailable: false, intervals: [] },
] as WorkingTimeModel[];

export default function WorkingTimeContainer(props: Props) {
  const flowAction = props.flowAction as CalendarFlowActionModel;
  const timeMenu: { hours: number; minutes: number }[] = [];
  let minutes = 0;
  const setMenuTime = () => {
    for (let i = 0; i <= 23; ) {
      timeMenu.push({ hours: i, minutes: minutes });
      if (minutes === 45) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        minutes = 0;
        i++;
      } else {
        minutes = minutes + 15;
      }
    }
  };

  setMenuTime();

  const day = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const addTime = (dayTime: string) => {
    let newWorkingTime = flowAction.workingTimes || WORKING_TIMES;

    const findWorkingTime = newWorkingTime.find(el => el.dayOfWeek === dayTime);
    let changeWorkingTime = findWorkingTime ? { ...findWorkingTime } : undefined;

    if (changeWorkingTime && changeWorkingTime.intervals) {
      changeWorkingTime = {
        ...changeWorkingTime,
        isAvailable: true,
        intervals: [...changeWorkingTime.intervals, { beginTime: "10:00", endTime: "18:00" }],
      };
      newWorkingTime = newWorkingTime.filter(el => el.dayOfWeek !== dayTime);

      newWorkingTime = [...newWorkingTime, changeWorkingTime];
    } else {
      newWorkingTime = [
        ...newWorkingTime,
        {
          dayOfWeek: dayTime,
          order: chooseDay(dayTime),
          isAvailable: true,
          intervals: [{ beginTime: "10:00", endTime: "18:00" }],
        },
      ];
    }

    props.onDataChange({ ...flowAction, workingTimes: [...newWorkingTime], isAvailable: true } as CalendarFlowActionModel);
  };

  const removeTime = (dayTime: string, index: number) => {
    let newWorkingTime = flowAction.workingTimes || [];
    const findWorkingTime = newWorkingTime.find(el => el.dayOfWeek === dayTime);
    const changeWorkingTime = findWorkingTime ? { ...findWorkingTime } : undefined;

    if (changeWorkingTime) {
      changeWorkingTime.intervals = changeWorkingTime.intervals.filter((el, i) => i !== index);
      changeWorkingTime.isAvailable = changeWorkingTime.intervals.length > 0;
      newWorkingTime = newWorkingTime.filter(el => el.dayOfWeek !== dayTime);

      newWorkingTime = [...newWorkingTime, changeWorkingTime];
    }

    let isUndefinedTime = true;
    newWorkingTime.forEach(el => {
      if (el.intervals.length !== 0) {
        isUndefinedTime = false;
      }
    });

    props.onDataChange({
      ...flowAction,
      isAvailable: !isUndefinedTime,
      workingTimes: isUndefinedTime ? WORKING_TIMES : [...newWorkingTime],
    } as CalendarFlowActionModel);
  };

  const copyDay = (dayTime: string) => {
    if (flowAction.workingTimes) {
      const newWorkingTime: WorkingTimeModel[] = [];
      const findWorkingTime = flowAction.workingTimes.find(el => el.dayOfWeek === dayTime);

      if (findWorkingTime) {
        day.forEach((el, i) => {
          newWorkingTime.push({
            dayOfWeek: el,
            order: i,
            intervals: findWorkingTime.intervals,
            isAvailable: true,
          });
        });
      }

      props.onDataChange({
        ...flowAction,
        workingTimes: [...newWorkingTime],
      } as CalendarFlowActionModel);
    }
  };

  const changeTime = (newTime: { beginTime: string; endTime: string }, index: number, dayTime: string) => {
    if (flowAction.workingTimes) {
      const findDay = flowAction.workingTimes.find(el => el.dayOfWeek === dayTime);
      const newWorkingTimes: Array<{ beginTime: string; endTime: string }> = [];

      findDay?.intervals.forEach((el, ind) => {
        if (ind === index) {
          newWorkingTimes.push(newTime);
        } else {
          newWorkingTimes.push(el);
        }
      });

      newWorkingTimes.sort((a, b) => {
        if (a.beginTime === b.beginTime) {
          return a.endTime > b.endTime ? 1 : -1;
        }
        return a.beginTime > b.beginTime ? 1 : -1;
      });

      const changeWorkingTimes: WorkingTimeModel[] = [];
      flowAction.workingTimes.forEach((el, ind) => {
        if (el.dayOfWeek === dayTime) {
          changeWorkingTimes.push({ dayOfWeek: dayTime, order: el.order, intervals: newWorkingTimes, isAvailable: true });
        } else {
          changeWorkingTimes.push(el);
        }
      });

      props.onDataChange({
        ...flowAction,
        workingTimes: changeWorkingTimes,
      } as CalendarFlowActionModel);
    }
  };

  const chooseTime = (dayTime: string) => {
    const times = flowAction.workingTimes || [];
    const result = times.find(el => el.dayOfWeek === dayTime);
    return result ? result.intervals : [];
  };
  const getIntersectionRatio = (dayTime: string) => {
    const times = flowAction.workingTimes || [];
    const result = times.find(el => el.dayOfWeek === dayTime);
    const intersectionByDay = Boolean(
      result?.intervals.some((el, index, arr) => {
        if (index > 0) {
          return el.beginTime < arr[index - 1].endTime;
        } else {
          return null;
        }
      }),
    );
    return intersectionByDay;
  };

  return (
    <Flex maxH="400px" overflow="auto" gap="8px" flexDirection="column">
      {day.map((el, i) => (
        <WorkingTimeItem
          timeMenu={timeMenu}
          removeTime={removeTime}
          addTime={addTime}
          copyDay={copyDay}
          changeTime={changeTime}
          key={el}
          label={el}
          time={chooseTime(el)}
          intersection={getIntersectionRatio(el)}
        />
      ))}
    </Flex>
  );
}
