import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import {
  getFlowList,
  selectBotVariablesInFlow,
  selectCanAddNewFlow,
  selectCanImportNewFlow,
  selectFlowList,
  selectImportResultsFlow,
} from "../AutomationSlice";
import {
  Box,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DeleteFlowPopup } from "./DeleteFlowPopup/DeleteFlowPopup";
import { getBots, selectBots, selectCurrentBot, selectIsCurrentBotLoading } from "../../sidebar/SidebarSlice";
import { FlowList } from "./FlowList";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import s from "../Automation.module.scss";
import sp from "../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { useGetLoadingState } from "../../../common/loading/hooks/useGetLoadingState";
import { CopyFlowPopup } from "./CopyFlowPopup/CopyFlowPopup";
import { FlowTemplatesModal } from "./FlowTemplatesModal/FlowTemplatesModal";
import { ImportFlowModal } from "./ImportFlowModal/ImportFlowModal";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plusIconTile.svg";
import { ReactComponent as ImportIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as StarPlan } from "../../../assets/icons/starPlan.svg";
import { ReactComponent as BotImageStart } from "../../../assets/images/botImageStart.svg";

import { useGAWithCustomParameters } from "../../../common/ga/GAEventTracker";
import { TariffPlanRedirectButton } from "../../../common/tariffPlan/TariffPlanRedirectButton";
import BaseEmptyPage from "../../../common/baseEmptyPage/BaseEmptyPage";
import { TrialModal } from "../../organisation/components/TrialPlan/TrilalModal/TrialModal";
import { selectTrialPlan } from "../../organisation/OrganisationSlice";
import { clearStore, selectIsConnectionCompleted } from "../../addBot/AddBotSlice";

export const FlowListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;

  const dispatch = useAppDispatch();

  const botList = useAppSelector(selectBots);
  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const flowList = useAppSelector(selectFlowList);
  const botVariablesInFlow = useAppSelector(selectBotVariablesInFlow);
  const importResultsFlow = useAppSelector(selectImportResultsFlow);
  const loadingFlowList = useGetLoadingState("getFlowList");
  const trackEvent = useGAWithCustomParameters("Flow");
  const { isOpen: isOpenTemplates, onOpen: onOpenTemplates, onClose: onCloseTemplates } = useDisclosure();
  const { isOpen: isOpenImportFlow, onOpen: onOpenImportFlow, onClose: onCloseImportFlow } = useDisclosure();

  const [deletePopupState, setDeletePopupState] = useState<{
    id: string;
    title: string;
  } | null>(null);
  const [copyPopupState, setCopyPopupState] = useState<{
    id: string;
    title: string;
  } | null>(null);

  const [searchParams] = useSearchParams("");
  const currentPage = Number(searchParams.get("page") ?? 1);
  const search = searchParams.get("search") ?? "";
  const canAddFlow = useAppSelector(selectCanAddNewFlow) ?? false;
  const isImportAvailable = useAppSelector(selectCanImportNewFlow) ?? false;
  const isFirstBotConnected = useAppSelector(selectIsConnectionCompleted);
  const trialPlan = useAppSelector(selectTrialPlan);
  const [isOpenModal, setIsOpenModal] = useState(true);
  const showTrialModal = !!trialPlan && trialPlan.isAvailable && isFirstBotConnected && botList?.totalItems === 1;

  useEffect(() => {
    if (currentBot) {
      dispatch(getFlowList({ botId: currentBot.id, page: currentPage, search }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, searchParams, importResultsFlow]);

  const getAdditionalBots = () => {
    if (botList) {
      dispatch(getBots({ page: botList?.currentPage + 1 }));
    }
  };

  useEffect(() => {
    if (flowList) {
      trackEvent("FlowListLoaded", "", { count: flowList.totalItems });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowList]);

  const clearAddBotStore = () => {
    dispatch(clearStore());
  };

  useEffect(() => {
    return () => {
      clearAddBotStore();
    };
  }, []);

  if (!currentBot && !isCurrentBotLoading) {
    return (
      <Box className={s.flowListEmptyPageContainer}>
        <BaseEmptyPage
          botTitle={t("No flows")}
          botDescription={t("Create or connect an existing bot to add your first Flow.")}
          Image={BotImageStart}
          description={t("Create your first Flow")}
          title={t("You don't have any flows")}
        >
          {!canAddFlow ? (
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="sm" variant="dominoViolet" px="24px" className={sp.starPlanButton} data-pw="new-flow-button">
                      <Icon className={sp.starPlan} boxSize="20px" as={StarPlan} />
                      {t("New Flow")}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to create more Flows")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : (
            <Button data-pw="new-flow-button" variant="dominoViolet" onClick={() => onOpenTemplates()}>
              <Flex gap={"8px"} align={"center"}>
                <Flex align="center" justify="center" className={s.plusIcon}>
                  <PlusIcon />
                </Flex>
                {t("New Flow")}
              </Flex>
            </Button>
          )}
          {!isImportAvailable || !canAddFlow ? (
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="sm" variant="dominoOutlineViolet" data-pw="import-flow-button" className={sp.starPlanButton}>
                      <Icon className={sp.starPlan} boxSize="20px" fill={"#6D5BF7"} as={StarPlan} />
                      {t("Import Flow")}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to import Flows")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : (
            <Button size="sm" variant="dominoOutlineViolet" onClick={() => onOpenImportFlow()} data-pw="import-flow-button">
              <Flex gap={"8px"} align={"center"}>
                <ImportIcon />
                <Text>{t("Import Flow")}</Text>
              </Flex>
            </Button>
          )}
        </BaseEmptyPage>
      </Box>
    );
  }

  if (flowList?.items.length === 0 && search !== "") {
    return (
      <Box className={s.noData} position={"relative"}>
        <Box className={`${s.flowListItemGrid} ${s.flowListFilter}`}>
          <Text noOfLines={1} ml="16px">
            {t("Name")}
          </Text>
          <Text justifySelf="center" mr="36px" noOfLines={1}>
            {t("Runs")}
          </Text>
          <Text justifySelf="center" noOfLines={1}>
            {t("Modified")}
          </Text>
          <div />
        </Box>
        <Box className={s.noSearchResults}>{ct("No results found")}</Box>
      </Box>
    );
  }

  if (flowList?.items.length === 0 && search === "" && !loadingFlowList) {
    return (
      <>
        <Box className={s.flowListEmptyPageContainer}>
          <BaseEmptyPage
            botTitle={t("No flows")}
            botDescription={t("Create or connect an existing bot to add your first Flow.")}
            Image={BotImageStart}
            description={t("Create your first Flow")}
            title={t("You don't have any flows")}
          >
            {!canAddFlow ? (
              <Popover trigger={"hover"}>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Button size="sm" variant="dominoViolet" px="24px" className={sp.starPlanButton} data-pw="new-flow-button">
                        <Icon className={sp.starPlan} boxSize="20px" as={StarPlan} />
                        {t("New Flow")}
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                        <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                        <Flex alignItems="center" justifyContent="center" direction="column">
                          <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                            {tp("Advanced feature")}
                          </PopoverHeader>
                          <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                            {tp("Please upgrade your plan to create more Flows")}
                          </PopoverBody>
                          <TariffPlanRedirectButton onClose={onClose} />
                        </Flex>
                      </PopoverContent>
                    </Portal>
                  </>
                )}
              </Popover>
            ) : (
              <Button data-pw="new-flow-button" variant="dominoViolet" onClick={() => onOpenTemplates()}>
                <Flex gap={"8px"} align={"center"}>
                  <Flex align="center" justify="center" className={s.plusIcon}>
                    <PlusIcon />
                  </Flex>
                  {t("New Flow")}
                </Flex>
              </Button>
            )}
            {!isImportAvailable || !canAddFlow ? (
              <Popover trigger={"hover"}>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Button size="sm" variant="dominoOutlineViolet" data-pw="import-flow-button" className={sp.starPlanButton}>
                        <Icon className={sp.starPlan} boxSize="20px" fill={"#6D5BF7"} as={StarPlan} />
                        {t("Import Flow")}
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                        <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                        <Flex alignItems="center" justifyContent="center" direction="column">
                          <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                            {tp("Advanced feature")}
                          </PopoverHeader>
                          <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                            {tp("Please upgrade your plan to import Flows")}
                          </PopoverBody>
                          <TariffPlanRedirectButton onClose={onClose} />
                        </Flex>
                      </PopoverContent>
                    </Portal>
                  </>
                )}
              </Popover>
            ) : (
              <Button size="sm" variant="dominoOutlineViolet" onClick={() => onOpenImportFlow()} data-pw="import-flow-button">
                <Flex gap={"8px"} align={"center"}>
                  <ImportIcon />
                  <Text>{t("Import Flow")}</Text>
                </Flex>
              </Button>
            )}
          </BaseEmptyPage>
        </Box>
        {showTrialModal && <TrialModal trialPlan={trialPlan} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />}
        <FlowTemplatesModal isOpen={isOpenTemplates} onClose={onCloseTemplates} />
        <ImportFlowModal isOpen={isOpenImportFlow} onClose={onCloseImportFlow} />
      </>
    );
  }

  return (
    <>
      {deletePopupState && <DeleteFlowPopup deletePopupState={deletePopupState} onClose={() => setDeletePopupState(null)} />}
      {copyPopupState && (
        <CopyFlowPopup
          botVariablesInFlow={botVariablesInFlow?.botVariables}
          copyPopupState={copyPopupState}
          botList={botList}
          onClose={() => setCopyPopupState(null)}
          getAdditionalBots={getAdditionalBots}
        />
      )}
      <Box padding={`0 16px 16px 16px`} pb={"12px"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
        {currentBot && (
          <FlowList
            onCopy={flow => setCopyPopupState({ id: flow.id, title: flow.title })}
            flowList={flowList}
            botId={currentBot.id}
            currentPage={currentPage}
            onDelete={flow => setDeletePopupState({ id: flow.id, title: flow.title })}
          />
        )}
      </Box>
    </>
  );
};
