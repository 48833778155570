import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../common/state/store";

export interface CommonState {
  isOverlayShown: boolean;
}

const initialState: CommonState = {
  isOverlayShown: false,
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    turnOnOverlay: state => {
      return {
        ...state,
        isOverlayShown: true,
      };
    },
    turnOffOverlay: state => {
      return {
        ...state,
        isOverlayShown: false,
      };
    },
  },
});

export const { turnOnOverlay, turnOffOverlay } = commonSlice.actions;

export const selectIsOverlayShown = (state: RootState) => state.app.commonState.isOverlayShown;

export default commonSlice.reducer;
